import { GridSettings } from "../documents/grid-settings";
import { PreviewData } from "./preview-data";

export class EditorSettings {
	PreviewData:PreviewData = new PreviewData();
	GridSettings:GridSettings = new GridSettings();

	public constructor(data?:any){
		if (!data) return;
		this.PreviewData = new PreviewData(data.PreviewData);
		this.GridSettings = new GridSettings(data.GridSettings);
	}

	public getJSON(){
		return {
			PreviewData: this.PreviewData.getJSON(),
			GridSettings: this.GridSettings
		};
	}
}
import { Condition } from "@/utils/condition/condition";
import { v4 } from "uuid";
import SaleRow from "./sale-row";
import { comparePriceRules, IPriceRule, priceRuleToString } from "@/utils/price-rule";

export class ProductPriceRule implements IPriceRule{
	key:string = v4();

	ID:number = 0;
	ProductID:number = 0;
	Filters:Condition[] = [];
	Price:number = 0.0;
	BuyPercentage:number = 0.0;
	BuyAmount:number = 0.0;
	Discount:number = 0.0;
	DisplayOrder:number = 0;
	PriceCategory:number = 0;

	public constructor(data?:any) {
		if (!data) return;
		this.ID = data.ID;
		this.ProductID = data.ProductID;
		this.Filters = (data.Filters || []).map((f:any)=>new Condition(f));
		this.Price = data.Price;
		this.Discount = data.Discount || 0;
		this.DisplayOrder = data.DisplayOrder || 0;
		this.PriceCategory = data.PriceCategory;
	}

	public getJSON(){
		return {
			ID: this.ID,
			ProductID: this.ProductID,
			Filters: this.Filters.map(f=>f.getJSON()),
			Price: this.Price,
			Discount: this.Discount,
			DisplayOrder: this.DisplayOrder,
			PriceCategory: this.PriceCategory
		};
	}

	public makeCopy(): IPriceRule {
		return new ProductPriceRule(this.getJSON());
	}

	public toString():string{
		return priceRuleToString(this);
	}

	public test(val:SaleRow):boolean {
		return Condition.checkConditions(this.Filters, val);
	}

	public compare(rule:ProductPriceRule):boolean {
		return comparePriceRules(this ,rule);
	}
}
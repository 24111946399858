import { ExtraFieldNamesConfig } from "@/models/dossier-configs/extra-field-names-config";

export class ExtraFieldNamesConfigs {
	ClientNames = new ExtraFieldNamesConfig();
	SupplierNames = new ExtraFieldNamesConfig();
	ProspectNames = new ExtraFieldNamesConfig();
	ProductNames = new ExtraFieldNamesConfig();
	SaleNames = new ExtraFieldNamesConfig();
	PurchaseNames = new ExtraFieldNamesConfig();
	CarNames = new ExtraFieldNamesConfig();
	CrmNames = new ExtraFieldNamesConfig();

	constructor(data?:any) {
		if (!data) return;
		this.ClientNames = new ExtraFieldNamesConfig(data.ClientNames);
		this.SupplierNames = new ExtraFieldNamesConfig(data.SupplierNames);
		this.ProspectNames = new ExtraFieldNamesConfig(data.ProspectNames);
		this.ProductNames = new ExtraFieldNamesConfig(data.ProductNames);
		this.SaleNames = new ExtraFieldNamesConfig(data.SaleNames);
		this.PurchaseNames = new ExtraFieldNamesConfig(data.PurchaseNames);
		this.CarNames = new ExtraFieldNamesConfig(data.CarNames);
		this.CrmNames = new ExtraFieldNamesConfig(data.CrmNames);
	}

	getJSON() {
		return {
			ClientNames: this.ClientNames.getJSON(),
			SupplierNames: this.SupplierNames.getJSON(),
			ProspectNames: this.ProspectNames.getJSON(),
			ProductNames: this.ProductNames.getJSON(),
			SaleNames: this.SaleNames.getJSON(),
			PurchaseNames: this.PurchaseNames.getJSON(),
			CarNames: this.CarNames.getJSON(),
			CrmNames: this.CrmNames.getJSON()
		};
	}

	public getContactNamesByGroupID(contactGroupId:number):ExtraFieldNamesConfig {
		if (contactGroupId == 3) {
			return this.ProspectNames;
		}
		if (contactGroupId == 2) {
			return this.SupplierNames;
		}
		return this.ClientNames;
	}
}
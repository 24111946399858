
import { ContactAddressMailSetting } from "@/utils/contact-address-mail-settings";
import Vue from "vue";
import AppSaleTypeMultiSelect from "@/components/sales/SaleTypeMultiSelect.vue";

export default Vue.extend({
	data(){
		return {
			settings: new ContactAddressMailSetting(),
			isDefaultValue: false
		};
	},
	props: {
		value: {
			type: Object as ()=>ContactAddressMailSetting,
			required: true,
		},
		isDefault: Boolean,
		groupId: {
			type: Number,
			required: true
		}
	},
	methods: {
		close(){
			this.$emit("close");
		},
		confirm(){
			if (this.isDefaultValue != this.isDefault) {
				this.$emit("set-is-default", this.isDefaultValue);
			}
			this.$emit("input", new ContactAddressMailSetting(this.settings.getJSON()));
			this.close();
		}
	},
	mounted(){
		console.log(this.value, this.isDefaultValue);
		this.settings = new ContactAddressMailSetting(this.value.getJSON());
		this.isDefaultValue = this.isDefault;
	},
	components: {
		AppSaleTypeMultiSelect
	},
	i18nEx: {
		componentPrefix: "components.contacts"
	}
});

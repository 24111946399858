
import AppSellCategorySelect from "@/components/SellCategorySelect.vue";
import { Contact } from "@/models/base";
import { ContactAutofill } from "@/models/contact-autofill";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import AppAddress from "./ContactEditAddress.vue";
import AppDefaultCreatorSelect from "./DefaultCreatorSelect.vue";
import AppAutofillModal from "./ContactEditAutofillModal.vue";
import AppSelectJournal from "@/components/SelectJournal.vue";
import { AllContactsService } from "@/services/all-contacts-service";
import { TranslateResult } from "vue-i18n";
import { DossierConfigService } from "@/services/dossier-config-service";
import { CustomerService } from "@/services/customer-service";
import AppErrorMessageBox from "@/components/common/ErrorMessageBox.vue";
import AppLedgerAccountAutocomplete from "@/components/common/LedgerAccountAutocomplete.vue";
import { AuthService } from "@/services/auth-service";
import AppSaleVatRegimeSelect from "@/components/common/SaleVatRegimeSelect.vue";
import AppPurchaseVatRegimeSelect from "@/components/common/PurchaseVatRegimeSelect.vue";
import AppCurrencySelect from "@/components/CurrencySelect.vue";
import AppVatSelect from "@/components/SelectVat.vue";
export default Vue.extend({
	data() {
		return {
			autofillModal: false,
			checkVat: false,
			autofillData: null as ContactAutofill | null,
			isAllowedMultiValuta: AuthService.hasModule(10),
			config: DossierConfigService.getContactsConfig()
		};
	},
	props: {
		contact: {type: Object as () => Contact}
	},
	computed: {
		mainAddressTitle():TranslateResult {
			return this.$ct(`titles.address-default-${this.contact.GroupID}`);
		},
		reminders(): ISelectItem<number>[] {
			return Object.values(this.$ct("reminders")).map((k, v) => {
				return {value: v, text: k};
			});
		},
		contactNrLabel():TranslateResult {
			if (this.contact.GroupID > 3) {
				return this.$ct("labels.contact-nr");
			}
			return this.$ct(`labels.contact-nr-${this.contact.GroupID}`);
		},
		hasMollieModuleConfigured():boolean {
			return DossierConfigService.getMollieConfig().ConnectedWithMollie;
		},
		showVatRegimeWarning():boolean {
			let {countryCode} = this.contact.getVatCountryCodeAndNumberSeperated();
			if (!countryCode) return false;
			let internationalVatRegimes = [10, 3, 4, 9, 11, 17];
			if (countryCode.toUpperCase() == "BE") {
				if (internationalVatRegimes.indexOf(this.contact.VatRegime) != -1) {
					return true;
				}
			}else if (internationalVatRegimes.indexOf(this.contact.VatRegime) == -1) {
				return true;
			}
			return false;
		}
	},
	watch: {
		"contact.Vat": {
			handler(){
				if (!this.contact.Vat) return;
				let vat = this.contact.Vat.toUpperCase().trim();
				if (vat == this.contact.Vat) return;
				this.contact.Vat = vat;
			}
		}
	},
	methods: {
		async onVatCheck() {
			if (!this.contact.Vat) {
				return;
			}
			this.checkVat = true;
			try {
				this.autofillData = await AllContactsService.autoFill(this.contact.Vat);
				if (this.autofillData) {
					this.autofillModal = true;
				}
			} catch (err) {
				this.$wf.notify(this.$ct("error-unkown-vat"), "warning", 3000, "exclamation-triangle");
			}
			this.checkVat = false;
		},
		hasModule(moduleId:number):boolean{
			return AuthService.hasModule(moduleId);
		},
		async onVatBlur() {
			if (!this.contact.Vat) return;
			await this.formatVatNumber();
			this.$emit("check-if-vat-exists");
		},
		async formatVatNumber(){
			if (!this.contact.Vat) return;
			if(this.contact.MainAddress?.CountryCode == "BE"){
				let p = this.contact.Vat.slice(0, 2);
				if(p!=="BE" && !/\d/.test(p)){
					return;
				}
				let v = this.contact.Vat.replace(/\D/g, "");
				if (v.length !== 10) {
					return;
				}
				this.contact.Vat = "BE" + v.replace(/(\d{4})(\d{3})(\d{3})/, "$1.$2.$3");
				return;
			}

			function insertDotAtIndex(txt: string, index: number): string {
				return txt.substring(0, index) + "." + txt.substring(index, txt.length);
			}

			let vat = this.contact.Vat;
			if (vat.match(/^NL\d{9}.{3}/)) {
				vat = insertDotAtIndex(vat, 11);
				vat = insertDotAtIndex(vat, 8);
				vat = insertDotAtIndex(vat, 6);
				this.contact.Vat = vat;
			}
		},
		async checkIfMollieCustomerIsValid(){
			this.contact.MollieCustomerID = this.contact.MollieCustomerID.trim();
			if (!this.contact.MollieCustomerID) return;
			let isValid = await CustomerService.isMollieCustomerIdValid(this.contact.MollieCustomerID);
			if (isValid) return;
			this.contact.MollieCustomerID = "";
			this.$wf.dialog(this.$ct("mollie-customer-id-does-not-exist"), this.$ct("common.ok"), this.$ct("common.error"), "warning");
		},
		setContactVatNull(shouldBeNull:boolean){
			if (shouldBeNull) {
				this.contact.Vat = null;
			}else{
				this.contact.Vat = "";
			}
		}
	},
	components: {
		AppAddress,
		AppAutofillModal,
		AppSellCategorySelect,
		AppSelectJournal,
		AppErrorMessageBox,
		AppLedgerAccountAutocomplete,
		AppDefaultCreatorSelect,
		AppSaleVatRegimeSelect,
		AppPurchaseVatRegimeSelect,
		AppCurrencySelect,
		AppVatSelect
	},
	i18nEx: {
		componentPrefix: "components.contacts"
	}
});

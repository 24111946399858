
import { ContactAddress } from "@/models/base";
import { ContactAddressMailSetting } from "@/utils/contact-address-mail-settings";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import AppContactEditAddressEmailSettings from "./ContactEditAddressEmailSettings.vue";
import { isStringEmail } from "@/utils/strings";

interface IMailContainer {
	address:string;
	settings:ContactAddressMailSetting;
	wasBlured:boolean;
}

export default Vue.extend({
	data(){
		return {
			mails: [] as IMailContainer[],
			defaultSettingsIndex: -1,
			toEditMailSettingsIndex: -1,
		};
	},
	props: {
		value: {
			type: Object as ()=>ContactAddress,
			required: true
		},
		groupId: {
			type: Number,
			required: true
		}
	},
	computed: {
		toEditMailSettings():ContactAddressMailSetting | undefined {
			let settings = this.mails[this.toEditMailSettingsIndex];
			if (!settings) {
				return undefined;
			}
			return settings.settings;
		}
	},
	methods: {
		setSettings(){
			let mails:string[] = [];
			let settings:ContactAddressMailSetting[] = [];
			for (let i = 0; i < this.mails.length; i++){
				if (this.mails[i].address.trim()) {
					mails.push(this.mails[i].address);
					settings.push(new ContactAddressMailSetting(this.mails[i].settings.getJSON()));
				}
			}
			this.value.EmailSettings.Settings = settings;
		},
		setAddresses(){
			this.value.Email = this.mails.map(m=>m.address).filter(a=>a.trim()!="").join(";");
		},
		getMailLabel(i:number):TranslateResult{
			return this.value.getMailLabel(i);
		},
		async addEmailAddress(){
			this.mails.push({
				address: "",
				settings: new ContactAddressMailSetting(),
				wasBlured: false
			});
			this.setSettings();
			await this.$nextTick();
			let inputs = this.$refs.inputs as any[];
			if (!inputs) return;
			inputs[inputs.length-1].focus();
		},
		removeEmailAddress(i:number){
			this.mails.splice(i,1);
			this.setAddresses();
			this.setSettings();
		},
		editMailSettings(i:number){
			this.toEditMailSettingsIndex = i;
		},
		setMailSettings(settings:ContactAddressMailSetting){
			if (this.mails[this.toEditMailSettingsIndex]){
				this.mails[this.toEditMailSettingsIndex].settings = settings;
				this.setSettings();
			}
		},
		setDefaultMail(value:boolean){
			if (value){
				this.defaultSettingsIndex = this.toEditMailSettingsIndex;
			}else{
				this.defaultSettingsIndex = -1;
			}
			this.value.EmailSettings.DefaultMailIndex = this.defaultSettingsIndex;
		},
		onMailInput(mail:IMailContainer, value:string){
			mail.address = value;
			this.setAddresses();
		},
		onKeyDown(event:KeyboardEvent){
			if (event.key == "," || event.code == "Space" || event.key == ";") {
				event.preventDefault();
				this.addEmailAddress();
			}
		},
		isEmailAddressValid(mail:IMailContainer){
			if (!mail.wasBlured) return true;
			let address = mail.address.trim();
			if (!address) return true;
			return isStringEmail(address);
		}
	},
	mounted(){
		this.mails = this.value.Email.split(";").map((address, i)=>{
			let settings = this.value.EmailSettings.Settings[i];
			if (!settings) {
				settings = new ContactAddressMailSetting();
			}
			return {
				address,
				settings,
				wasBlured: true
			};
		});
		this.defaultSettingsIndex = this.value.EmailSettings.DefaultMailIndex;
	},
	components: {
		AppContactEditAddressEmailSettings
	},
	i18nEx: {
		componentPrefix: "components.contacts"
	}
});

export class DashboardTabShare {
	ID:number = 0;
	TabID:number = 0;
	UserID:number = 0;

	public constructor(data?:any) {
		if (!data) return;
		this.ID = data.ID;
		this.TabID = data.TabID;
		this.UserID = data.UserID;
	}

	public getJSON(){
		return {
			ID: this.ID,
			TabID: this.TabID,
			UserID: this.UserID
		};
	}
}
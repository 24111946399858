import { i18n } from "@/setup/i18n-setup";
import { TranslateResult } from "vue-i18n";
import { Condition } from "../condition/condition";

export enum TextIfValueApplyToTotalOption{
	NO = "",
	YES = "yes",
	EXCLUSIVE = "exclusive"
}

export class TextIfValue {
	Text:string = "";
	ApplyToTotal:TextIfValueApplyToTotalOption = TextIfValueApplyToTotalOption.NO;
	NummericValue:number | null = null;
	Conditions:Condition[] = [];

	public constructor(data?:any){
		if (!data) return;
		this.Text = data.Text;
		this.ApplyToTotal = data.ApplyToTotal;
		this.NummericValue = data.NummericValue;
		this.Conditions = (data.Conditions || []).map((c:any)=>new Condition(c));
	}

	public getJSON(){
		return {
			Text: this.Text,
			ApplyToTotal: this.ApplyToTotal,
			NummericValue: this.NummericValue,
			Conditions: this.Conditions.map(c=>c.getJSON())
		};
	}

	public toString(getFieldName:(val:string)=>string):TranslateResult{
		let conditionText = Condition.conditionsToShortString(getFieldName, this.Conditions);
		return i18n.t("components.reportengine.text-if-value", {text: this.Text, conditions: conditionText});
	}
}
import { nullable, field, hidden } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";

@model("User")
export class User {
	@field("number")
	ID:number;
	@field("string")
	Username:string;
	@field("string")
	Firstname:string;
	@field("string")
	Lastname:string;
	@field("string")
	@nullable()
	Email:string | null;
	@field("string")
	Phone:string;
	@field("string")
	@hidden()
	ProfileImg:string;

	@field("string")
	UserString:string;
	@field("string")
	FullName:string;

	public constructor(data:any){
		this.ID = data.ID;
		this.Username = data.Username;
		this.Firstname = data.Firstname;
		this.Lastname = data.Lastname;
		this.Email = data.Email;
		this.Phone = data.Phone;
		this.ProfileImg = data.ProfileImg;
		this.UserString = data.UserString;
		this.FullName = data.FullName;
	}
}
import { ServerConfig } from "@/config/config";
import { ProductGroup } from "@/models/base/product-group";
import { Axios } from "@/utils/axios";

const URL = `${ServerConfig.host}/product-group`;

export class ProductGroupServiceClass {

	public constructor(){
	}

	private findGroup(id:number, groupList:ProductGroup[]):ProductGroup | null {
		for (let group of groupList) {
			if (id == group.ID) return group;
			let foundGroup = this.findGroup(id, group.Children);
			if (foundGroup) return foundGroup;
		}
		return null;
	}

	public async getTopLevelGroups():Promise<ProductGroup[]>{
		let result = await Axios.get(`${URL}`);
		let groups = result.data.map((g:any)=>new ProductGroup(g));
		return groups;
	}

	public async getAllGroups():Promise<ProductGroup[]>{
		let result = await Axios.get(`${URL}/all`);
		let groups = result.data.map((g:any)=>new ProductGroup(g));
		return groups;
	}

	public async getGroupByID(id:number):Promise<ProductGroup>{
		let result = await Axios.get(`${URL}/${id}`);
		return new ProductGroup(result.data);
	}

	public async getChildrenOfGroup(groupId:number, level:number = 0):Promise<ProductGroup[]>{
		let result = await  Axios.get(`${URL}/${groupId}/fetch-children/${level}`);
		return new ProductGroup(result.data).Children;
	}

	public async getAllChildrenOfGroup(groupId:number):Promise<ProductGroup[]>{
		let result = await Axios.get(`${URL}/${groupId}/fetch-all-children`);
		return new ProductGroup(result.data).Children;
	}

	public async updateGroups(groups:ProductGroup[]):Promise<ProductGroup[]>{
		let result = await Axios.post(`${URL}/update-structure`, groups.map(g=>g.getJSON()));
		return result.data.map((g:any)=>new ProductGroup(g));
	}

	public async addGroup(group:ProductGroup):Promise<ProductGroup>{
		let result = await Axios.post(`${URL}/add-group`, group.getJSON());
		return new ProductGroup(result.data);
	}

	public async addChildGroup(group:ProductGroup, parentGroupId:number):Promise<ProductGroup>{
		let result = await Axios.post(`${URL}/${parentGroupId}/add-child-group`, group.getJSON());
		return new ProductGroup(result.data);
	}

	public init(){
	}
}

export const ProductGroupService = new ProductGroupServiceClass();
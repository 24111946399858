import { getFileUrl } from "@/utils/url";
import { ILayoutImage } from "../layout-editor";

export class DocumentLayoutLogoBase implements ILayoutImage {
	public ImageToken = "";
	public X = 0;
	public Y = 0;
	public Width = 50;
	public Height = 50;
	public HideOnPdfOnPrint:boolean = false;
	public HideOnPdfOnEmailSend:boolean = false;
	public HideOnPdfOnDownload:boolean = false;

	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.HideOnPdfOnPrint = data.HideOnPdfOnPrint || false;
		this.HideOnPdfOnEmailSend = data.HideOnPdfOnEmailSend || false;
		this.HideOnPdfOnDownload = data.HideOnPdfOnDownload || false;
		this.ImageToken = data.ImageToken;
		this.X = Math.max(data.X, 0);
		this.Y = Math.max(data.Y, 0);
		this.Width = Math.max(data.Width, 0);
		this.Height = Math.max(data.Height, 0);
	}
	public getJSON() {
		return {
			ImageToken: this.ImageToken,
			X: Math.max(this.X, 0),
			Y: Math.max(this.Y, 0),
			Width: Math.max(this.Width, 0),
			Height: Math.max(this.Height, 0),
			HideOnPdfOnPrint: this.HideOnPdfOnPrint,
			HideOnPdfOnEmailSend: this.HideOnPdfOnEmailSend,
			HideOnPdfOnDownload: this.HideOnPdfOnDownload,
		};
	}

	public getImageUrl():string {
		return getFileUrl(this.ImageToken);
	}

	public getExtraSettingsComponent():string{
		return "";
	}
}



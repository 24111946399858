
import { Vat } from "@/models/base";
import { VatService } from "@/services/vat-service";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import AppVatEditModal from "@/components/common/VatEditModal.vue";

export default Vue.extend({
	data() {
		return {
			vats: [] as Vat[],
			toEditVat: null as null | Vat
		};
	},
	props: {
		value: [Number, Object as () => Vat],
		disabled: {
			type: Boolean,
			default: false
		},
		fancy: Boolean,
		light: Boolean,
		label: String,
		useObject: Boolean,
		useId: Boolean,
		includeNull: Boolean,
		defaultValue: {
			type: Number,
		},
		createNewEnabled: Boolean
	},
	computed: {
		items(): ISelectItem<any | Vat>[] {
			let allItems = this.vats.map(v => {
				let value:any | Vat = v.Value;
				if (this.useId){
					value = v.ID;
				}
				if (this.useObject){
					value = v;
				}
				return {
					text: v.Name + "%",
					value
				};
			});
			if (this.includeNull){
				allItems.unshift({
					text: this.$ct("include-null").toString(),
					value: null
				});
			}
			if (this.createNewEnabled){
				allItems.push({
					text: this.$ct("add-new-vat-percentage").toString(),
					value: -1
				});
			}

			return allItems;
		},
		selectedValue():Vat | number | undefined{
			if (this.useObject && typeof(this.value) == "object") {
				return this.vats.find(v=>v.ID == (this.value as Vat).ID);
			}
			return this.value;
		}
	},
	methods: {
		onInput(event: number | Vat) {
			if (event == -1){
				this.toEditVat = new Vat();
				return;
			}
			this.$emit("input", event);
		},
		focus() {
			(this.$refs.select as HTMLElement).focus();
		},
		onKeyUp(event: KeyboardEvent) {
			this.$emit("keyup", event);
		},
		onVatCreated(vat:Vat){
			if (this.useId){
				this.onInput(vat.ID);
				return;
			}
			if (this.useObject) {
				this.onInput(vat);
				return;
			}
			this.onInput(vat.Value);
		},
		async refreshVats(){
			this.vats = VatService.getVats();
		}
	},
	async mounted() {
		this.vats = VatService.getVats();
		if (this.value == null && this.defaultValue){
			let defaultVat = this.vats.find(v=>v.Value == this.defaultValue);
			if (!defaultVat) return;
			if (this.useObject) {
				this.$emit("input", defaultVat);
			}else if (this.useId){
				this.$emit("input", defaultVat.ID);
			}else{
				this.$emit("input", defaultVat.Value);
			}
		}
		VatService.on("vats-refreshed", this.refreshVats);
	},
	destroyed(){
		VatService.off("vats-refreshed", this.refreshVats);
	},
	components: {
		AppVatEditModal
	},
	i18nEx: {
		componentPrefix: "components.sale-settings"
	}
});

import { field, nullable, print } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";
import { printHumanFloatPercentage } from "@/utils/pretty-print";
import { ProductConfig } from "./product";

@model("ProductProfitRules")
export class ProductProfitRules {
	ProductID:number = 0;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedPercentage1:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedPercentage2:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedPercentage3:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedPercentage4:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedPercentage5:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedPercentage6:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedPercentage7:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedPercentage8:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedPercentage9:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedPercentage10:number | null = null;


	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedValue1:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedValue2:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedValue3:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedValue4:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedValue5:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedValue6:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedValue7:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedValue8:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedValue9:number | null = null;

	@field("number")
	@nullable()
	@print(printHumanFloatPercentage)
	public FixedValue10:number | null = null;


	public constructor(data?:any){
		if (!data) return;
		this.ProductID = data.ProductID;
		this.FixedPercentage1 = data.FixedPercentage1;
		this.FixedPercentage2 = data.FixedPercentage2;
		this.FixedPercentage3 = data.FixedPercentage3;
		this.FixedPercentage4 = data.FixedPercentage4;
		this.FixedPercentage5 = data.FixedPercentage5;
		this.FixedPercentage6 = data.FixedPercentage6;
		this.FixedPercentage7 = data.FixedPercentage7;
		this.FixedPercentage8 = data.FixedPercentage8;
		this.FixedPercentage9 = data.FixedPercentage9;
		this.FixedPercentage10 = data.FixedPercentage10;
		this.FixedValue1 = data.FixedValue1;
		this.FixedValue2 = data.FixedValue2;
		this.FixedValue3 = data.FixedValue3;
		this.FixedValue4 = data.FixedValue4;
		this.FixedValue5 = data.FixedValue5;
		this.FixedValue6 = data.FixedValue6;
		this.FixedValue7 = data.FixedValue7;
		this.FixedValue8 = data.FixedValue8;
		this.FixedValue9 = data.FixedValue9;
		this.FixedValue10 = data.FixedValue10;
	}


	public getJSON(){
		return {
			ProductID: this.ProductID,
			FixedPercentage1: this.FixedPercentage1,
			FixedPercentage2: this.FixedPercentage2,
			FixedPercentage3: this.FixedPercentage3,
			FixedPercentage4: this.FixedPercentage4,
			FixedPercentage5: this.FixedPercentage5,
			FixedPercentage6: this.FixedPercentage6,
			FixedPercentage7: this.FixedPercentage7,
			FixedPercentage8: this.FixedPercentage8,
			FixedPercentage9: this.FixedPercentage9,
			FixedPercentage10: this.FixedPercentage10,
			FixedValue1: this.FixedValue1,
			FixedValue2: this.FixedValue2,
			FixedValue3: this.FixedValue3,
			FixedValue4: this.FixedValue4,
			FixedValue5: this.FixedValue5,
			FixedValue6: this.FixedValue6,
			FixedValue7: this.FixedValue7,
			FixedValue8: this.FixedValue8,
			FixedValue9: this.FixedValue9,
			FixedValue10: this.FixedValue10,
		};
	}


	getFixedPercentageOfCategory(category:number):number | null {
		if (category < 1 || category > ProductConfig.sellPriceCount) {
			throw new Error("Invalid price category: " + category);
		}
		return this[`FixedPercentage${category}` as keyof ProductProfitRules] as number | null;
	}

	getFixedValueOfCategory(category:number):number | null {
		if (category < 1 || category > ProductConfig.sellPriceCount) {
			throw new Error("Invalid price category: " + category);
		}
		return this[`FixedValue${category}` as keyof ProductProfitRules] as number | null;
	}


	setFixedPercentageOfCategory(category:number, value:number | null) {
		if (category < 1 || category > ProductConfig.sellPriceCount) {
			throw new Error("Invalid price category: " + category);
		}
		return (this[`FixedPercentage${category}` as keyof ProductProfitRules] as number | null) = value;
	}

	setFixedValueOfCategory(category:number, value:number | null) {
		if (category < 1 || category > ProductConfig.sellPriceCount) {
			throw new Error("Invalid price category: " + category);
		}
		return (this[`FixedValue${category}` as keyof ProductProfitRules] as number | null) = value;
	}
}

import { SellCategories } from "@/models/dossier-configs/sell-categories";
import { Product } from "@/models/base";
import { DossierConfigService } from "@/services/dossier-config-service";
import { SellCategory } from "@/utils/sell-category";
import Vue from "vue";
import AppSellPrice from "./ProductEditSellPrice.vue";
import AppPriceCategorySalesDatesEdit from "./PriceCategorySalesDatesEdit.vue";
import { ISelectItem } from "winfakt-vue-components";
import { ProductProfitRules } from "@/models/base/product-profit-rules";

interface IVisibleCategories {
	categoryName: string;
	category: number;
}

export default Vue.extend({
	data() {
		return {
			visiblePrices: [] as IVisibleCategories[],
			toAddPricePerUnit: "0",
			selectedTab: 0
		};
	},
	props: {
		product: Object as () => Product
	},
	computed: {
		priceCategories(): SellCategories {
			return DossierConfigService.getSellCategories();
		},
		tabNames(): string[] {
			return this.visiblePrices.map(v => v.categoryName);
		},
		showAddPriceButton(): boolean {
			return this.visiblePrices.length < 10;
		},
		selectedPriceCategory(): number {
			return this.visiblePrices[this.selectedTab].category;
		},
		staticProfitValueDropdownItem():ISelectItem<Function>{
			if (!this.product.ProfitRules || this.product.ProfitRules.getFixedValueOfCategory(this.selectedPriceCategory) === null) {
				return {
					text: this.$ct("enable-static-profit-value"),
					value: this.enableStaticProfitValue
				};
			}
			return {
				text: this.$ct("disable-static-profit-value"),
				value: this.disableStaticProfitValue
			};
		},
		staticProfitPercentageDropdownItem():ISelectItem<Function>{
			if (!this.product.ProfitRules || this.product.ProfitRules.getFixedPercentageOfCategory(this.selectedPriceCategory) === null) {
				return {
					text: this.$ct("enable-static-profit-percentage"),
					value: this.enableStaticProfitPercentage
				};
			}
			return {
				text: this.$ct("disable-static-profit-percentage"),
				value: this.disableStaticProfitPercentage
			};
		},
		dropdownItems():ISelectItem<Function>[]{
			let items:ISelectItem<Function>[] = [
				this.staticProfitValueDropdownItem,
				this.staticProfitPercentageDropdownItem,
			];
			return items;
		}
	},
	watch: {
		product: {
			immediate: true,
			handler(product: Product) {
				this.updateAutomatedPrices();
				this.computeVisibleCategories();
			}
		},
		priceCategories: {
			immediate: true,
			handler(categories: SellCategories) {
				this.computeVisibleCategories();
			}
		},
		"product.VatID": {
			handler(){
				this.updateAutomatedPrices();
			}
		}
	},
	methods: {
		computeVisibleCategories() {
			this.visiblePrices = [];
			let cats = this.priceCategories.getCategories();
			for (let cat of cats) {
				if (cat.Active) {
					let catNumber = cats.indexOf(cat) + 1;
					this.visiblePrices.push({
						categoryName: this.priceCategories.getCategoryName(catNumber),
						category: catNumber
					});
				}
			}
			if (this.visiblePrices.length == 0) {
				this.addPriceCategory();
			}
		},
		addPriceCategory() {
			let toUsePriceCategory = 0;
			for (let i = 1; i <= 10; i++) {
				let price = this.visiblePrices.find(p => p.category == i);
				if (!price) {
					toUsePriceCategory = i;
					break;
				}
			}
			if (toUsePriceCategory == 0 || toUsePriceCategory > 10) {
				throw new Error(toUsePriceCategory + " IS NOT A VALID PRICE CATEOGRY NUMBER");
			}
			let sellPricesConfig = DossierConfigService.getSellCategories();
			(sellPricesConfig["Category" + toUsePriceCategory as keyof SellCategories] as SellCategory).Active = true;
			DossierConfigService.saveSellCategoryNames(sellPricesConfig);
			this.visiblePrices.push({
				categoryName: this.priceCategories.getCategoryName(toUsePriceCategory),
				category: toUsePriceCategory
			});
			this.selectedTab = this.visiblePrices.length - 1;
		},
		updateAutomatedPrices(){
			let sellCategories = DossierConfigService.getSellCategories();
			for (let config of sellCategories.getActiveCategories()) {
				if (config.settings.CalculateViaParameters){
					let supplier = this.product.getMainSupplier();
					if (!supplier) continue;
					let percentage = config.settings.calculateProfitBasedOnParameters(supplier.NettoPrice);
					let pricePerUnit = supplier.NettoPrice * (1 + (percentage / 100.0));
					this.product.setPriceByCategory(config.id, pricePerUnit);
				}
			}
		},
		getProfitValue():number{
			let el = this.$refs.sellPrice as any;
			if (!el) return 0;
			return el.computedValues.profit;
		},
		getProfitPercentage():number{
			let el = this.$refs.sellPrice as any;
			if (!el) return 0;
			return el.computedValues.profitPercentage;
		},
		createProfitRulesIfNotExist(){
			if (this.product.ProfitRules) return;
			this.product.ProfitRules = new ProductProfitRules();
			this.product.ProfitRules.ProductID = this.product.ID;
		},
		enableStaticProfitValue(){
			if (!this.product.ProfitRules) return;
			this.product.ProfitRules.setFixedPercentageOfCategory(this.selectedPriceCategory, null);
			this.product.ProfitRules.setFixedValueOfCategory(this.selectedPriceCategory, this.getProfitValue());
		},
		disableStaticProfitValue(){
			if (!this.product.ProfitRules) return;
			this.product.ProfitRules.setFixedValueOfCategory(this.selectedPriceCategory, null);
		},
		enableStaticProfitPercentage(){
			if (!this.product.ProfitRules) return;
			this.product.ProfitRules.setFixedValueOfCategory(this.selectedPriceCategory, null);
			this.product.ProfitRules.setFixedPercentageOfCategory(this.selectedPriceCategory, this.getProfitPercentage());
		},
		disableStaticProfitPercentage(){
			if (!this.product.ProfitRules) return;
			this.product.ProfitRules.setFixedPercentageOfCategory(this.selectedPriceCategory, null);
		},
	},
	created(){
		this.updateAutomatedPrices();
	},
	components: {
		AppSellPrice,
		AppPriceCategorySalesDatesEdit
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});


import Vue from "vue";
import { ExtraFieldNamesConfig } from "../models/dossier-configs/extra-field-names-config";
import { IExtraFields } from "../utils/models/extra-fields";
import { ExtraFieldNumberConfig } from "@/models/dossier-configs/extra-field-number-config";
import AppExtraFieldsEditText from "./ExtraFieldsEditText.vue";
import { ExtraFieldBaseConfig } from "@/models/dossier-configs/extra-field-base-config";

export default Vue.extend({
	props: {
		value: {
			type: Object as () => IExtraFields,
			required: true
		},
		nameSettings: {
			type: Object as () => ExtraFieldNamesConfig,
			required: true
		},
		saleTypeId: Number
	},
	computed: {
		locale(): string {
			return this.$i18n.locale;
		}
	},
	methods: {
		getLabel(type: string, count: number): string {
			let key = `${type}${count}` as keyof ExtraFieldNamesConfig;
			if ((this.nameSettings[key] as string) != "") {
				return this.nameSettings[key] as string;
			}
			return this.$ct(type, {prop: count}).toString();
		},
		getDecimals(count:number):number{
			return (this.nameSettings[`Num${count}Config` as keyof ExtraFieldNamesConfig] as ExtraFieldNumberConfig).Decimals;
		},
		isActive(type: string, count: number): boolean {
			let key = `${type}${count}` as keyof ExtraFieldNamesConfig;
			if (this.nameSettings[key] == "") return false;
			let conf = this.nameSettings[`${key}Config` as keyof ExtraFieldNamesConfig] as any as ExtraFieldBaseConfig;
			if (!conf) return true;
			if (this.saleTypeId) {
				if (conf.OnlyShownForSaleTypes.length > 0 && conf.OnlyShownForSaleTypes.indexOf(this.saleTypeId) == -1){
					return false;
				}
			}
			if (conf.HideInEditAndInfo) return false;
			return true;
		}
	},
	components: {
		AppExtraFieldsEditText
	},
	i18nEx: {
		componentPrefix: "components.ExtraFieldsEdit"
	}
});

import { ServerConfig } from "@/config/config";
import { AWSFile } from "@/models/aws-file";
import { Axios } from "@/utils/axios";
import axios from "axios";
import { printPdf } from "@/utils/pdf";
import { downloadBlob } from "@/utils/blob";
import { EventEmitter } from "@/utils/event-emitter";

export class AWSFileServiceClass extends EventEmitter{
	get url():string {
		return `${ServerConfig.host}/files`;
	}

	async downloadFile(token:string, fileName:string = ""):Promise<void> {
		let result = await this.getLink(token);
		if (!fileName) {
			let info = await this.getFileInfo(token);
			fileName = info.Name;
		}
		let response = await axios.get(result, {responseType: "blob"});
		downloadBlob(response.data, fileName);
	}

	async getFileInfo(token:string):Promise<AWSFile>{
		let result = await Axios.get(`${this.url}/${token}/info`);
		return new AWSFile(result.data);
	}

	async getLink(token:string):Promise<string> {
		let result = await Axios.get(`${this.url}/${token}/link`);
		return result.data.Url;
	}

	async saveFile(file:AWSFile):Promise<void>{
		await this.downloadFile(file.Token, file.Name);
	}

	async fetchFile(token:string, fileName:string):Promise<File>{
		let link = await this.getLink(token);
		let result = await axios.get(link, {responseType: "blob"});
		let data = result.data as File;
		(data as any).name = fileName;
		(data as any).lastModifiedDate = new Date();
		return data;
	}

	async printFile(token:string, fileName:string){
		let link = await this.getLink(token);
		let result = await axios.get(link, {responseType: "blob"});
		let data = result.data as File;
		(data as any).name = fileName;
		(data as any).lastModifiedDate = new Date();
		await printPdf(data, "documents.pdf");
	}

	async listFiles(path:string):Promise<AWSFile[]> {
		let result = await Axios.get(`${this.url}/ls${path}`);
		return result.data.map((f:any) => new AWSFile(f));
	}

	async uploadFile(path:string, file:File):Promise<AWSFile> {
		let data = new FormData();
		data.append("file", file, file.name);
		let result = await Axios.post(`${this.url}${path}`, data);
		return new AWSFile(result.data);
	}

	async deleteFiles(files:AWSFile[]):Promise<void> {
		await Axios.delete(`${this.url}`, {
			data: files.map(f => f.ID)
		});
	}

	previewAwsFile(file:AWSFile){
		this.emit("preview-file", file);
	}
};

export const AWSFileService = new AWSFileServiceClass();
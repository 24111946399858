
import { v4 as  uuid } from "uuid";
import Vue from "vue";
import AppMonthSelect from "@/components/common/MonthSelect.vue";

export default Vue.extend({
	data(){
		let month = new Date().getMonth() +1;
		if (month > 12) {
			month = 1;
		}
		return {
			id: uuid(),
			selectedTab: 0,
			startMonth: 1,
			endMonth: 12,
			month,
		};
	},
	props: {
		value: {
			type: String,
			required: true
		},
		label: String,
	},
	computed: {
		tabs():string[]{
			return [this.$ct("month-range").toString(), this.$ct("quarter-select").toString()];
		},
	},
	watch: {
		value: {
			handler(){
				let val = JSON.parse(this.value);
				if (val.startMonth) {
					this.month = val.startMonth;
					let quarter = this.getQuarter(val.startMonth);
					this.startMonth = this.getStartMonthOfQuarter(quarter);
					if (val.endMonth == 12 && this.startMonth == 1) {
						this.endMonth = 12;
					}else if (val.endMonth) {
						this.endMonth = this.startMonth + 3;
					}
				}
			}
		},
	},
	methods: {
		onInput(){
			if (this.selectedTab == 0) {
				this.$emit("input", JSON.stringify({startMonth: this.month, endMonth: this.month}));
			}else{
				this.$emit("input", JSON.stringify({startMonth: this.startMonth, endMonth: this.endMonth}));
			}
		},
		getQuarter(month:number):number{
			if (month <= 3){
				return 1;
			}
			if (month <= 6){
				return 2;
			}
			if (month <= 9){
				return 3;
			}
			return 4;
		},
		getStartMonthOfQuarter(quarter:number):number {
			if (quarter == 1) {
				return 1;
			}else if(quarter == 2) {
				return 4;
			}else if(quarter == 3) {
				return 7;
			}
			return 10;
		},
		setStartMonth(month:number){
			this.startMonth = month;
			this.onInput();
		},
		setEndMonth(month:number){
			this.endMonth = month;
			this.onInput();
		},
		isQuarterSelected(quarter:number):boolean{
			if (this.startMonth == 1 && this.endMonth == 12) {
				return false;
			}
			return this.startMonth == this.getStartMonthOfQuarter(quarter);
		},
		selectQuarter(quarter:number){
			this.startMonth = (((quarter-1)*3)+1);
			this.endMonth = this.startMonth +2;
			this.onInput();
		},
		isYearSelected():boolean{
			return this.startMonth == 1 && this.endMonth == 12;
		},
		selectYear(){
			this.startMonth = 1;
			this.endMonth = 12;
			this.onInput();
		},
		setSelectedTab(value:number){
			this.selectedTab = value;
			if (value == 0){
				this.setMonth(this.month);
			} else if (value == 1) {
				this.selectQuarter(1);
			}
		},
		setMonth(month:number){
			this.month = month;
			this.onInput();
		}
	},
	created(){
		this.onInput();
	},
	components: {
		AppMonthSelect
	},
	i18nEx: {
		componentPrefix: "components.reportengine"
	}
});

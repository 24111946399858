/* eslint-disable max-lines */
import { ignoredFields, model } from "@/utils/models/decorator-model";
import { field, filterValueInput, hidden, nullable, print } from "@/utils/models/decorator-field";
import { Contact, Sale, Purchase } from "@/models/base/index";
import { printBoolean, printLocalDate } from "@/utils/pretty-print";
import { CarAwsDocument } from "@/models/base/car-aws-document";
import CarExtraFields from "./car-extra-fields";

@model("Car")
@ignoredFields([
	"Supplier.MainAddress.Contact",
	"Contact.MainAddress.Contact",
	"Invoice.Contact.MainAddress.Contact",
	"Invoice.InvoiceAddress.Contact",
	"Invoice.DeliveryAddress.Contact",
	"Invoice.LastBank.Invoice",
	"Invoice.LastBank.Purchase",
	"Invoice.LastBank.FriendlyPurchaseID",
	"Invoice.LastBank.PurchaseID",
	"Invoice.LastBank.Contact",
	"Invoice.LastBank.Ticket.LastBank",
	"Invoice.LastBank.Sale.Banks",
	"Invoice.LastBank.Ticket.Contact",
	"Invoice.LastBank.Ticket.InvoiceAddress.Contact",
	"Invoice.LastBank.Ticket.DeliveryAddress.Contact",
	"Purchase.LastBank.Invoice",
	"Purchase.LastBank.Purchase",
	"Purchase.LastBank.FriendlyPurchaseID",
	"Purchase.LastBank.PurchaseID",
	"Purchase.LastBank.Contact",
	"Purchase.LastBank.Ticket",
	"Purchase.Contact.MainAddress.Contact",
	"Invoice.Car"
])
export default class Car {

	@field("number")
	public ID:number = 0;

	@field("boolean")
	public Active:boolean = true;

	@field("string")
	public CustomLabel:string = ""

	@field("string")
	public Plate:string = "";

	@field("number")
	public Mileage:number = 0;

	@field("string")
	public Brand:string = "";

	@field("string")
	public Chassis:string = "";

	@field("string")
	public Engine:string = "";

	@field("string")
	public KeyCode:string = "";

	@field("string")
	public RadioCode:string = "";

	@field("string")
	public InteriorColor:string = "";

	@field("string")
	public ExteriorColor:string = "";

	@field("string")
	public MtM:string = "";

	@field("string")
	public Cabine:string = "";

	@field("string")
	public Gear:string = "";

	@field("string")
	public Payload:string = "";

	@field("string")
	public Tyres:string = "";

	@field("string")
	public Construction:string = "";

	@field("string")
	public Volume:string = "";

	@field("string")
	public Suspension:string = "";

	@field("string")
	public Power:string = "";

	@field("string")
	public Cilinder:string = "";

	@field("string")
	public Co2:string = "";

	@field("string")
	public Transmission:string = "";

	@field("string")
	public Fuel:string = "";

	@field("string")
	public Info:string = "";

	@field("string")
	public ExtraInfo:string = "";

	@field("string")
	public Type:string = "";


	@field("number")
	public InvoiceID:number = 0;

	@field("has-one", "Sale")
	@nullable()
	public Invoice:Sale | null = null;

	@field("number")
	public PurchaseID:number = 0;

	@field("has-one", "Purchase")
	@nullable()
	public Purchase:Purchase | null = null;

	@field("number")
	@filterValueInput("Contact")
	public ContactID:number = 0;

	@field("has-one", "Contact")
	@nullable()
	public Contact:Contact | null = null;

	@field("number")
	public SupplierID:number = 0;

	@field("has-one", "Contact")
	@nullable()
	public Supplier:Contact | null = null;

	@field("date")
	@nullable()
	@print(printLocalDate)
	public ConstructionYear:  Date | null = null;

	@field("date")
	@nullable()
	@print(printLocalDate)
	public CommDate: Date | null = null;

	@field("date")
	@nullable()
	@print(printLocalDate)
	public PurchaseDate: Date | null = null;

	@field("date")
	@nullable()
	@print(printLocalDate)
	public InvoiceDate: Date | null = null;

	@field("boolean")
	public UseInCarpass:boolean = false;

	public AWSDocuments:CarAwsDocument[] = [];
	public UnprocessedFiles: File[] = [];
	public toDeleteFiles:CarAwsDocument[] = [];

	@field("boolean")
	@print(printBoolean)
	public HasAttachedDocument:boolean = false;

	@field("number")
	@hidden()
	public ExtraFieldsID:number = 0;

	@field("has-one", "CarExtraFields")
	public ExtraFields:CarExtraFields | null = null;


	// eslint-disable-next-line max-lines-per-function
	public constructor(data: any = null) {
		if (!data) {
			this.ExtraFields = new CarExtraFields();
			return;
		};
		this.ID = data.ID;
		this.Active = data.Active;
		this.CustomLabel =data.CustomLabel;
		this.Plate = data.Plate;
		this.Mileage = data.Mileage;
		this.Brand = data.Brand;
		this.Chassis = data.Chassis;
		this.Engine = data.Engine;
		this.KeyCode = data.KeyCode;
		this.RadioCode = data.RadioCode;
		this.InteriorColor = data.InteriorColor;
		this.ExteriorColor = data.ExteriorColor;
		this.MtM = data.MtM;
		this.Cabine = data.Cabine;
		this.Gear = data.Gear;
		this.Payload = data.Payload;
		this.Tyres = data.Tyers;
		this.Construction = data.Construction;
		this.Volume = data.Volume;
		this.Suspension = data.Suspension;
		this.Power = data.Power;
		this.Cilinder = data.Cilinder;
		this.Co2 = data.Co2;
		this.Transmission = data.Transmission;
		this.Fuel = data.Fuel;
		this.Info = data.Info;
		this.ExtraInfo = data.ExtraInfo;
		this.Type = data.Type;
		this.InvoiceID = data.InvoiceID;
		this.PurchaseID = data.PurchaseID;
		this.ContactID = data.ContactID;
		if(data.Contact){
			this.Contact = new Contact(data.Contact);
		}
		this.SupplierID = data.SupplierID;
		if(data.Supplier){
			this.Supplier = new Contact(data.Supplier);
		}
		this.ConstructionYear = data.ConstructionYear ? new Date(data.ConstructionYear) : null;
		this.CommDate = data.CommDate ? new Date(data.CommDate) : null;
		this.PurchaseDate = data.PurchaseDate ? new Date(data.PurchaseDate) : null;
		this.InvoiceDate = data.InvoiceDate ? new Date(data.InvoiceDate) : null;
		if(data.Invoice){
			this.Invoice = new Sale(data.Invoice);
		}
		if(data.Purchase){
			this.Purchase = new Purchase(data.Purchase);
		}
		if (data.AWSDocuments) {
			this.AWSDocuments = (data.AWSDocuments as any[]).map(d=>new CarAwsDocument(d));
		}
		this.UseInCarpass = data.UseInCarpass;
		this.HasAttachedDocument = data.HasAttachedDocument;
		this.ExtraFieldsID = data.ExtraFieldsID;
		if (data.ExtraFields) {
			this.ExtraFields = new CarExtraFields(data.ExtraFields);
		}

	}

	// eslint-disable-next-line max-lines-per-function
	public getJSON(): any {
		return {
			ID: this.ID,
			Active: this.Active,
			CustomLabel: this.CustomLabel,
			Plate: this.Plate,
			Mileage: this.Mileage,
			Brand: this.Brand,
			Chassis: this.Chassis,
			Engine: this.Engine,
			KeyCode: this.KeyCode,
			RadioCode: this.RadioCode,
			InteriorColor: this.InteriorColor,
			ExteriorColor: this.ExteriorColor,
			MtM: this.MtM,
			Cabine: this.Cabine,
			Gear: this.Gear,
			Payload: this.Payload,
			Tyres: this.Tyres,
			Construction: this.ConstructionYear,
			Volume: this.Volume,
			Suspension: this.Suspension,
			Power: this.Power,
			Cilinder: this.Cilinder,
			Co2: this.Co2,
			Transmission: this.Transmission,
			Fuel: this.Fuel,
			Info: this.Info,
			ExtraInfo: this.ExtraInfo,
			Type: this.Type,
			InvoiceID: this.InvoiceID,
			Invoice: new Sale(this.Invoice?.getJSON()),
			Purchase: new Purchase(this.Purchase?.getJSON()),
			PurchaseID: this.PurchaseID,
			ContactID: this.ContactID,
			SupplierID: this.SupplierID,
			ConstructionYear: this.ConstructionYear,
			CommDate: this.CommDate,
			PurchaseDate: this.PurchaseDate,
			InvoiceDate: this.InvoiceDate,
			UseInCarpass: this.UseInCarpass,
			HasAttachedDocument: this.HasAttachedDocument,
			ExtraFieldsID: this.ExtraFieldsID,
			ExtraFields: this.ExtraFields ? this.ExtraFields.getJSON() : null,
		};
	}

	public getDisplayName():string{
		return this.CustomLabel.trim() || this.Plate.trim() || `${this.Brand} ${this.Type}`.trim() || this.Chassis.trim();
	}

}
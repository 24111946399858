import {ServerConfig} from "@/config/config";
import {Axios} from "@/utils/axios";
import {advancedSearch} from "@/utils/browse/browse";
import {IGetResponse} from "@/utils/get-response";
import {HumanFilter} from "@/utils/human-filter";
import {IQueryParams, IQueryParamsWithOptionalView} from "@/utils/query-params";
import Crm from "@/models/crm/crm";
import CrmStatus from "@/models/crm/crm-status";
import CrmHistory from "@/models/crm/crm-history";
import {View} from "@/models/view";
import {FetchedTotal} from "@/utils/views/fetched-total";
import { IInitAppResponse } from "./init-service";
import { getModel } from "@/utils/models/model";

export class CrmServiceClass {
	public statuses: CrmStatus[] = [];

	get url(): string {
		return ServerConfig.host + "/crm";
	}

	get historyUrl(): string {
		return ServerConfig.host + "/crm-history";
	}


	async getSettings() {
		let result = await Axios.get(ServerConfig.host + "/browse/11", {
			params: {}
		});
		return result.data;
	}

	async get(id: number): Promise<Crm> {
		let result = await Axios.get(this.url + "/" + id);
		return new Crm(result.data);
	}

	async getTotals(view: View): Promise<FetchedTotal[]> {
		let result = await Axios.get(`${this.url}/view/${view.ID}/totals`);
		return (result.data || []).map((d: any) => new FetchedTotal(d));
	}

	async getCrm(query: IQueryParamsWithOptionalView, extraFilters:HumanFilter[] = []): Promise<IGetResponse<Crm>> {
		let result = await advancedSearch(query, this.url, getModel("Crm"), extraFilters);
		result.data.data = result.data.data.map((s: any) => new Crm(s));
		return result.data;
	}

	async search(queryString: string, query: IQueryParams): Promise<IGetResponse<Crm>> {
		let result = await Axios.get(this.url + "/view/" + query.view.ID, {
			params: {
				limit: query.limit,
				offset: query.offset,
				order: query.orderBy,
				orderDirection: query.orderDirection,
				filters: [
					new HumanFilter({
						Field: "this.ID",
						Operator: "like",
						Values: [queryString]
					}),
				] as HumanFilter[]
			}
		});
		result.data.data = result.data.data.map((s: any) => new Crm(s));
		return result.data;
	}

	async delete(crm: Crm[]): Promise<void> {
		await Axios.delete(this.url, {
			data: crm.map(p => p.ID)
		});
	}

	async saveCrm(crm: Crm): Promise<Crm> {
		let result = await Axios.post(this.url, crm, {});
		return new Crm(result.data);
	}

	async putCrm(crm: Crm): Promise<Crm> {
		let result = await Axios.put(`${this.url}`, crm.getJSON());
		return new Crm(result.data);
	}

	async getCrmStatuses(): Promise<CrmStatus[]> {
		let result = await Axios.get(this.url + "/list/statuses");
		let items =  result.data.data.map((s: any) => new CrmStatus(s));
		this.statuses = items;
		return this.statuses;
	}

	async postCrmStatus(status: CrmStatus): Promise<CrmStatus> {
		let result = await Axios.post(this.url + "/status", status);
		this.getCrmStatuses();
		return new CrmStatus(result.data);
	}

	async deleteCrmStatus(id: number): Promise<void> {
		await Axios.delete(this.url + "/status/"+ id);
		this.getCrmStatuses();
	}

	async putCrmStatus(status: CrmStatus): Promise<CrmStatus> {
		let result = await Axios.put(this.url + "/status/" + status.ID, status);
		this.getCrmStatuses();
		return new CrmStatus(result.data);
	}
	async putCrmStatuses(statuses: CrmStatus[]): Promise<CrmStatus[]> {
		let result = await Axios.put(this.url + "/status/position" , statuses);
		this.getCrmStatuses();
		return result.data.data.map((s: any) => new CrmStatus(s));
	}

	async getCrmHistory(id: number): Promise<CrmHistory[]> {
		let result = await Axios.get(this.url + "/" + id);
		return result.data.data.map((s: any) => new CrmHistory(s));
	}

	async postCrmHistory(histories: CrmHistory[]): Promise<CrmHistory[]> {
		let result = await Axios.post(`${this.historyUrl}`, histories.map(h => h.getJSON()));
		return result.data.map((c: any) => new CrmHistory(c));
	}

	async putCrmHistory(histories: CrmHistory[]){
		await Axios.put(`${this.historyUrl}`, histories.map(h => h.getJSON()));
	}

	async init(data:IInitAppResponse){
		this.statuses = data.CrmStatuses;
	}

};


export const CrmService = new CrmServiceClass();
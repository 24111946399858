
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { ISelectItem } from "winfakt-vue-components";

export default Vue.extend({
	props: {
		value: {
			type: String,
			required: true
		},
		label: {
			type: [Object as ()=>TranslateResult, String],
			default():TranslateResult{
				return this.$ct("bank-kind");
			}
		}
	},
	computed: {
		items():ISelectItem<string>[] {
			return [
				{
					text: this.$ct("components.layout-editor.print"),
					value: "print"
				},
				{
					text: this.$ct("components.layout-editor.download"),
					value: "download"
				},
				{
					text: this.$ct("components.layout-editor.send"),
					value: "send"
				}
			];
		},
	},
	methods: {
		setValue(value:string){
			this.$emit("input", `${value}`);
		}
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});

export class UblDossierConfig {
	CostCenter:string = "";
	IncludeSalePdf:boolean = false;
	AccountingCost:string = "";
	UblType:string = "";
	UseLedgerAccountAsInvoiceLineName:boolean = false;
	IncludeSaleSeperatelyInZip:boolean = false;
	FilterPurchasesOnVatPeriod:boolean = false;
	BookkeeperEmail:string = "";
	LeeggoedLedger:string = "418000";
	ClearFactsCompatibiltiy:boolean = false;
	IncludeClientsXml:boolean = false;
	PeppolGlnEV:string = "";
	PeppolBankTypeID:number = 0;

	public constructor(data?:any){
		if (!data) return;
		this.CostCenter = data.CostCenter;
		this.IncludeSalePdf = data.IncludeSalePdf;
		this.AccountingCost = data.AccountingCost;
		this.UblType = data.UblType || "invoice";
		this.UseLedgerAccountAsInvoiceLineName = data.UseLedgerAccountAsInvoiceLineName;
		this.FilterPurchasesOnVatPeriod = data.FilterPurchasesOnVatPeriod;
		this.IncludeSaleSeperatelyInZip = data.IncludeSaleSeperatelyInZip;
		this.BookkeeperEmail = data.BookkeeperEmail;
		this.LeeggoedLedger = data.LeeggoedLedger || "418000";
		this.ClearFactsCompatibiltiy = data.ClearFactsCompatibiltiy;
		this.IncludeClientsXml = data.IncludeClientsXml;
		this.PeppolGlnEV = data.PeppolGlnEV;
		this.PeppolBankTypeID = data.PeppolBankTypeID;
	}

	public getJSON(){
		return {
			CostCenter: this.CostCenter,
			IncludeSalePdf: this.IncludeSalePdf,
			AccountingCost: this.AccountingCost,
			UblType: this.UblType,
			UseLedgerAccountAsInvoiceLineName: this.UseLedgerAccountAsInvoiceLineName,
			FilterPurchasesOnVatPeriod: this.FilterPurchasesOnVatPeriod,
			IncludeSaleSeperatelyInZip: this.IncludeSaleSeperatelyInZip,
			BookkeeperEmail: this.BookkeeperEmail,
			LeeggoedLedger: this.LeeggoedLedger,
			ClearFactsCompatibiltiy: this.ClearFactsCompatibiltiy,
			IncludeClientsXml: this.IncludeClientsXml,
			PeppolGlnEV: this.PeppolGlnEV,
			PeppolBankTypeID: this.PeppolBankTypeID
		};
	}
}
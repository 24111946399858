import { CtepBrandID } from "@/utils/electron/electron-ctep-brands";

export interface ICtepDefaultBankForPaymentType {
	BankTypeID:number;
	PaymentType:CtepBrandID;
}

export class BankConfig {
	CtepDefaultBankForPaymentTypes:ICtepDefaultBankForPaymentType[] = [];

	public constructor(data:any){
		this.CtepDefaultBankForPaymentTypes = [...(data.CtepDefaultBankForPaymentTypes || [])];
	}

	public getJSON(){
		return {
			CtepDefaultBankForPaymentTypes: [...this.CtepDefaultBankForPaymentTypes]
		};
	}

	public getBankIdByCtepBrandID(brandId:string):number{
		for (let setting of this.CtepDefaultBankForPaymentTypes){
			if (setting.PaymentType == brandId) {
				return setting.BankTypeID || 0;
			}
		}
		return 0;
	}
}
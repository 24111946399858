
import { MODULE } from "@/config/modules";
import { Contact } from "@/models/base";
import { ContactPriceRule } from "@/models/base/contact-price-rule";
import { AuthService } from "@/services/auth-service";
import Vue from "vue";
import AppMissingModule from "@/components/common/MissingModule.vue";
import AppPriceRuleEdit from "@/components/price-rules/PriceRuleEdit.vue";
import { Browse } from "@/models/browse";
import { BrowseService } from "@/services";

export default Vue.extend({
	data(){
		return {
			toEditRule: null as ContactPriceRule | null
		};
	},
	props: {
		contact: {
			type: Object as ()=>Contact,
			required: true
		}
	},
	computed: {
		isAllowed(): Boolean {
			return AuthService.hasModule(this.moduleId);
		},
		moduleId():number {
			return MODULE.PRIJSBEDING;
		},
		browse():Browse | null {
			return BrowseService.getBrowseWithoutViewSettings(7);
		}
	},
	methods: {
		onSorted(){
			this.contact.PriceRules.forEach((r,i)=>{
				r.DisplayOrder = i;
			});
		},
		deleteRule(rule:ContactPriceRule){
			let i = this.contact.PriceRules.indexOf(rule);
			if (i == -1) {
				return;
			}
			this.contact.PriceRules.splice(i,1);
		},
		editRule(rule:ContactPriceRule){
			this.toEditRule = rule;
		},
		addRule(){
			this.toEditRule = new ContactPriceRule();
		},
		onRuleSaved(rule:ContactPriceRule){
			if (!this.toEditRule) return;
			let i = this.contact.PriceRules.indexOf(this.toEditRule);
			if (i == -1) {
				this.contact.PriceRules.push(rule);
			}else{
				this.contact.PriceRules[i] = rule;
			}
		}
	},
	components: {
		AppMissingModule,
		AppPriceRuleEdit
	},
	i18nEx: {
		componentPrefix: "components.contacts"
	}
});

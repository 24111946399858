import { ServerConfig } from "@/config/config";
import {Contact, SaleExtraFields, Vat} from "@/models/base";
import { Job } from "@/models/job";
import { Sale } from "@/models/base";
import { SaleHistory, SaleHistoryEventType } from "@/models/base";
import { SaleLayoutConf } from "@/models/sale-layout";
import { SaleType } from "@/models/base";
import { Axios } from "@/utils/axios";
import { BookYearService } from "@/services/book-year-service";
import { UserConfigService } from "@/services/user-config-service";
import { base64ToBlob, downloadBlob } from "@/utils/blob";
import { advancedSearch, advancedSearchStaticView } from "@/utils/browse/browse";
import { BrowserInfo } from "@/utils/browser";
import { HumanFilter } from "@/utils/human-filter";
import { formdataFromIMail, IMail } from "@/utils/mail";
import { SaleLayout } from "@/utils/sale-layouts/sale-layout";
import { SalesSaveAsData } from "@/utils/documents/sales-save-as-data";
import { MainSocketService } from "./main-socket-service";
import { SaleLayoutInfo } from "@/utils/sale-layouts/sale-layout-info";
import { INextAvailableIDResponse } from "@/utils/next-available-id-respone";
import { tokenStore } from "@/utils/token-store";
import { HttpError } from "@/utils/services/http-error";
import { UpgradeService } from "@/services/upgrade-service";
import { PdfImages } from "@/utils/pdf-images";
import { Deposit } from "@/models/base/deposit";
import { printPdf } from "@/utils/pdf";
import { PrintContext, SalePrintSettings } from "@/utils/sale-layouts/print-settings";
import { View } from "@/models/view";
import { FetchedTotal } from "@/utils/views/fetched-total";
import { SaleAwsDocument } from "@/models/base/sale-aws-document";
import { IQueryParams, IQueryParamsWithOptionalView } from "@/utils/query-params";
import { IGetResponse } from "@/utils/get-response";
import { DossierConfigService } from "./dossier-config-service";
import { ElectronFunctions } from "@/utils/electron/electron";
import { SaleLayoutHistory } from "@/models/sale-layout-history";
import { IInitAppResponse } from "./init-service";
import { MailSettings } from "@/models/mail-settings";
import { InitInfo } from "@/utils/init-info";
import { IExtraFieldQuickEditViaMenuDataResult } from "@/utils/models/extra-fields";
import { DocumentLayoutHeading } from "@/utils/documents/layout-heading";
import { DocumentLayoutClientHeading } from "@/utils/documents/layout-client-heading";
import { VatService } from "./vat-service";
import { AuthService } from ".";
import { i18n } from "@/setup/i18n-setup";
import { AutoSaleRowSetting } from "@/utils/sale-edit/auto-sale-row-setting";
import { getModel } from "@/utils/models/model";

export interface ISaleFile{
	FileName:string;
	Data:string;
	MimeType:string;
	LayoutID:number;
}

export interface ISaleImageFile{
	FileName:string;
	Data:PdfImages;
	MimeType:string;
	LayoutID:number;
}


export type LayoutTemplateFormat = "json" | "jpg"


export class SaleServiceClass {
	private initialized:boolean = false;
	public saleTypes: SaleType[] = [];
	public readonly url = ServerConfig.host + "/sale";
	public readonly purchaseDocumentUrl = ServerConfig.host + "/purchase-document"
	public readonly prospectDocumentUrl = ServerConfig.host + "/prospect-document"
	public readonly historyUrl = ServerConfig.host + "/sale-history";
	public readonly typeUrl = ServerConfig.host + "/sale-type";
	public readonly layoutUrl = ServerConfig.host + "/sale-layout";
	public readonly pdfUrl = ServerConfig.host + "/pdf";
	public readonly depositUrl = ServerConfig.host + "/deposit";
	private defaultLayout:string = "";
	private autoSaleRowSettings:any[] = [];

	selectedSales = [] as Sale[];


	private getDefaultLayouIdOfType(saleTypeId:number, context:PrintContext):number {
		let conf = DossierConfigService.getSaleConfig(saleTypeId);
		if (!conf) {
			return 0;
		}
		let layoutIds = conf.getLayoutIds(context);
		if (layoutIds.length == 0) {
			return 0;
		}
		return layoutIds[0];
	}

	async getSales(query: IQueryParamsWithOptionalView, bookyear: string = "", extraFilters:HumanFilter[] = []): Promise<IGetResponse<Sale>> {
		let result = await advancedSearch(query, this.url, getModel("Sale"), extraFilters, {Bookyear: bookyear});
		let records = result.data.records as number;
		let sales = result.data.data.map((c: any) => new Sale(c));
		return {data: sales, records};
	}

	public async getSalesByIds(ids:number[]):Promise<Sale[]>{
		let result = await Axios.get(`${this.url}/by-ids`, {params: {ids}});
		return (result.data as any[]).map(c=> new Sale(c));
	}

	async getSalesStaticView(view:View, query: IQueryParams, bookyear: string = "", extraFilters:HumanFilter[] = []):Promise<IGetResponse<Sale>> {
		let result = await advancedSearchStaticView(view, query, this.url + "/raw-view", extraFilters, {Bookyear: bookyear});
		let records = result.data.records as number;
		let sales = result.data.data.map((c: any) => new Sale(c));
		return {data: sales, records};
	}
	async getTotals(view:View):Promise<FetchedTotal[]>{
		let result = await Axios.get(`${this.url}/view/${view.ID}/totals`);
		return (result.data || []).map((d:any)=>new FetchedTotal(d));
	}

	async getPurchaseDocuments(query:IQueryParamsWithOptionalView, bookyear:string = "", extraFilters:HumanFilter[] = []):Promise<IGetResponse<Sale>>{
		let result = await advancedSearch(query, this.purchaseDocumentUrl, getModel("Sale"), extraFilters, {Bookyear: bookyear});
		let records = result.data.records as number;
		let sales = result.data.data.map((c: any) => new Sale(c));
		return {data: sales, records};
	}


	async getPurchaseDocumentTotals(view:View):Promise<FetchedTotal[]>{
		let result = await Axios.get(`${this.purchaseDocumentUrl}/view/${view.ID}/totals`);
		return (result.data || []).map((d:any)=>new FetchedTotal(d));
	}

	async getProspectDocuments(query:IQueryParams, bookyear:string = "", extraFilters:HumanFilter[] = []):Promise<IGetResponse<Sale>>{
		let result = await advancedSearch(query, this.prospectDocumentUrl, getModel("Sale"), extraFilters, {Bookyear: bookyear});
		let records = result.data.records as number;
		let sales = result.data.data.map((c: any) => new Sale(c));
		return {data: sales, records};
	}


	async getProspectDocumentTotals(view:View):Promise<FetchedTotal[]>{
		let result = await Axios.get(`${this.prospectDocumentUrl}/view/${view.ID}/totals`);
		return (result.data || []).map((d:any)=>new FetchedTotal(d));
	}

	async getSale(id: number, bookyear?: number): Promise<Sale> {
		let result = await Axios.get(`${this.url}/${id}`, {headers: {Bookyear: bookyear || ""}});
		return new Sale(result.data);
	}

	async getSalesCount():Promise<Number>{
		let result = await Axios.get(`${this.url}`);
		return result.data.records;
	}

	async getByFriendlyId(id:string, bookyear?:number):Promise<Sale>{
		let result = await Axios.get(`${this.url}/by-computed-id/${id}`, {headers: {BookYear: bookyear || ""}});
		return new Sale(result.data);
	}

	async search(input: string): Promise<Sale[]> {
		let result = await Axios.get(`${this.url}`, {
			params: {
				limit: 10,
				filters: [
					new HumanFilter({Field: "this.ComputedFriendlyID", Operator: "like", Values: [input]}),
					new HumanFilter({Field: "this.CompanyName", Operator: "like", Values: [input], IsOr: true})
				]
			}
		});
		return result.data.data.map((s: any) => new Sale(s));
	}

	async postSale(sale: Sale): Promise<Sale> {
		sale.calculateTotals();
		sale.BookYear = BookYearService.selectedBookYear;
		sale.Rows.forEach(row => {
			row.BookYear = sale.BookYear;
		});


		try{
			let result = await Axios.post(`${this.url}`, sale.getJSON());
			let s = new Sale(result.data);
			if(sale.UnprocessedFiles.length){
				for (let file of sale.UnprocessedFiles){
					await this.uploadDocument(s,file);
				}
			}
			return s;
		}catch(err){
			let error = err as HttpError;
			if (error.key == "MAX_SALES_REACHED") {
				error.dontShow = true;
				await UpgradeService.showUpgradeModal(0);
			}
			throw error;
		}


	}

	async putSale(sale: Sale): Promise<Sale> {
		sale.calculateTotals();
		sale.Rows.forEach(row => {
			row.SaleID = sale.ID;
			row.BookYear = sale.BookYear;
		});
		let result = await Axios.put(`${this.url}`, sale.getJSON());
		let s = new Sale(result.data);
		for (let file of sale.UnprocessedFiles){
			await this.uploadDocument(s,file);
		}
		for (let file of sale.toRemoveFiles) {
			await this.deleteDocument(s, file);
		}
		await this.updateDocuments(s, sale.toUpdateFiles);
		return s;
	}

	async uploadDocuments(sale: Sale): Promise<Sale>{
		if(sale.UnprocessedFiles.length){
			for (let file of sale.UnprocessedFiles){
				await this.uploadDocument(sale,file);
			}
		}
		return this.getSale(sale.ID);
	}

	getTypeName(typeId:number):string {
		let foundType = this.saleTypes.find(t=>t.ID == typeId);
		if (!foundType) {
			return "";
		}
		return foundType.DisplayedName;
	}


	getTypes(contactGroupId:number = 1): SaleType[] {
		let order = UserConfigService.getSaleSettings().SaleDisplayOrder;
		if (contactGroupId == 2) {
			order = UserConfigService.getSaleSettings().PurchaseDisplayOrder;
		}else if (contactGroupId == 3) {
			contactGroupId = 1;
			order = UserConfigService.getSaleSettings().SaleDisplayOrder;
		}
		return this.saleTypes.filter(t=>t.ContactGroupID == contactGroupId).sort((a,b)=>{
			let aIndex = order.indexOf(a.ID);
			let bIndex = order.indexOf(b.ID);
			if (aIndex == -1 || bIndex == -1) {
				return a.ID - b.ID;
			}
			return aIndex - bIndex;
		}).map(t => new SaleType(t.getJSON()));
	}

	getAllTypes():SaleType[] {
		return this.saleTypes.map(t=>new SaleType(t.getJSON()));
	}


	async fetchTypes(): Promise<SaleType[]> {
		let result = await Axios.get(`${this.typeUrl}`, {});
		this.saleTypes = result.data.data.map((i: any) => new SaleType(i));
		return this.saleTypes;
	}

	async getType(id: number): Promise<SaleType> {
		let result = await Axios.get(`${this.typeUrl}/${id}`);
		return new SaleType(result.data);
	}

	async updateType(saleType:SaleType):Promise<SaleType>{
		let result = await Axios.put(`${this.typeUrl}/${saleType.ID}`, saleType.getJSON());
		saleType = new SaleType(result.data);
		await this.fetchTypes();
		return saleType;
	}

	async createType(saleType:SaleType):Promise<SaleType> {
		let result = await Axios.post(`${this.typeUrl}`, saleType.getJSON());
		saleType = new SaleType(result.data);
		await this.fetchTypes();
		return saleType;
	}

	async deleteType(saleType:SaleType):Promise<void> {
		await Axios.delete(`${this.typeUrl}/${saleType.ID}`);
		await this.fetchTypes();
	}

	async delSales(sales: Sale[]): Promise<Job> {
		let result = await Axios.delete(`${this.url}`, {
			data: sales.map(i => i.ID)
		});
		return new Job(result.data);
	}

	async delSale(sale:Sale):Promise<void>{
		await Axios.delete(`${this.url}/${sale.ID}`);
	}

	async verifyRemainingSales():Promise<boolean>{
		let res = await Axios.get(`${this.url}/remaining-sales-to-make-this-month`);
		return res.data.RemainingDocuments != 0;
	}

	async getSalesByContactID(contactID: number, queryParams: IQueryParams, bookyear: string = "", additionalFilters:HumanFilter[] = []): Promise<IGetResponse<Sale>> {
		let f = new HumanFilter({
			Field: "this.ContactID",
			Operator: "=",
			Values: [`${contactID}`],
		});
		additionalFilters.push(f);
		let result = await advancedSearch(queryParams, this.url, getModel("Sale"), additionalFilters, {Bookyear: bookyear});
		let records = result.data.records as number;
		let sales = result.data.data.map((c: any) => new Sale(c));
		return {data: sales, records};
	}

	async advancedSearchByContactID(contactID: number, query: IQueryParams, bookear: string = ""): Promise<IGetResponse<Sale>> {
		let result = await advancedSearch(query, this.url, getModel("Sale"), [new HumanFilter({
			Field: "this.ContactID",
			Operator: "=",
			Values: [`${contactID}`]
		})], {Bookyear: bookear});
		let records = result.data.records as number;
		let sales = result.data.data.map((c: any) => new Sale(c));
		return {data: sales, records};
	}

	// Is used to filter on contact when importing sales
	async getPurchaseByContactID(contactID: number, queryParams: IQueryParams, bookyear: string = ""): Promise<IGetResponse<Sale>> {
		let filter = new HumanFilter({
			Field: "this.ContactID",
			Operator: "=",
			Values: [`${contactID}`],
		});
		let result = await advancedSearch(queryParams, this.purchaseDocumentUrl, getModel("Sale"), [filter], {Bookyear: bookyear});
		let records = result.data.records as number;
		let sales = result.data.data.map((c: any) => new Sale(c));
		return {data: sales, records};
	}

	async getSaleHistoryByContactID(contactID: number, queryParams: IQueryParams, bookyear: string = "", additionalFilters:HumanFilter[] = []): Promise<IGetResponse<Sale>> {
		let f = new HumanFilter({
			Field: "this.ContactID",
			Operator: "=",
			Values: [`${contactID}`],
		});
		additionalFilters.push(f);
		let result = await advancedSearchStaticView(queryParams.view, queryParams, this.url + "/raw-view", additionalFilters, {Bookyear: bookyear},["Rows"]);
		let records = result.data.records as number;
		let sales = result.data.data.map((c: any) => new Sale(c));
		return {data: sales, records};
	}

	async advancedPurchaseSearchByContactID(contactID: number, query: IQueryParams, bookear: string = ""): Promise<IGetResponse<Sale>> {
		let result = await advancedSearch(query, this.purchaseDocumentUrl, getModel("Sale"), [new HumanFilter({
			Field: "this.ContactID",
			Operator: "=",
			Values: [`${contactID}`]
		})], {Bookyear: bookear});
		let records = result.data.records as number;
		let sales = result.data.data.map((c: any) => new Sale(c));
		return {data: sales, records};
	}


	// Is used to filter on contact when importing sales
	async getProspectDocumentByContactID(contactID: number, queryParams: IQueryParams, bookyear: string = ""): Promise<IGetResponse<Sale>> {
		let filter = new HumanFilter({
			Field: "this.ContactID",
			Operator: "=",
			Values: [`${contactID}`],
		});
		let result = await advancedSearch(queryParams, this.prospectDocumentUrl, getModel("Sale"), [filter], {Bookyear: bookyear});
		let records = result.data.records as number;
		let sales = result.data.data.map((c: any) => new Sale(c));
		return {data: sales, records};
	}

	async advancedProspectSearchByContactID(contactID: number, query: IQueryParams, bookear: string = ""): Promise<IGetResponse<Sale>> {
		let result = await advancedSearch(query, this.prospectDocumentUrl, getModel("Sale"), [new HumanFilter({
			Field: "this.ContactID",
			Operator: "=",
			Values: [`${contactID}`]
		})], {Bookyear: bookear});
		let records = result.data.records as number;
		let sales = result.data.data.map((c: any) => new Sale(c));
		return {data: sales, records};
	}

	async getSaleLayouts(): Promise<SaleLayoutInfo[]> {
		let result = await Axios.get(`${this.layoutUrl}`);
		let layouts:SaleLayoutInfo[] = result.data.map((l: any) => new SaleLayoutInfo(l));
		let conf = UserConfigService.getSaleSettings();
		layouts = layouts.sort((a,b)=>{
			if (a.ContactGroupID != b.ContactGroupID){
				return a.ContactGroupID-b.ContactGroupID;
			}
			let order = conf.getSaleLayoutDisplayOrderByContactGroupId(a.ContactGroupID);
			let ia = order.indexOf(a.ID);
			let ib = order.indexOf(b.ID);
			if (ia == -1 || ib == -1){
				return a.ID - b.ID;
			}
			return ia-ib;
		});
		return layouts;
	}

	async getSaleLayout(id: number): Promise<SaleLayoutConf> {
		let result = await Axios.get(`${this.layoutUrl}/${id}`);
		return new SaleLayoutConf(result.data);
	}

	async postSaleLayouts(layouts: SaleLayoutConf[]): Promise<SaleLayoutConf[]> {
		let result = await Axios.post(`${this.layoutUrl}`, layouts.map(l => l.getJSON()));
		return result.data.map((l: any) => new SaleLayoutConf(l));
	}

	async putSaleLayouts(layouts: SaleLayoutConf[]): Promise<SaleLayoutConf[]> {
		let result = await Axios.put(`${this.layoutUrl}`, layouts.map(l => l.getJSON()));
		return result.data.map((l: any) => new SaleLayoutConf(l));
	}

	async deleteSaleLayout(layout:SaleLayoutConf | SaleLayoutInfo): Promise<void> {
		await Axios.delete(`${this.layoutUrl}/${layout.ID}`);
	}

	async getSaleLayoutHistory(layoutId:number, offset:number, limit:number):Promise<IGetResponse<SaleLayoutInfo>>{
		let result = await Axios.get(`${this.layoutUrl}/${layoutId}/history?offset=${offset}&limit=${limit}`);
		return {
			records: result.data.records || 0,
			data: result.data.data.map((r:any)=>new SaleLayoutInfo(r))
		};
	}

	async initSaleLayouts(initInfo:InitInfo):Promise<void>{
		await Axios.post(`${this.layoutUrl}/init`, initInfo.getInitLayoutsJson());
	}

	async getDefaultSaleLayout():Promise<SaleLayout>{
		if (this.defaultLayout){
			return new SaleLayout(this.defaultLayout);
		}
		let result = await Axios.get(`${this.layoutUrl}/default-sale-layout`);
		this.defaultLayout = JSON.stringify(result.data);
		return new SaleLayout(result.data);
	}

	async getSaleLayoutHistoryById(layoutId:number, historyId:number):Promise<SaleLayoutHistory>{
		let result = await Axios.get(`${this.layoutUrl}/${layoutId}/history/${historyId}`);
		return new SaleLayoutHistory(result.data);
	}

	async getSaleHistoryBySaleId(saleId: number): Promise<SaleHistory[]> {
		let result = await Axios.get(`${this.historyUrl}`, {
			params: {
				filters: [new HumanFilter({Field: "this.SaleID", Operator: "=", Values: [`${saleId}`]})],
				limit: 1000
			}
		});
		return result.data.data.map((h: any) => new SaleHistory(h));
	}

	async postSaleHistory(histories: SaleHistory[]): Promise<SaleHistory[]> {
		let result = await Axios.post(`${this.historyUrl}`, histories.map(h => h.getJSON()));
		return result.data.map((h: any) => new SaleHistory(h));
	}

	async putSaleHistory(histories: SaleHistory[]): Promise<SaleHistory[]> {
		let result = await Axios.put(`${this.historyUrl}`, histories.map(h => h.getJSON()));
		return result.data.map((h: any) => new SaleHistory(h));
	}

	async deleteHistory(histories: SaleHistory[]): Promise<void> {
		await Axios.delete(`${this.historyUrl}`, {
			data: histories.map(h => h.ID)
		});
	}

	async downloadSaleLayoutPreview(layout: SaleLayout, lang: string = "nl-be"): Promise<void> {
		let blob = await this.getSaleLayoutPreview(layout, lang);
		let url = window.URL.createObjectURL(blob);
		let a = document.createElement("a");
		a.href = url;
		a.download = "preview.pdf";
		a.click();
		window.URL.revokeObjectURL(url);
	}

	async getSaleLayoutPreview(layout: SaleLayout, lang: string = "nl-be"): Promise<Blob> {
		let result = await Axios.post(`${this.layoutUrl}/download-preview`, {
			Lang: lang,
			Layout: layout.getJSON(),
		}, {responseType: "blob"});
		return new Blob([result.data], {type: "application/pdf"});
	}

	async downloadSale(sale: Sale | number, layout: SaleLayoutConf | number, settings:SalePrintSettings): Promise<Blob> {
		if (typeof (sale) == "object") {
			sale = sale.ID;
		}
		if (typeof (layout) == "object") {
			layout = layout.ID;
		}
		let result = await Axios.get(`${this.url}/${sale}/download/${layout}`, {
			responseType: "blob",
			params: settings.getHttpQuery(),
		});
		let blob = new Blob([result.data], {type: "application/pdf"});
		return blob;
	}

	async downloadLayoutTemplate(layout:SaleLayout, format:LayoutTemplateFormat = "json"):Promise<void>{
		var result = await Axios.post(`${this.layoutUrl}/download-template?format=${format}`, layout.getJSON(), {
			responseType: "blob",
		});

		if (!result.headers) return;
		if (!result.headers.getContentType) return;
		let type = result.headers.getContentType as string;
		let blob = new Blob([result.data], {type });
		downloadBlob(blob, `layout.${format}`);
	}

	async getLayoutConfFromImage(image:File):Promise<SaleLayout>{
		let form = new FormData();
		form.set("file", image, image.name);
		let result = await Axios.post(`${this.layoutUrl}/from-image`, form);
		return new SaleLayout(result.data);
	}

	async downloadSaleAsImages(sale:Sale | number, layout:SaleLayoutConf | number, settings:SalePrintSettings):Promise<PdfImages>{
		if (typeof (sale) == "object") {
			sale = sale.ID;
		}
		if (typeof (layout) == "object") {
			layout = layout.ID;
		}
		let result = await Axios.get(`${this.url}/${sale}/download/${layout}?img=true`, {
			params: settings.getHttpQuery(),
		});
		return new PdfImages(result.data);
	}

	async getSaleFileName(sale: Sale | number, layout: SaleLayoutConf | number): Promise<string> {
		let fallbackName = "";
		if (typeof (sale) == "object") {
			fallbackName = `${sale.ComputedFriendlyID}.pdf`;
			sale = sale.ID;
		}else{
			fallbackName = `${sale}.pdf`;
		}
		if (typeof (layout) == "object") {
			layout = layout.ID;
		}
		let result = await Axios.get(`${this.url}/${sale}/file-name/${layout}`);
		return result.data || fallbackName;
	}

	private async addPrintSaleHistory(sale:Sale | number){
		let event = new SaleHistory();
		event.EventTypeID = SaleHistoryEventType.Print;
		if (typeof (sale) == "object") {
			event.SaleID = sale.ID;
			event.BookYear = sale.BookYear;
		} else {
			event.SaleID = sale;
			event.BookYear = BookYearService.selectedBookYear;
		}
		event.TimeStamp = new Date();
		await this.postSaleHistory([event]);
	}

	async downloadAsJson(sale: Sale | number, layout: SaleLayoutConf | number = 0, settings:SalePrintSettings = new SalePrintSettings(PrintContext.PRINT)):Promise<ISaleFile[]>{
		if (typeof(sale) == "number") {
			sale = await this.getSale(sale);
		}
		if (typeof(layout) == "object"){
			layout = layout.ID;
		}
		let response = await Axios.get(`${this.url}/${sale.ID}/download-as-json/${layout}`, {params: settings.getHttpQuery()});
		let files:ISaleFile[] = response.data;
		return files;
	}

	async printSale(sale: Sale | number, layout: SaleLayoutConf | number = 0, settings:SalePrintSettings = new SalePrintSettings(PrintContext.PRINT)): Promise<any> {
		settings.context = PrintContext.PRINT;
		if (BrowserInfo.isMobile()){
			let saleId = sale;
			if (typeof (sale) == "object") {
				saleId = sale.ID;
			}
			let fileName = await this.getSaleFileName(sale, layout);
			if (typeof (layout) == "object") {
				layout = layout.ID;
			}

			let url = `${this.url}/${saleId}/download/${layout}/${tokenStore.getSelectedToken().token}/${tokenStore.socketId}/${fileName}?${settings.getHttpQueryAsString()}`;
			await this.addPrintSaleHistory(sale);
			window.open(url, "_blank");
			return;
		}

		let files:ISaleFile[] = await this.downloadAsJson(sale, layout, settings);
		await this.addPrintSaleHistory(sale);
		for (let file of files) {
			let data = base64ToBlob(file.Data, file.MimeType);
			if (BrowserInfo.isElectron()){
				layout = file.LayoutID;
				let defaultPrinterId = ElectronFunctions.getDefaultPrinterIdForSaleLayout(layout);
				if (defaultPrinterId){
					let copies = ElectronFunctions.getCopyCountForSaleLayout(layout);
					if (settings.forceSingleCoppy) {
						copies = 1;
					}
					await ElectronFunctions.printPdf(data, defaultPrinterId, copies);
					continue;
				}
			}
			await printPdf(data, file.FileName);
		}
	}

	async printSalesMultiple(saleIds:number[]){
		let result = await Axios.get(`${this.url}/download-multiple/${saleIds.join(",")}`, {
			responseType: "blob",
			params: {createContext: PrintContext.PRINT}
		});
		let data = new Blob([result.data], {type: "application/pdf"});
		await printPdf(data, "documents.pdf");
	}

	async printPreview(sale:Sale):Promise<void>{
		let result = await Axios.post(`${this.url}/download-preview`, sale.getJSON({includeProducts: true}), {
			responseType: "blob",
		});
		let data = new Blob([result.data], {type: "application/pdf"});
		await printPdf(data, "documents.pdf");
	}

	async downloadAndSaveSale(sale: Sale, layout: SaleLayoutConf | number, settings:SalePrintSettings = new SalePrintSettings(PrintContext.DOWNLOAD)): Promise<void> {
		settings.context = PrintContext.DOWNLOAD;
		let blob = await this.downloadSale(sale, layout, settings);
		let name = await this.getSaleFileName(sale, layout);
		if (BrowserInfo.isElectron() && ElectronFunctions.getDownloadFolderForSaleType(sale.TypeID)){
			await ElectronFunctions.downloadFileToPath(blob, `${ElectronFunctions.getDownloadFolderForSaleType(sale.TypeID)}/${name}`);
			AuthService.mainVueInstance.$wf.notify(i18n.t("common.file-saved", {fileName: name}), "success", 3000, "check");
		}else{
			downloadBlob(blob, name);
		}
		let event = new SaleHistory();
		event.EventTypeID = SaleHistoryEventType.Download;
		if (typeof (sale) == "object") {
			event.SaleID = sale.ID;
			event.BookYear = sale.BookYear;
		} else {
			event.SaleID = sale;
			event.BookYear = BookYearService.selectedBookYear;
		}
		event.TimeStamp = new Date();
		await this.postSaleHistory([event]);
	}

	async generatePublicUrl(sale: Sale | number, returnToken?: Boolean): Promise<string> {
		if (typeof (sale) == "object") {
			sale = sale.ID;
		}
		let result = await Axios.get(`${this.url}/generate-url/${sale}`);

		if(returnToken)
			return result.data.Token;

		return location.protocol + "//" + location.host + "/sale-url/" + result.data.Token;
	}


	async afpunten(contact?: Contact): Promise<Job> {
		let url = this.url + "/afpunten";
		if (contact) {
			url += contact.ID;
		}
		let result = await Axios.post(url);
		return new Job(result.data);
	}

	async saldosOverboeken(): Promise<Job> {
		let result = await Axios.post(`${this.url}/saldos-overboeken`);
		return new Job(result.data);
	}

	async copyBackordersToNewBookYear():Promise<Job>{
		let result = await Axios.post(`${this.url}/copy-backorders-to-new-bookyear`);
		return new Job(result.data);
	}

	async fillLedgers():Promise<Job>{
		let result = await Axios.post(`${this.url}/fill-ledgers`);
		return new Job(result.data);
	}

	async getReferencingImportSales(saleId: number): Promise<Sale[]> {
		let result = await Axios.get(`${this.url}/${saleId}/referencing-import-sales`);
		return result.data.map((d: any) => new Sale(d));
	}

	async importFromOffline(token: string): Promise<Job> {
		let result = await Axios.post(`${ServerConfig.host}/offline/import/${token}/sales`);
		return new Job(result.data);
	}

	async getNextAvailableComputedID(typeId: number, journalId:number): Promise<INextAvailableIDResponse> {
		let result = await Axios.get(`${this.url}/get-next-computed-friendly-id/${typeId}/${journalId}`);
		return {
			friendlyId: result.data.FriendlyID,
			computedFriendlyId: result.data.ComputedFriendlyID
		};
	}

	async init(data:IInitAppResponse) {
		this.saleTypes = data.SaleTypes;
		this.autoSaleRowSettings = data.AutoSaleRowSetting;
		if (!this.initialized){
			this.initialized = true;
			MainSocketService.on("/sale-type/update", this.onSaleTypeUpdate.bind(this));
		}
	}

	private onSaleTypeUpdate(data:any){
		let st = new SaleType(data);
		let foundType = this.saleTypes.find(s=>s.ID = st.ID);
		if (!foundType){
			this.saleTypes.push(st);
		}else{
			foundType.onUpdate(st);
		}
	}

	async sendSale(saleId: number, mail: IMail, settings:MailSettings, addReadConfirm: boolean): Promise<void> {
		let data = formdataFromIMail(mail);
		data.set("addReadConfirmation", addReadConfirm ? "true" : "false");
		await Axios.post(`${this.url}/${saleId}/send/${settings.ID}`, data);
	}

	async convertToCreditNote(saleIds: number[]): Promise<Sale[]> {
		let result = await Axios.post(`${this.url}/convert-to-credit-note`, saleIds);
		return result.data.map((s: any) => new Sale(s));
	}

	async getSaleImportRowDescription(saleId:number, lang:string):Promise<string>{
		let result = await Axios.get(`${this.url}/get-sale-import-row-description/${saleId}/${lang}`);
		return result.data.Result;
	}

	async fixFriendlyIds(saleTypeId:number, journalId:number):Promise<void>{
		await Axios.post(`${this.url}/fix-numbering/${saleTypeId}/${journalId}`);
	}

	async fixImportedInSales(saleIds:number[]):Promise<Job>{
		let result = await Axios.post(`${this.url}/fix-imported-in-sales`, saleIds);
		return new Job(result.data);
	}

	async saveSalesAs(data:SalesSaveAsData):Promise<Job>{
		let result = await Axios.post(`${this.url}/save-as`, data.getJSON());
		return new Job(result.data);
	}

	async hasSales():Promise<boolean> {
		let result = await Axios.get(`${this.url}/has-sales`);
		return result.data;
	}

	async getDepositsByContactID(contactId:number):Promise<Deposit[]>{
		try{
			let result = await Axios.get(`${this.depositUrl}/by-contact-id/${contactId}`);
			return (result.data as any[]).map(d=>new Deposit(d));
		}catch(err){
			let e = err as HttpError;
			if (e.status == 401){
				e.dontShow = true;
			}
			return [] as Deposit[];
		}
	}

	async getDepositsByCreatedByDocumentId(documentId:number):Promise<Deposit[]>{
		let result = await Axios.get(`${this.depositUrl}/by-created-by-document-id/${documentId}`);
		return (result.data as any[]).map(d=>new Deposit(d));
	}

	public async uploadDocument(sale:Sale, file:SaleAwsDocument):Promise<SaleAwsDocument>{
		if (!file.toUploadFile) throw new Error("No file");
		let data = new FormData();
		data.append("file", file.toUploadFile, file.toUploadFile.name);
		data.append("doc-options", JSON.stringify(file.getJSON()));
		let result = await Axios.post(`${this.url}/${sale.ID}/document`, data);
		return new SaleAwsDocument(result.data);
	}

	public async deleteDocument(sale:Sale, document:SaleAwsDocument):Promise<void>{
		await Axios.delete(`${this.url}/${sale.ID}/document/${document.ID}`);
	}

	public async updateDocuments(sale:Sale, documents:SaleAwsDocument[]):Promise<void>{
		if (documents.length == 0) return;
		await Axios.put(`${this.url}/${sale.ID}/documents`, documents.map(d=>d.getJSON()));
	}

	public async hasBanks(sale:Sale):Promise<boolean>{
		let result = await Axios.get(`${this.url}/${sale.ID}/has-banks`);
		return result.data.HasBanks == true;
	}

	public async createDagontvangst(saleType:SaleType | number, startDate:Date, endDate:Date, invoiceDate:Date):Promise<number>{
		if (typeof(saleType) == "object") {
			saleType = saleType.ID;
		}
		let result = await Axios.post(`${this.url}/create-dagontvangst/${saleType}`, {BeginDate: startDate, EndDate: endDate, InvoiceDate: invoiceDate});
		return result.data.Count || 0;
	}

	public async signSale(sale:Sale, signedBy:string, blob:Blob):Promise<Sale>{
		let data = new FormData();
		data.append("file", blob, "signature.png");
		data.append("name", signedBy);
		let result = await Axios.post(`${this.url}/sign/${sale.ID}`, data);
		return new Sale(result.data);
	}


	public async changeComputedFriendlyID(sale:Sale, computedFriendlyId:string, friendlyId:number):Promise<Sale>{
		let result = await Axios.post(`${this.url}/${sale.ID}/change-number`, {ComputedFriendlyID: computedFriendlyId, FriendlyID: friendlyId});
		return new Sale(result.data);
	}

	public async saleHasBanks(sale:Sale|number):Promise<boolean>{
		if (typeof(sale) == "object") {
			sale = sale.ID;
		}
		let result = await Axios.get(`${this.url}/${sale}/has-banks`);
		return result.data.HasBanks;
	}

	public async salesHaveBanks(sales:(Sale|number)[]):Promise<boolean>{
		sales = sales.map(s=>typeof(s)=="object" ? s.ID : s);
		let result = await Axios.get(`${this.url}/have-banks`,{ params: {saleIds: JSON.stringify(sales)}});
		return result.data.HaveBanks;
	}

	public async updateExtraFields(sale:Sale, extraFields:SaleExtraFields):Promise<SaleExtraFields>{
		let result = await Axios.put(`${this.url}/extra-fields/${sale.ExtraFieldsID}`, extraFields.getJSON());
		return new SaleExtraFields(result.data);
	}

	public async batchEditExtraField(sales:Sale[], field:string, value:IExtraFieldQuickEditViaMenuDataResult) {
		let body = {
			ExtraFieldIDs: sales.map(s=>s.ExtraFieldsID),
			Field: field,
			NumValue: value.numValue,
			TextValue: value.textValue,
			BoolValue: value.boolValue,
			TimeValue: value.timeValue
		};
		await Axios.post(`${this.url}/batch-edit-extra-field`, body);
	}

	public async applyHeadingToLayouts(layouts:SaleLayoutInfo[], heading:DocumentLayoutHeading){
		await Axios.post(`${this.layoutUrl}/apply-heading`, {
			LayoutIDs: layouts.map(l=>l.ID),
			Heading: heading.getJSON(),
		});
	}

	public async applyClientHeadingToLayouts(layouts:SaleLayoutInfo[], heading:DocumentLayoutClientHeading, useDeliveryAddress:boolean = false){
		await Axios.post(`${this.layoutUrl}/apply-client-heading`, {
			LayoutIDs: layouts.map(l=>l.ID),
			Heading: heading.getJSON(),
			UseDeliveryAddress: useDeliveryAddress
		});
	}

	public async linkWiyhAwsDocuments(sale:Sale, awsFileIds:number[]):Promise<void>{
		await Axios.post(`${this.url}/${sale.ID}/link-with-aws-documents`, awsFileIds);
	}

	public getDefaultNewSaleVat():Vat {
		try{
			return VatService.getVat(UserConfigService.getSaleSettings().DefaultNewSaleVatID);
		}catch(err){
			return VatService.getDefaultVat();
		}
	}

	public async batchSend(sales:(Sale | number)[]):Promise<Job>{
		sales = sales.map(s=>{
			if (typeof(s) == "number"){
				return s;
			}
			return s.ID;
		});
		let result = await Axios.post(`${this.url}/batch-send`, sales);
		return new Job(result.data);
	}

	public async setDefaultExtraFieldValues():Promise<void>{
		await Axios.post(`${this.url}/set-default-extra-field-values`);
	}

	public async addDeposit(saleId:number, amount:number):Promise<void>{
		await Axios.post(`${this.url}/${saleId}/add-deposit`, {Amount: amount});
	}

	public getAutoSaleRowSettings():AutoSaleRowSetting[] {
		return this.autoSaleRowSettings.map(a=>new AutoSaleRowSetting(a));
	}
};

export const SaleService = new SaleServiceClass();
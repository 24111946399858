interface IZipCodeWithoutCountry {
	zip: string;
	city: string;
}

interface IZipCodeSourceDoc {
	codes:IZipCodeWithoutCountry[];
}

export interface IZipCode extends IZipCodeWithoutCountry{
	countryCode:string;
}

function addZipCodeCountry(z:IZipCodeWithoutCountry, countryCode:string):IZipCode{
	return {
		zip: z.zip,
		city: z.city,
		countryCode
	};
}

let zipcodes = [] as IZipCode[];

export async function getZipcodes(countryFilter?:any):Promise<IZipCode[]>{

	if (zipcodes.length > 0 && !countryFilter){
		return zipcodes;
	}
	let zipcodesBelgiumWithoutCountry = await import("@/assets/json/zipcode-belgium.json") as IZipCodeSourceDoc;
	let zipcodesBelgium = zipcodesBelgiumWithoutCountry.codes.map(z=>addZipCodeCountry(z, "BE"));
	let zipcodesNetherlandsWithoutCountry = await import("@/assets/json/zipcode-netherlands.json") as IZipCodeSourceDoc;
	let zipcodesNetherlands = zipcodesNetherlandsWithoutCountry.codes.map(z=>addZipCodeCountry(z, "NL"));
	zipcodes = [...zipcodesBelgium, ...zipcodesNetherlands];
	if (countryFilter) {
		return zipcodes.filter(z => z.countryCode === countryFilter);
	}
	return zipcodes;
}
import { i18n } from "@/setup/i18n-setup";
import { DashboardItemType } from "./dashboard-item";
import { BankService } from "@/services";

export class DashboardBankData {
	public bankIds:number[] = [];
	public title:string = ""
	public addTotal:boolean = false;

	public constructor(data?:any, type?:DashboardItemType) {
		if (!data) {
			if (type == DashboardItemType.BANK_SALDO) {
				this.title = i18n.t("components.dashboard.item-type.BANK_SALDO").toString();
			}
			return;
		}
		this.title = data.title;
		if (data.bankIds){
			this.bankIds = JSON.parse(data.bankIds || "[]") as number[];
		}else{
			let types = BankService.getTypes();
			if (types.length > 0){
				this.bankIds = [types[0].ID];
			}
		}
		this.addTotal = data.addTotal == "true";
	}

	public getJSON(){
		return {
			bankIds: JSON.stringify([...this.bankIds]),
			title: this.title,
			addTotal: `${this.addTotal}`
		};
	}
}
export class ContactsConfig{
	DefaultPaymentDays:number = 0;
	DefaultDiscountCashDays:number = 8;
	DefaultCountry:string = "BE";
	CheckForDuplicateAddress:boolean = false;
	ContactSelectVatID:boolean = false;
	DefaultSellPriceCategoryForNewClients:number = 0;

	public constructor(data:any){
		this.DefaultPaymentDays = data.DefaultPaymentDays;
		this.DefaultDiscountCashDays = data.DefaultDiscountCashDays == undefined ? 8 : data.DefaultDiscountCashDays;
		this.DefaultCountry = data.DefaultCountry || "BE";
		this.CheckForDuplicateAddress = data.CheckForDuplicateAddress || false;
		this.ContactSelectVatID = data.ContactSelectVatID || false;
		this.DefaultSellPriceCategoryForNewClients = data.DefaultSellPriceCategoryForNewClients || 0;
	}

	public getJSON(){
		return {
			DefaultPaymentDays: this.DefaultPaymentDays,
			DefaultDiscountCashDays: this.DefaultDiscountCashDays,
			DefaultCountry: this.DefaultCountry,
			CheckForDuplicateAddress: this.CheckForDuplicateAddress,
			ContactSelectVatID: this.ContactSelectVatID,
			DefaultSellPriceCategoryForNewClients: this.DefaultSellPriceCategoryForNewClients
		};
	}
}
export class ExtraFieldBaseConfig {
	public EditableViaBrowseMenu:boolean = false;
	public HideInEditAndInfo:boolean = false;
	// Only used with sales
	public OnlyShownForSaleTypes:number[] = [];

	public constructor(data?:any) {
		if (!data) return;
		this.EditableViaBrowseMenu = data.EditableViaBrowseMenu;
		this.HideInEditAndInfo = data.HideInEditAndInfo || false;
		this.OnlyShownForSaleTypes = [...(data.OnlyShownForSaleTypes || [])];
	}

	public getJSON():any{
		return {
			EditableViaBrowseMenu: this.EditableViaBrowseMenu,
			HideInEditAndInfo: this.HideInEditAndInfo,
			OnlyShownForSaleTypes: this.OnlyShownForSaleTypes
		};
	}
}
import { i18n } from "@/setup/i18n-setup";
import { Condition, Operator } from "./condition/condition";
import { getModel } from "./models/model";
import { SaleRow } from "@/models/base";

export interface IPriceRule {
	ID:number;
	Filters:Condition[];
	Price:number;
	Discount:number;
	BuyPercentage:number;
	BuyAmount:number;
	DisplayOrder:number;
	PriceCategory:number;
	toString():string;
	test(val:SaleRow):boolean;
	compare(ruel:IPriceRule):boolean;
	makeCopy():IPriceRule;
}

export function priceRuleToString(rule:IPriceRule):string{
	let result = "";
	for (let i in rule.Filters) {
		let filter = rule.Filters[i];
		let str = priceRuleFilterToString(filter);
		if (i != "0") {
			if (filter.IsOr) {
				result += ` ${i18n.t("common.or")} `;
			}else{
				result += ` ${i18n.t("common.and")} `;
			}
		}
		result += str;
	}
	if(rule.BuyPercentage != 0){
		return i18n.t("components.settings-default.buy-discount-rule-as-text", {discount: rule.BuyPercentage.toFixed(2), rules: result}).toString();
	}
	if(rule.Discount != 0){
		return i18n.t("components.settings-default.discount-rule-as-text", {discount: rule.Discount.toFixed(2), rules: result}).toString();
	}
	return i18n.t("components.settings-default.price-rule-as-text", {price: rule.Price.toFixed(2), rules: result}).toString();
}

export function priceRuleFilterToString(filter:Condition){
	let model = getModel("SaleRow");
	let field = model.getField(filter.Prop);
	if (!field) {
		return "ERROR";
	}
	let operator = i18n.t(`common.condition.short-operators.${filter.Operator}`);
	if (filter.Operator == Operator.IS_EMPTY || filter.Operator == Operator.IS_NOT_EMPTY) {
		return `${field.getPropertyTranslation()} ${operator}`;
	}
	return filter.Info ? `${filter.Info}` : `${field.getPropertyTranslation()} ${operator} ${filter.Value}`;
}

function filterToNonTranslatedString(filter:Condition):string {
	let operator = `${filter.Operator}`;
	return `${filter.Value} ${operator} ${filter.Value}`;
}

function toUntranslatedString(rule:IPriceRule):string{
	let result = "";
	for (let i in rule.Filters) {
		let filter = rule.Filters[i];
		let str = filterToNonTranslatedString(filter);
		if (i != "0") {
			if (filter.IsOr) {
				result += ` or `;
			}else{
				result += ` and `;
			}
		}
		result += str;
	}
	return `${rule.Price} if ${result}`;
}

export function comparePriceRules(rule1:IPriceRule, rule2:IPriceRule):boolean {
	return toUntranslatedString(rule1) == toUntranslatedString(rule2);
}

export interface IRunResult {
	priceCategory?:number;
	unitPrice?:number;
}

export function runPriceRules(rules:IPriceRule[], row:SaleRow):IRunResult{
	let result:IRunResult = {};
	for (let rule of rules.reverse()) {
		console.log("Running price rule", rule.toString());
		if (!rule.test(row)) continue;
		if (rule.Price != 0) {
			return {unitPrice: rule.Price};
		}
		if (rule.BuyAmount != 0){
			if(row.Product?.MainSupplier?.NettoPrice){
				return {unitPrice: row.Product?.MainSupplier?.NettoPrice + rule.BuyAmount};
			}
		}
		if (rule.BuyPercentage != 0){
			if(row.Product?.MainSupplier?.CatalogPrice){
				return {unitPrice: row.Product?.MainSupplier?.NettoPrice * (1 + (rule.BuyPercentage/100))};
			}
		}
		if (rule.PriceCategory > 0) {
			result.priceCategory = rule.PriceCategory;
		}
	}
	return result;
}
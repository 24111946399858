import { DossierConfigService } from "@/services/dossier-config-service";
import { CustomTranslation } from "@/utils/custom-translation";
import { CustomTranslationWithoutFallback } from "@/utils/custom-translation-without-fallback";
import { getSaleMapPropSelectGroup } from "@/utils/prop-select/sale-map-prop-group";
import { PrintContext } from "@/utils/sale-layouts/print-settings";
import { SaleConfigStock } from "./sale-config-stock";

export enum PrintSendSetting {
	Dont = 0,
	PrintAfterSend = 1,
	SendAfterPrint = 2
}

export class SaleConfig {
	ComputeStock: boolean;
	AskforBankTransfer: boolean;
	AskforBankTransferDefaultOn:boolean;
	ShowTicketRefundDisplay: boolean;
	Stocks: SaleConfigStock[];
	DefaultLayoutID: number;
	DefaultLayoutSecondHandSaleVatRegime:number;
	DefaultPrintLayoutIDs:number[] = [];
	DefaultSendLayoutIDs:number[] = [];
	DefaultComment: CustomTranslation;
	SubTotal: CustomTranslation;
	DefaultEmailTemplateID:number = 0;
	DefaultPortalEmailTemplateID:number = 0;
	Requirements:CustomTranslationWithoutFallback;
	AvailableLayoutIDs: number[] =[];
	SaleImportRowDescriptionTemplate:CustomTranslation = new CustomTranslation();
	CanBeSignedByClient:boolean = false;
	ExtraBCCAddress:string = "";
	AdditionalBCCAddress:string = "";
	PrintSendSetting:PrintSendSetting = PrintSendSetting.Dont;
	DefaultContactID:number = 0;
	AskForExtraPrintOptionsOnSave:boolean = false;
	OpenExtraFields:boolean = false;
	EnableCarpass:boolean = false;
	LangOverride:string = ""
	DontAllowDelete:boolean = false;
	UseForProductLastSold:boolean = false;
	UseForProductLastOrdered:boolean = false;
	PreventCopyOfEvWhileImporting:boolean = false;
	EnableExpirationDays:boolean = false;
	DaysUntilExpiration:number = 0;
	DontCopyBackordersToNewBookyear:boolean = false;
	AskToCreateCalendarEvent:boolean = false;

	HideSaleImportReferenceRows:boolean = false;


	constructor(data: any) {
		this.ComputeStock = data.ComputeStock || false;
		this.AskforBankTransfer = data.AskforBankTransfer;
		this.AskforBankTransferDefaultOn = data.AskforBankTransferDefaultOn || false;
		this.ShowTicketRefundDisplay = data.ShowTicketRefundDisplay || false;
		this.Stocks = (data.Stocks || []).map((c: any) => new SaleConfigStock(c));
		this.DefaultLayoutID = data.DefaultLayoutID;
		this.DefaultLayoutSecondHandSaleVatRegime = data.DefaultLayoutSecondHandSaleVatRegime;
		if (data.DefaultPrintLayoutIDs) {
			this.DefaultPrintLayoutIDs = [...data.DefaultPrintLayoutIDs];
		}
		if (data.DefaultSendLayoutIDs) {
			this.DefaultSendLayoutIDs = [...data.DefaultSendLayoutIDs];
		}
		this.DefaultComment = new CustomTranslation(data.DefaultComment);
		this.SubTotal = new CustomTranslation(data.SubTotal);
		this.DefaultEmailTemplateID = data.DefaultEmailTemplateID;
		this.DefaultPortalEmailTemplateID = data.DefaultPortalEmailTemplateID;
		this.Requirements = new CustomTranslationWithoutFallback(data.Requirements);
		this.AvailableLayoutIDs = [...(data.AvailableLayoutIDs || [])];
		this.CanBeSignedByClient = data.CanBeSignedByClient || false;
		this.SaleImportRowDescriptionTemplate = new CustomTranslation(data.SaleImportRowDescriptionTemplate);
		this.ExtraBCCAddress = data.ExtraBCCAddress || "";
		this.AdditionalBCCAddress = data.AdditionalBCCAddress || "";
		this.PrintSendSetting = data.PrintSendSetting;
		this.DefaultContactID = data.DefaultContactID || 0;
		this.AskForExtraPrintOptionsOnSave = data.AskForExtraPrintOptionsOnSave || false;
		this.OpenExtraFields = data.OpenExtraFields || false;
		this.EnableCarpass = data.EnableCarpass || false;
		this.LangOverride = data.LangOverride || "";
		this.DontAllowDelete = data.DontAllowDelete || false;
		this.UseForProductLastSold = data.UseForProductLastSold || false;
		this.UseForProductLastOrdered = data.UseForProductLastOrdered || false;
		this.PreventCopyOfEvWhileImporting = data.PreventCopyOfEvWhileImporting || false;
		this.EnableExpirationDays = data.EnableExpirationDays || false;
		this.DaysUntilExpiration = data.DaysUntilExpiration || 0;
		this.DontCopyBackordersToNewBookyear = data.DontCopyBackordersToNewBookyear || false;
		this.AskToCreateCalendarEvent = data.AskToCreateCalendarEvent || false;
		this.HideSaleImportReferenceRows = data.HideSaleImportReferenceRows || false;
	}

	// eslint-disable-next-line max-lines-per-function
	getJSON() {
		let AskforBankTransferDefaultOn = this.AskForExtraPrintOptionsOnSave;
		if (!this.AskforBankTransfer){
			AskforBankTransferDefaultOn = false;
		}
		return {
			ComputeStock: this.ComputeStock,
			AskforBankTransfer: this.AskforBankTransfer,
			AskforBankTransferDefaultOn,
			ShowTicketRefundDisplay: this.ShowTicketRefundDisplay,
			Stocks: this.Stocks.map(s => s.getJSON()),
			DefaultLayoutID: this.DefaultLayoutID,
			DefaultLayoutSecondHandSaleVatRegime: this.DefaultLayoutSecondHandSaleVatRegime,
			DefaultPrintLayoutIDs: [...this.DefaultPrintLayoutIDs],
			DefaultSendLayoutIDs: [...this.DefaultSendLayoutIDs],
			DefaultComment: this.DefaultComment.getJSON(),
			SubTotal: this.SubTotal.getJSON(),
			DefaultEmailTemplateID: this.DefaultEmailTemplateID,
			DefaultPortalEmailTemplateID: this.DefaultPortalEmailTemplateID,
			Requirements: this.Requirements.getJSON(),
			AvailableLayoutIDs: [...this.AvailableLayoutIDs],
			SaleImportRowDescriptionTemplate: this.SaleImportRowDescriptionTemplate.getJSON(),
			CanBeSignedByClient: this.CanBeSignedByClient,
			ExtraBCCAddress: this.ExtraBCCAddress,
			AdditionalBCCAddress: this.AdditionalBCCAddress,
			PrintSendSetting: this.PrintSendSetting,
			DefaultContactID: this.DefaultContactID || 0,
			AskForExtraPrintOptionsOnSave: this.AskForExtraPrintOptionsOnSave,
			OpenExtraFields: this.OpenExtraFields,
			EnableCarpass: this.EnableCarpass,
			LangOverride: this.LangOverride,
			DontAllowDelete: this.DontAllowDelete,
			UseForProductLastSold: this.UseForProductLastSold,
			UseForProductLastOrdered: this.UseForProductLastOrdered,
			PreventCopyOfEvWhileImporting: this.PreventCopyOfEvWhileImporting,
			EnableExpirationDays: this.EnableExpirationDays,
			DaysUntilExpiration: this.DaysUntilExpiration,
			DontCopyBackordersToNewBookyear: this.DontCopyBackordersToNewBookyear,
			AskToCreateCalendarEvent: this.AskToCreateCalendarEvent,
			HideSaleImportReferenceRows: this.HideSaleImportReferenceRows
		};
	}

	public isValid():boolean {
		let languageSettings = DossierConfigService.getLanguages();
		for (let lang of languageSettings.LanguageNames){
			if (!SaleConfig.validateSaleImportRowDescriptionTemplate(this.SaleImportRowDescriptionTemplate.getTranslation(lang))){
				return false;
			}
		}
		return true;
	}

	static getDefaultSaleImportRowDescriptionTemplate():string{
		return "{{ Type }} {{ NR }} van datum {{ InvoiceDate }}";
	}

	static validateSaleImportRowDescriptionTemplate(val:string):boolean{
		if (!(/^({(?!{)|[^{]*|({{\s*\.[a-zA-Z0-9][a-zA-Z0-9\.]*[a-zA-Z0-9]\s*}}))*$/.test(val))){
			return false;
		}
		let expr = /{{\s*\.[a-zA-Z0-9][a-zA-Z0-9\.]*[a-zA-Z0-9]\s*}}/g;
		let propExpr = /[a-zA-Z0-9][a-zA-Z0-9\.]*[a-zA-Z0-9]/;
		let match:RegExpExecArray | null;
		let props = getSaleMapPropSelectGroup().flattenList();
		while ((match = expr.exec(val)) != null){
			let str = match[0];
			let foundProp = propExpr.exec(str);
			if (!foundProp) {
				return false;
			}
			let prop = foundProp[0];
			let foundItem = props.find(i=>i.value == prop);
			if (!foundItem) {
				return false;
			}
		}
		return true;
	}

	public getLayoutIds(context:PrintContext):number[]{
		if (context == PrintContext.PRINT) {
			if (this.DefaultPrintLayoutIDs.length > 0) {
				return this.DefaultPrintLayoutIDs;
			}
		}else if (context == PrintContext.EMAIL_SEND) {
			if (this.DefaultSendLayoutIDs.length > 0) {
				return this.DefaultSendLayoutIDs;
			}
		}
		return [this.DefaultLayoutID];
	}
}


import { Condition } from "@/utils/condition/condition";
import AppItemEdit from "./PriceRuleEditFilterItemEdit.vue";
import { priceRuleFilterToString } from "@/utils/price-rule";
import Vue from "vue";
import { Browse } from "@/models/browse";
import { BrowseService } from "@/services";

export default Vue.extend({
	props: {
		value: {
			type: Object as ()=>Condition,
			required: true
		},
		expand: Boolean,
	},
	computed: {
		asText():string{
			return priceRuleFilterToString(this.value);
		},
		browse():Browse{
			let b = BrowseService.getBrowseWithoutViewSettings(7);
			if (!b) throw new Error("Missing browse 7?");
			return b;
		}
	},
	methods: {
		edit(){
			this.$emit("edit");
		},
		deleteItem(){
			this.$emit("delete");
		}
	},
	components: {
		AppItemEdit
	}
});

import { FavvTax} from "../taxes/favv";
import {CustomTranslation} from "@/utils/custom-translation";
import {i18n} from "@/setup/i18n-setup";
export class TaxesConfig {
	FavvConfig: FavvTax = new FavvTax();
	TextBebat: CustomTranslation = new CustomTranslation();

	public constructor(data?:any){
		if (!data) return;
		this.FavvConfig = new FavvTax(data.FavvConfig);
		this.TextBebat = data.TextBebat ? new CustomTranslation(data.TextBebat) : this.getDefaultBebatText();
	}

	public getJSON():any{
		return {
			FavvConfig: this.FavvConfig,
			TextBebat: this.TextBebat
		};
	}

	public getDefaultBebatText():CustomTranslation{
		let Translations = {"nl-be": i18n.t("common.taxes.bebat")};
		return new CustomTranslation({Translations,
			Fallback: i18n.t("common.taxes.bebat").toString()});
	}
}

import AppContactSearch from "@/components/contacts/ContactSearchWithBrowseAndCreate.vue";
import { Contact } from "@/models/base";
import { Product } from "@/models/base";
import { ProductSupplier } from "@/utils/product-supplier";
import Vue from "vue";
import { SupplierService } from "../../services/supplier-service";
import { AuthService } from "@/services/auth-service";
import AppMissingModuleModal from "@/components/common/MissingModuleModal.vue";
import { DossierConfigService } from "@/services/dossier-config-service";
import AppCurrencySelect from "@/components/CurrencySelect.vue";
import { UserConfigService } from "@/services";
import { ProductConfig } from "@/models/base/product";

export default Vue.extend({
	data() {
		return {
			openedSeller: 0,
			supplierContact: null as Contact | null,
			percentage: 0,
			focus: "",
			isLoaded: false,
			missingModule: false,
			DossierConfigService,
			autoFocus: false,
			isAllowedMultiValuta: AuthService.hasModule(10)
		};
	},
	props: {
		product: {
			type: Object as () => Product,
			required: true
		}
	},
	computed: {
		canSeeSellPrices():boolean{
			return UserConfigService.hasCurrentUserPermission(p=>p.CanReadProductPurchaseDetails);
		},
		sellerTabs(): string[] {
			return this.product.Suppliers.map((s, i) => {
				if (s.Contact?.ID){
					return s.Contact.getDisplayName();
				}
				return (this.$ct("labels.seller", {num: i + 1}).toString());
			});
		},
		supplier(): ProductSupplier | undefined {
			return this.product.Suppliers[this.openedSeller];
		},
		nettoPrice: {
			get(): number {
				return (this.supplier as ProductSupplier).NettoPrice || 0;
			},
			set(newValue: number) {
				(this.supplier as ProductSupplier).NettoPrice = newValue;
			}
		},
		catalogPrice: {
			get(): number {
				return (this.supplier as ProductSupplier).CatalogPrice || 0;
			},
			set(newValue: number) {
				(this.supplier as ProductSupplier).CatalogPrice = newValue;
			}
		},
		canHaveSupplier(): boolean {
			return true; //AuthService.wfCompany.Tier == Tier.ENTERPRISE;
		},
		decimals():number{
			return this.DossierConfigService.getDecimalSettings().Buy;
		}
	},
	watch: {
		async supplier(newValue: ProductSupplier) {
			this.supplierContact = null;
			if (newValue.ContactID != 0) {
				this.supplierContact = await SupplierService.getContact(newValue.ContactID);
			} else {
				this.supplierContact = null;
			}
			this.computePercentage();
		},
		"supplier.ContactID": {
			immediate: true,
			async handler(contactID: number) {
				this.fetchContact();
			}
		}
	},
	methods: {
		addClicked() {
			let supplier = new ProductSupplier();
			if (!this.product.Suppliers.length)
				supplier.IsMainSupplier = true;
			else if (!AuthService.hasModule(45)) {
				this.missingModule = true;
				return;
			}

			this.product.Suppliers.push(supplier);
			this.openedSeller = this.product.Suppliers.length - 1;
		},
		async contactClicked(contact: Contact) {
			if (!this.supplier) return;
			this.supplier.ContactID = contact.ID;
			this.supplierContact = contact;
			this.computePercentage();
		},
		onMainSupplierChange(value: boolean) {
			if (!this.supplier) return;
			this.product.Suppliers.forEach(sup => sup.IsMainSupplier = false);
			this.supplier.IsMainSupplier = value;
			if (value){
				this.product.MainSupplier = this.supplier;
			}else{
				this.product.MainSupplier = null;
			}
		},
		computePercentage() {
			let nettoPrice = this.nettoPrice;
			let catalogPrice = this.catalogPrice;
			this.percentage = catalogPrice ? ((nettoPrice / catalogPrice) * 100 - 100) * -1 : 0;
		},
		computeNetto() {
			this.nettoPrice = this.catalogPrice * (1 - (this.percentage / 100));
		},
		percentageChanged(value: number) {
			this.percentage = value;
			if (this.focus == "percent")
				this.computeNetto();
		},
		nettoPriceChanged(value: number) {
			this.nettoPrice = value;
			if (this.focus == "netto"){
				this.computePercentage();
			}
			this.computeStaticProfits();
		},
		catalogPriceChanged(value: number) {
			this.catalogPrice = value;
			if (this.focus == "cat") {
				this.computeNetto();
			}
		},
		async fetchContact() {
			if (!this.supplier) return;
			if (this.supplier.ContactID == null || this.supplier.ContactID == 0) return;
			if (this.supplierContact && this.supplierContact.ID == this.supplier.ContactID) return;
			this.supplierContact = await SupplierService.getContact(this.supplier.ContactID);
		},
		clearSupplier() {
			this.product.Suppliers.splice(this.openedSeller, 1);
			this.addClicked();
		},
		async removeClicked() {
			let confirm = await this.$wf.confirm(this.$ct("confirm-delete-supplier"), this.$t("common.yes"), this.$t("common.no"), this.$ct("common.warning"), "warning");
			if (!confirm) return;
			this.product.Suppliers.splice(this.openedSeller, 1);
			this.openedSeller--;
			if (this.product.Suppliers.length == 0){
				this.product.MainSupplier = null;
			}
		},
		computeStaticProfits(){
			if (!this.product.ProfitRules) return;
			if (!this.supplier) return;
			if (!this.supplier.IsMainSupplier) return;
			for (let i = 1; i <= ProductConfig.sellPriceCount; i++){
				let percentage = this.product.ProfitRules.getFixedPercentageOfCategory(i);
				if (percentage !== null) {
					let price = this.nettoPrice * ((100 + percentage) / 100);
					this.product.setPriceByCategory(i, price);
				}
				let value = this.product.ProfitRules.getFixedValueOfCategory(i);
				if (value !== null) {
					let price = this.nettoPrice + value;
					this.product.setPriceByCategory(i, price);
				}
			}
		}
	},
	async mounted() {
		if (this.supplier) {
			this.computePercentage();
			this.supplier.CurrencyRate = this.supplier.getCurrencyRate;
		}
		this.openedSeller =  this.product.Suppliers.findIndex(supplier => supplier.IsMainSupplier == true);
		if (this.openedSeller == -1){
			this.openedSeller = 0;
		}
		this.isLoaded = true;
	},
	components: {
		AppContactSearch,
		AppMissingModuleModal,
		AppCurrencySelect,
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});

import { printBoolean, printLocalDate } from "@/utils/pretty-print";
import { field, rightAlign, print, computed, hidden, decimals, nullable, translatedName } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";
import { dateToNullOrJson, newNullOrDate } from "@/utils/date-utils";
import { DossierConfigService } from "@/services";
import { i18n } from "@/setup/i18n-setup";

function getLocationQtyName(locationId:number):string{
	let name = DossierConfigService.getStockLocationConfig().getLocationAlias(locationId);
	if (name) {
		return i18n.t("models.friendlyNames.ProductStock.QtyLocation", {name}).toString();
	}
	return i18n.t(`models.friendlyNames.ProductStock.Location${locationId}Qty`).toString();
}

function getLocationBuyValueName(locationId:number):string{
	let name = DossierConfigService.getStockLocationConfig().getLocationAlias(locationId);
	if (name) {
		return i18n.t("models.friendlyNames.ProductStock.LocationBuyValue", {name}).toString();
	}
	return i18n.t(`models.friendlyNames.ProductStock.Location${locationId}BuyValue`).toString();
}

@model("ProductStock")
export default class ProductStock {
	@field("number")
	public ID:number = 0;

	@field("number")
	@hidden()
	public BuyNettoPrice:number = 0;


	@field("number")
	@decimals(2)
	@rightAlign()
	@translatedName(()=>getLocationQtyName(1))
	public Location1Qty:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	@translatedName(()=>getLocationQtyName(2))
	public Location2Qty:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	@translatedName(()=>getLocationQtyName(3))
	public Location3Qty:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	@translatedName(()=>getLocationQtyName(4))
	public Location4Qty:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	@translatedName(()=>getLocationQtyName(5))
	public Location5Qty:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	@translatedName(()=>getLocationQtyName(6))
	public Location6Qty:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	@translatedName(()=>getLocationQtyName(7))
	public Location7Qty:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	@translatedName(()=>getLocationQtyName(8))
	public Location8Qty:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	@translatedName(()=>getLocationQtyName(9))
	public Location9Qty:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public TotalQty:number = 0;


	@field("boolean")
	@print(printBoolean)
	public MinStock1Enabled:boolean = true;

	@field("number")
	@decimals(2)
	@rightAlign()
	public MinStockQty1:number = 0;

	@field("number")
	@computed()
	@decimals(2)
	public get Total():number{
		let total = 0;
		for (let i = 0; i < 9; i++) {
			total += (this as any)[`Location${i + 1}Qty`] || 0;
		}
		return total;
	}

	@field("date")
	@print(printLocalDate)
	public TimeChanged:Date = new Date();

	@field("number")
	@decimals(2)
	@translatedName(()=>getLocationBuyValueName(1))
	public Location1BuyValue:number = 0.0;
	@field("number")
	@decimals(2)
	@translatedName(()=>getLocationBuyValueName(2))
	public Location2BuyValue:number = 0.0;
	@field("number")
	@decimals(2)
	@translatedName(()=>getLocationBuyValueName(3))
	public Location3BuyValue:number = 0.0;
	@field("number")
	@decimals(2)
	@translatedName(()=>getLocationBuyValueName(4))
	public Location4BuyValue:number = 0.0;
	@field("number")
	@decimals(2)
	@translatedName(()=>getLocationBuyValueName(5))
	public Location5BuyValue:number = 0.0;
	@field("number")
	@decimals(2)
	@translatedName(()=>getLocationBuyValueName(6))
	public Location6BuyValue:number = 0.0;
	@field("number")
	@decimals(2)
	@translatedName(()=>getLocationBuyValueName(7))
	public Location7BuyValue:number = 0.0;
	@field("number")
	@decimals(2)
	@translatedName(()=>getLocationBuyValueName(8))
	public Location8BuyValue:number = 0.0;
	@field("number")
	@decimals(2)
	@translatedName(()=>getLocationBuyValueName(9))
	public Location9BuyValue:number = 0.0;

	@field("number")
	@decimals(2)
	public Location1ToOrderAmount:number = 0.0;

	@field("date")
	@nullable()
	@print(printLocalDate)
	public LastTimeOrdered:Date | null = null;

	@field("date")
	@nullable()
	@print(printLocalDate)
	public LastTimeSold:Date | null = null;

	@field("date")
	@nullable()
	@print(printLocalDate)
	public LastStockInDate:Date | null = null;

	@field("number")
	@nullable()
	@decimals(2)
	public LastStockInAmount:number | null = null;

	public constructor(data?: any) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		for (let i = 0; i < 9; i++) {
			let key = `Location${i + 1}Qty`;
			(this as any)[key] = data[key];
			key = `Location${i + 1}BuyValue`;
			(this as any)[key] = data[key];
		}
		//Todo for multistock : 9 minstock locations
		for (let i = 0; i < 1; i++) {
			let key = `MinStockQty${i + 1}`;
			(this as any)[key] = data[key];
			key = `MinStock${i + 1}Enabled`;
			(this as any)[key] = data[key];
		}
		this.TotalQty = data.TotalQty;
		this.TimeChanged = new Date(data.TimeChanged);
		this.BuyNettoPrice = data.BuyNettoPrice;
		this.Location1ToOrderAmount = data.Location1ToOrderAmount;
		this.LastTimeSold = newNullOrDate(data.LastTimeSold);
		this.LastTimeOrdered = newNullOrDate(data.LastTimeOrdered);
		this.LastStockInDate = newNullOrDate(data.LastStockInDate);
		this.LastStockInAmount = data.LastStockInAmount;
	}

	public getJSON(this: ProductStock) {
		return {
			ID: this.ID,
			TimeChanged: this.TimeChanged.toJSON(),
			...((() => {
				let res = {} as any;
				for (let i = 0; i < 9; i++) {
					let key = `Location${i + 1}Qty`;
					res[key] = (this as any)[key];
					key = `Location${i + 1}BuyValue`;
					res[key] = (this as any)[key];
				}
				for (let i = 0; i < 1; i++) {
					let key = `MinStockQty${i + 1}`;
					res[key] = (this as any)[key];
					key = `MinStock${i + 1}Enabled`;
					res[key] = (this as any)[key];
				}
				return res;
			})()),
			TotalQty: this.TotalQty,
			LastTimeOrdered: dateToNullOrJson(this.LastTimeOrdered),
			LastTimeSold: dateToNullOrJson(this.LastTimeSold),
			LastStockInDate: dateToNullOrJson(this.LastStockInDate),
			LastStockInAmount: this.LastStockInAmount
		};
	}

	public getStockAtLocation(locationId:number):number {
		if (locationId < 1) {
			throw new Error("StockLocationID out of range");
		}else if (locationId > 9) {
			throw new Error("StockLocationID out of range");
		}
		return (this as any)[`Location${locationId}Qty`];
	}
}


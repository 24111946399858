import { Browse } from "@/models/browse";
import { ViewSetting } from "@/models/user-configs/view-setting";
import { View } from "@/models/view";
import { IBrowseBaseDataSource } from "@/utils/browse/browse-data-source";
import { EventEmitter } from "@/utils/event-emitter";
import { IGetResponse } from "@/utils/get-response";
import { HumanFilter } from "@/utils/human-filter";
import { browseFieldPathToFilterFieldPath, filterFieldPathToBrowseFieldPath } from "@/utils/models/utils";
import { IQueryParams, IQueryFilters } from "@/utils/query-params";
import VueRouter from "vue-router";
import WfVueExtensions from "winfakt-vue-components/src/extensions";
import { ReportEngineSettingsModel } from "@/models/report-engine-settings";
import { StaticReport } from "@/models/static-report";
import { UserConfigService, UserConfigServiceClass } from "@/services/user-config-service";
import { ReportService } from "@/services/report-service";
import { FetchedTotal } from "../views/fetched-total";
import { MainSocketService } from "@/services/main-socket-service";
import { IDataTableColumn, ISelectItem } from "winfakt-vue-components";
import { BrowseContextMenuState } from "./browse-context-menu-state";
import { BrowseExtraButton, BrowseExtraButtonLocation } from "./browse-extra-button";
import { getModel } from "../models/model";
import { Script } from "@/models/script";
import { BrowseService, IBrowseWithExtraData } from "@/services/browse-service";
import { i18n } from "@/setup/i18n-setup";
import { LabelLayoutModel } from "@/models/label-layout/label-layout-model";
import { AuthService, LabelLayoutService } from "@/services";
import { MODULE } from "@/config/modules";

export class BrowseStateBase extends EventEmitter {
	browse: Browse;
	dataSource: IBrowseBaseDataSource<any>;
	viewSettings: ViewSetting;
	extraButtons:BrowseExtraButton[] = [];
	oldData:any[] = [];
	queryResults: IGetResponse<any> = {records: 0, data: []};
	querySettings: IQueryParams = {
		view: new View(),
		offset: 0,
		limit: 250, //viewSettings.Limit,
		orderBy: "this.ID",
		orderDirection: "ASC",
		searchQuery: "",
		filters: [],
		searchByAllColumns: false,
		autoWildCardSearch: true
	};
	labelLayouts:LabelLayoutModel[] = [];
	UserConfigService:UserConfigServiceClass = UserConfigService;

	private _fetching = false;
	get fetching():boolean {
		return this._fetching;
	}
	set fetching(value:boolean) {
		this._fetching = value;
		if (!value) {
			this.emit("done-fetching");
		}
	}

	showSearch = false;
	useURLQueryParams = false;
	isAtTheEnd = false;
	customReportsModalOpen = false;
	protected _selectedItems: any[] = [];
	totals:FetchedTotal[] = [];
	staticView:View | null = null;
	baseButtonBarItems = [] as ISelectItem<string>[];
	contextMenu:BrowseContextMenuState = new BrowseContextMenuState(this);
	filterModalActiveTab:number = 0;
	overrideStaticViewSelect:boolean = false;
	extraScriptButtons:Script[] = [];
	openedExtraScriptButtonArgsModal:Script | null = null;
	browseToFileModalOpen:boolean = false;
	printLabelsModalOpen:boolean = false;

	public getButtonBarItems():ISelectItem<string | Function>[]{
		let extraButtons = this.extraButtons.filter(f=>f.Location == BrowseExtraButtonLocation.MENU).map(b=>b.toSelectItem(this));
		let items:ISelectItem<string | Function>[] = [...this.baseButtonBarItems];
		if (this.labelLayouts.length > 0 && this.selectedItems.length > 0){
			items.push({
				text: i18n.t("components.browse.print-labels"),
				value: ()=>this.openPrintLabelsModal(),
				icon: "tag",
			} as ISelectItem<Function>);
		}

		if(this.querySettings.view.ID != 0){
			items.push({
				text: i18n.t("components.browse.settings.edit"),
				value: ()=>this.editViewSettings(),
				icon: "cog",
				devider: false
			});
		}

		if (extraButtons.length > 0 && items.length > 0){
			items[items.length-1].devider = true;
		}
		let extraScriptButtons = this.extraScriptButtons.map(s=>{
			return {
				text: s.Name,
				value: ()=>this.runBrowseScript(s),
				icon: s.Icon,
			} as ISelectItem<Function>;
		});

		return [...items, ...extraButtons, ...extraScriptButtons];
	}

	public get selectedItems():any[] {
		return this._selectedItems;
	}

	public editViewSettings(){
		this.router.push(`/settings/browse/${this.querySettings.view.BrowseID}/view/${this.querySettings.view.ID}`);
	}

	public openPrintLabelsModal(){
		this.printLabelsModalOpen = true;
	}

	public async runBrowseScript(script:Script){
		if (script.ExtraArgs.length > 0) {
			this.openedExtraScriptButtonArgsModal = script;
			return;
		}
		await BrowseService.runBrowseScript(this.browse.ID, script.ID);
		this.wf.notify(i18n.t("common.script-started", {name: script.Name}).toString(), "success", 3000, "check");
	}

	public async runBrowseScriptWithArgs(args:string[]){
		if (!this.openedExtraScriptButtonArgsModal) return;
		await BrowseService.runBrowseScript(this.browse.ID, this.openedExtraScriptButtonArgsModal.ID, args);
		this.wf.notify(i18n.t("common.script-started", {name: this.openedExtraScriptButtonArgsModal.Name}).toString(), "success", 3000, "check");
		this.openedExtraScriptButtonArgsModal = null;
	}

	get wf():WfVueExtensions{
		return this.vue.$wf;
	}

	get router():VueRouter {
		return this.vue.$router;
	}

	savedReportSettings: ReportEngineSettingsModel[] = [];
	staticReportSettings: StaticReport[] = [];
	// eslint-disable-next-line
	constructor(protected vue:Vue, dataSource: IBrowseBaseDataSource<any>, browseData: IBrowseWithExtraData, useURLQueryParams: boolean, selectedItems:any[]) {
		super();
		this._selectedItems = selectedItems;
		this.dataSource = dataSource;
		this.browse = browseData.browse;
		this.viewSettings = browseData.viewSetting;
		this.extraButtons = browseData.extraButtons;
		this.extraScriptButtons = browseData.scriptButtons;
		this.useURLQueryParams = useURLQueryParams;
		if (this.browse.Views.length == 0){
			throw new Error("no-views");
		}
		let currentView = this.browse.Views[0];
		if (!this.dataSource.ignorePreferedView) {
			let foundView = this.browse.Views.find(v => v.ID == this.viewSettings.ViewID);
			if (foundView) {
				currentView = foundView;
			}
		}
		this.querySettings.view = currentView;
		this.querySettings.orderBy = filterFieldPathToBrowseFieldPath(this.viewSettings.OrderColumn);
		this.querySettings.orderDirection = this.viewSettings.OrderAscending ? "ASC" : "DESC";

		if (this.viewSettings.SearchQuery) {
			this.querySettings.searchQuery = this.viewSettings.SearchQuery;
		} else if (this.viewSettings.Filters) {
			this.querySettings.filters = this.viewSettings.Filters.map(f => new HumanFilter(f));
		}
		this.querySettings.autoWildCardSearch = this.viewSettings.AutoWildCardSearch || false;
		this.querySettings.searchByAllColumns = this.viewSettings.SearchByAllColumns || false;
		if (this.useURLQueryParams) {
			if (this.router.currentRoute.query.filters) {
				let filters:IQueryFilters = JSON.parse(this.router.currentRoute.query.filters.toString()) || {};
				filters.filters = (filters.filters || []).map(f=>new HumanFilter(f));
				this.setFilters(filters);
			}
		}
		this.fetchReports();
		this.fetchLabelLayouts();
	}

	async fetchLabelLayouts(){
		if (!AuthService.hasModule(MODULE.ETIKETTEN)) return;
		this.labelLayouts = await LabelLayoutService.getByBrowseId(this.browse.ID);
	}

	async setView(view:View){
		this.querySettings.view = view;
	}

	async fetchTotals(){
		if (this.querySettings.view.Totals.length > 0) {
			this.totals = await this.dataSource.getTotals(this.querySettings.view);
		}else{
			this.totals = [];
		}
	}

	async fetchReports(): Promise<void> {
		if (!UserConfigService.hasCurrentUserPermission(p => p.CanMakeReports)) return;
		let result = await ReportService.getReportEngineSettingsByBrowseId(this.browse.ID);
		this.savedReportSettings = result.savedReports;
		this.staticReportSettings = result.staticReports;
	}

	protected validateQueryOrderBy() {
		let col = this.querySettings.view.Columns.find(c => c.Property == this.querySettings.orderBy);
		if (!col) {
			this.querySettings.orderBy = this.querySettings.view.Columns[0].Property;
		}
	}

	async fetch() {
		if (this.fetching) return;
		this.fetching = true;
		this.isAtTheEnd = false;
		let isOrderByValid = this.querySettings.view.Columns.find(c => c.Property == this.querySettings.orderBy) != null;
		if (!isOrderByValid) {
			this.querySettings.orderBy = this.querySettings.view.Columns[0].Property;
			this.querySettings.orderDirection = "ASC";
		}
		this.querySettings.offset = 0;
		this.validateQueryOrderBy();
		if (this.dataSource.ignorePreferedView){
			this.querySettings.dontSaveViewSettings = true;
		}
		try {
			MainSocketService.offAll(this);
			this.queryResults = await this.getElements();
			if (this.queryResults.data.length == 0) {
				this.isAtTheEnd = true;
			}
			this.queryResults.data.forEach((item, i)=>this.onItemFetched(item,i));
			try{
				await this.fetchTotals();
			}catch(err){

			}
		} catch (err) {
			this.queryResults = {data: [] as any[], records: 0};
		}finally{
			this.fetching = false;
		}
	}

	public async doneFetching():Promise<void>{
		if (!this.fetching) return;
		await new Promise((resolve, reject)=>{
			this.once("done-fetching", resolve);
		});
	}


	private async getElements():Promise<IGetResponse<any>> {
		if (this.staticView && this.dataSource.staticViewGetElements) {
			return this.dataSource.staticViewGetElements(this.staticView, this.getFetchQuerySettings());
		}
		return this.dataSource.getElements(this.getFetchQuerySettings());
	}

	async infiniteScrollFetchMore() {
		if (this.queryResults.data.length == this.queryResults.records) return;
		if (this.fetching) return;
		if (this.isAtTheEnd) return;
		this.fetching = true;

		try {
			this.querySettings.offset = this.queryResults.data.length;
			let queryResults = await this.getElements();
			if (this.queryResults.data.length == 0) {
				this.isAtTheEnd = true;
			}
			let rows = queryResults.data;
			rows = rows.filter((r, i) => this.queryResults.data.findIndex(row => row.ID == r.ID) == -1);
			rows.forEach((item, i)=>this.onItemFetched(item,this.queryResults.data.length + i));
			this.queryResults.data.push(...rows);
		} catch (err) {
			throw err;
		}
		this.fetching = false;
	}

	setFilters(filters: IQueryFilters) {
		this.querySettings.searchQuery = filters.searchQuery;
		this.querySettings.autoWildCardSearch = filters.autoWildCardSearch;
		this.querySettings.searchByAllColumns = filters.searchByAllColumns;
		this.querySettings.filters = filters.filters;

		if (!this.useURLQueryParams) return;
		if ((filters.filters || []).length == 0 || filters.searchQuery == "") {
			let query = {...this.router.currentRoute.query};
			delete query.filters;
			this.router.push({query});
		} else {
			let query = {...this.router.currentRoute.query, filters: JSON.stringify(this.getFilters())};
			this.router.push({query});
		}
	}

	getFilters(): IQueryFilters {
		return {
			filters: (this.querySettings.filters || []).map(f => new HumanFilter(f)),
			searchByAllColumns: this.querySettings.searchByAllColumns,
			searchQuery: this.querySettings.searchQuery,
			autoWildCardSearch: this.querySettings.autoWildCardSearch
		};
	}

	hasTotals():boolean {
		return this.totals.filter(t=>t.Hidden == false).length > 0;
	}

	public destroy(){
		MainSocketService.offAll(this);
	}

	private getFetchSearchQuery():string {
		if ((this.querySettings.searchQuery || "").trim().length == 0){
			return "";
		}
		if (this.querySettings.autoWildCardSearch && (this.querySettings.searchQuery || "").charAt(0) != "%" ) {
			return "%" + this.querySettings.searchQuery;
		}
		return this.querySettings.searchQuery || "";
	}

	private getFetchQuerySettings():IQueryParams {
		return {...this.querySettings, searchQuery: this.getFetchSearchQuery()};
	}

	protected onItemFetched(item:any, index:number){
		if (!this.dataSource.getOnElementUpdateSocketEvent || !this.dataSource.onSocketElementUpdated) return;
		MainSocketService.on(this.dataSource.getOnElementUpdateSocketEvent(item), (data:any)=>{
			if (!this.dataSource.onSocketElementUpdated) return;
			this.queryResults.data[index] = this.dataSource.onSocketElementUpdated(data);
			this.queryResults.data = [...this.queryResults.data];
		}, this);
	}

	public checkIfSelectedItemsAreStillInBrowse(){
		for (let i = this.selectedItems.length-1;i>=0;i--) {
			let foundIndex = this.queryResults.data.findIndex(d=>d.ID == this.selectedItems[i].ID);
			if (!foundIndex){
				this.selectedItems.splice(i,1);
			}
		}
	}

	showViewSelect():boolean {
		return (this.staticView == null || this.dataSource.staticViewGetElements == null) || this.overrideStaticViewSelect;
	}

	setStaticView(view:View) {
		this.viewSettings.ViewID = 0;
		this.staticView = view;
		this.querySettings.view = view;
	}

	buttonBarItemTrigger(value: string) {
		this.emit("button-bar-trigger", value);
	}

	async onHeaderSearchClicked(column:IDataTableColumn) {
		let model = getModel(this.dataSource.modelName);
		let field = model.getField(column.property);
		if (!field) return;
		if (field.field.isComputed || field.field.notFilterable || field.field.type == "array" || field.field.type == "json") return;
		let filter = new HumanFilter();
		filter.Field = browseFieldPathToFilterFieldPath(column.property);
		filter.Operator = "=";
		filter.Values = [""];
		this.querySettings.filters.push(filter);
		this.showSearch = true;
		this.filterModalActiveTab = 1;
		// Fixes the cancel button in the filter modal
		setTimeout(()=>{
			this.querySettings.filters.splice(this.querySettings.filters.length-1);
		}, 100);
	}

	getContextMenuItems():ISelectItem<string | Function>[]{
		return [...this.contextMenu.items];
	}

	useCheckboxes():boolean {
		return this.UserConfigService.getGeneralSettings().UseCheckboxesInViews;
	}

	async refreshSpecificRecords(ids:number[]):Promise<void>{
		if (!ids) return;
		let queryParams:IQueryParams = {...this.querySettings};
		queryParams.filters = [new HumanFilter({Field: "this.ID", Operator: "in", Values: ids.map(id=>`${id}`)})];
		queryParams.dontSaveViewSettings = true;
		let result = await this.dataSource.getElements(queryParams);
		let checkedItems = 0;
		for (let item of result.data){
			for (let i = 0; i < this.queryResults.data.length;i++){
				if (item.ID == this.queryResults.data[i].ID) {
					this.vue.$set(this.queryResults.data, i, item);
					break;
				}
			}
			for (let i = 0; i < this.selectedItems.length; i++){
				checkedItems++;
				if (item.ID == this.selectedItems[i].ID) {
					this.vue.$set(this.selectedItems, i, item);
					break;
				}
			}
			if (checkedItems > 1e6){ // Keep the app performan
				await new Promise(resolve=>requestAnimationFrame(resolve));
				checkedItems = 0;
			}
		}
	}
}
export class PreviewData {
	SaleID:number = 0;

	public constructor(data?:any){
		if (!data) return;
		this.SaleID = data.SaleID;
	}

	public getJSON(){
		return {
			SaleID: this.SaleID
		};
	}

	isFilledIn():boolean{
		this.SaleID != 0;
		return true;
	}
}
import { i18n } from "@/setup/i18n-setup";
import { CustomTranslation } from "@/utils/custom-translation";
import { ILayoutEntity } from "../layout-editor";
import { Field } from "./field";
import { FreeBlock } from "./free-block";
import { TextStyledProperty } from "./text-styled-property";
import { TranslatedStyledProperty } from "./translated-styled-property";
import { Condition } from "../condition/condition";

export class DocumentLayoutHeading implements ILayoutEntity {
	public Heading1 = new TranslatedStyledProperty();
	public Heading2 = new TranslatedStyledProperty();
	public Heading3 = new TranslatedStyledProperty();
	public Heading4 = new TranslatedStyledProperty();
	public Heading5 = new TranslatedStyledProperty();
	public Heading6 = new TranslatedStyledProperty();
	public Heading7 = new TranslatedStyledProperty();
	public Heading8 = new TranslatedStyledProperty();
	public Heading9 = new TranslatedStyledProperty();
	public X = 50;
	public Y = 20;
	public Width = 50;
	public Height = 50;
	public Show:boolean = true;
	public Conditions:Condition[] = [];

	constructor(data?: any) {
		if (!data) {
			return;
		}
		for (let i = 1; i <= 9; i++) {
			(this as any)["Heading" + i] = new TranslatedStyledProperty(data["Heading" + i]);
		}
		this.X = data.X;
		this.Y = data.Y;
		this.Width = data.Width;
		this.Height = data.Height;
		this.Show = data.Show;
		this.Conditions = ((data.Conditions || []) as any[]).map(c=>new Condition(c));
	}

	public getJSON() {
		let data = {
			X: this.X,
			Y: this.Y,
			Width: this.Width,
			Height: this.Height,
			Show: this.Show,
			Conditions: this.Conditions.map(c=>c.getJSON())
		} as any;
		for (let i = 1; i <= 9; i++) {
			data["Heading" + i] = ((this as any)["Heading" + i] as CustomTranslation).getJSON();
		}
		return data;
	}

	private static lineToField(value:TranslatedStyledProperty):Field{
		let result = new Field();
		result.Text = new CustomTranslation(value.Value.getJSON());
		result.Style = new TextStyledProperty(value.Style);
		return result;
	}

	toFreeBlock():FreeBlock {
		let result = new FreeBlock();
		result.Lines.push(DocumentLayoutHeading.lineToField(this.Heading1));
		result.Lines.push(DocumentLayoutHeading.lineToField(this.Heading2));
		result.Lines.push(DocumentLayoutHeading.lineToField(this.Heading3));
		result.Lines.push(DocumentLayoutHeading.lineToField(this.Heading4));
		result.Lines.push(DocumentLayoutHeading.lineToField(this.Heading5));
		result.Lines.push(DocumentLayoutHeading.lineToField(this.Heading6));
		result.Lines.push(DocumentLayoutHeading.lineToField(this.Heading7));
		result.Lines.push(DocumentLayoutHeading.lineToField(this.Heading8));
		result.Lines.push(DocumentLayoutHeading.lineToField(this.Heading9));
		result.X = this.X;
		result.Y = this.Y;
		result.Width = this.Width;
		result.Height = this.Height;
		result.Placement = "Heading";
		result.Name = i18n.t("components.sale-layout-editor.heading").toString();
		this.Show = false;
		return result;
	}
}

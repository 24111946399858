export class DecimalConfig {
	Qty: number = 0;
	Qty2: number = 1;
	Unit: number = 0;
	Buy: number = 0;
	Bruto: number = 0;
	Sale: number = 0;
	UseCommaSeperator: boolean = false;


	constructor(data?: any) {
		if (!data) return;
		this.Qty = data.Qty;
		this.Qty2 = data.Qty2 !== undefined ? data.Qty2 : 1;
		this.Unit = data.Unit;
		this.Buy = data.Buy;
		this.Bruto = data.Bruto;
		this.Sale = data.Sale;
		this.UseCommaSeperator = data.UseCommaSeperator;
	}

	getJSON(): any {
		return {
			Qty: this.Qty,
			Qty2: this.Qty2,
			Unit: this.Unit,
			Buy: this.Buy,
			Bruto: this.Bruto,
			Sale: this.Sale,
			UseCommaSeperator: this.UseCommaSeperator
		};
	}

	isValid():boolean {
		let max = Math.max(
			this.Qty,
			this.Qty2,
			this.Unit,
			this.Buy,
			this.Bruto,
			this.Sale
		);

		if (max > 8) {
			return false;
		}
		if (max < 0) {
			return false;
		}

		return true;
	}
}
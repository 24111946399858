import { ServerConfig } from "@/config/config";
import { Contact } from "@/models/base";
import { ContactAddress } from "@/models/base";
import { ReminderConfig } from "@/models/dossier-configs/reminder-config";
import { Reminder } from "@/models/reminder";
import { ReminderEmailTemplate } from "@/models/reminder-email-template";
import { ReminderLayoutConf } from "@/models/reminder-layout";
import { ReminderTemplate } from "@/models/reminder-template";
import { Sale } from "@/models/base";
import { Axios } from "@/utils/axios";
import { DossierConfigService } from "@/services/dossier-config-service";
import { SaleService } from "@/services/sale-service";
import { DocumentLayoutInfo } from "@/utils/documents/layout-info";
import { HumanFilter } from "@/utils/human-filter";
import { i18n } from "@/setup/i18n-setup";
import { printLocalDate } from "@/utils/pretty-print";
import { formdataFromIMail, IMail } from "@/utils/mail";
import { ReminderLayout } from "@/utils/reminder-layouts/reminder-layout";
import { MailSettings } from "@/models/mail-settings";
import { DocumentLayoutClientHeading } from "@/utils/documents/layout-client-heading";
import { DocumentLayoutHeading } from "@/utils/documents/layout-heading";
import { PrintContext } from "@/utils/sale-layouts/print-settings";

function getDefaultReminderFilters(contact: number, value: string, config: ReminderConfig): HumanFilter[] {
	let types = [1];
	if(config && config.AddCreditNotes){
		types = [1,2];
	}
	return [
		new HumanFilter({
			Field: "this.ContactID",
			Operator: "=",
			Values: [contact + ""],
		}),
		new HumanFilter({
			Field: "this.TypeID",
			Operator: "in",
			Values: types.map(v => `${v}`)
		}),
		new HumanFilter({
			Field: "this.TotalOpen",
			Operator: "!=",
			Values: [value],
			IsOr: false,
		}),
		new HumanFilter({
			Field: "this.Reminder",
			Operator: "!=",
			Values: ["9"],
			IsOr: false,
		})
	];
}

export class ReminderServiceClass{
	private config:ReminderConfig | null = null;

	private readonly url = `${ServerConfig.host}/reminder`;
	private readonly layoutUrl = `${ServerConfig.host}/reminder-layout`;
	private readonly templateUrl = `${ServerConfig.host}/reminder-template`;
	private readonly emailTemplateUrl = `${ServerConfig.host}/reminder-email-template`;
	public readonly MAX_SALES_PER_LAYOUT = 50;

	async getSales(ContactID: number): Promise<Sale[]> {
		this.config = DossierConfigService.getReminderConfig();
		let filters = getDefaultReminderFilters(ContactID, "0",this.config);
		filters.push(...this.getCustomFilters());
		let result = await Axios.get(`${SaleService.url}`, {
			params: {
				order: "ExpirationDate",
				orderDirection: "ASC",
				limit: 500,
				filters
			}
		});
		let sales = result.data.data.map((c: any) => new Sale(c));
		return sales;
	}

	getCustomFilters():HumanFilter[] {
		if(!this.config)
			return [];
		let result:HumanFilter[] = [];

		if(!this.config.ShowAllInOverview) {
			let a = new HumanFilter({
				Field: "this.ExpirationDate",
				Operator: "<=",
				Values: [new Date().toJSON()],
				IsOr: false,
			});
			result.push(a);
		}
		return result;
	}

	async getReminder(id:number):Promise<Reminder>{
		let result = await Axios.get(`${this.url}/${id}`);
		return new Reminder(result.data);
	}

	async getReminderLayouts(): Promise<DocumentLayoutInfo[]> {
		let result = await Axios.get(`${this.layoutUrl}`);
		let layouts = result.data.map((l: any) => new DocumentLayoutInfo(l));
		return layouts;
	}

	async getReminderLayout(id: number): Promise<ReminderLayoutConf> {
		let result = await Axios.get(`${this.layoutUrl}/${id}`);
		return new ReminderLayoutConf(result.data);
	}

	async deleteReminderLayouts(layouts: DocumentLayoutInfo[]): Promise<void> {
		await Axios.delete(`${this.layoutUrl}`, {
			data: layouts.map(l => l.ID)
		});
	}

	async postReminderLayout(layouts: ReminderLayoutConf[]): Promise<ReminderLayoutConf[]> {
		let result = await Axios.post(`${this.layoutUrl}`, layouts.map(l => l.getJSON()));
		return result.data.map((l: any) => new ReminderLayoutConf(l));
	}

	async putReminderLayout(layouts: ReminderLayoutConf[]): Promise<ReminderLayoutConf[]> {
		let result = await Axios.put(`${this.layoutUrl}`, layouts.map(l => l.getJSON()));
		return result.data.map((l: any) => new ReminderLayoutConf(l));
	}

	async getReminderTemplates(): Promise<ReminderTemplate[]> {
		let result = await Axios.get(`${this.templateUrl}`, {
			params: {}
		});
		return result.data.data.map((i: any) => new ReminderTemplate(i));
	}

	async putReminderTemplates(templates: ReminderTemplate[]): Promise<ReminderTemplate[]> {
		let result = await Axios.put(`${this.templateUrl}`, templates.map(t => t.getJSON()));
		return result.data.map((r: any) => new ReminderTemplate(r));
	}

	async getEmailTemplates():Promise<ReminderEmailTemplate[]> {
		let result = await Axios.get(`${this.emailTemplateUrl}`);
		return result.data.data.map((t:any) => new ReminderEmailTemplate(t));
	}

	async restoreReminderTemplates():Promise<ReminderTemplate[]>  {
		await Axios.get(`${this.templateUrl}/restore-all`);
		let result = await Axios.get(`${this.templateUrl}`);
		return result.data.data.map((t:any) => new ReminderTemplate(t));
	}

	async getEmailTemplate(templateID:number):Promise<ReminderEmailTemplate> {
		let result = await Axios.get(`${this.emailTemplateUrl}/${templateID}`);
		return new ReminderEmailTemplate(result.data);
	}

	async postEmailTemplates(templates:ReminderEmailTemplate[]):Promise<ReminderEmailTemplate[]> {
		let result = await Axios.post(`${this.emailTemplateUrl}`, templates.map(t => t.getJSON()));
		return result.data.map((r:any) => new ReminderEmailTemplate(r));
	}

	async putEmailTemplates(templates:ReminderEmailTemplate[]):Promise<ReminderEmailTemplate[]> {
		let result = await Axios.put(`${this.emailTemplateUrl}`, templates.map(t => t.getJSON()));
		return result.data.map((r:any) => new ReminderEmailTemplate(r));
	}

	async deleteEmailTemplates(templates:ReminderEmailTemplate[]):Promise<void> {
		await Axios.delete(`${this.emailTemplateUrl}`, { data: templates.map(t => t.ID)});
	}

	async computeEmailTemplate(template:ReminderEmailTemplate, contact:Contact, reminders:Reminder[]):Promise<string> {
		let result = await Axios.post(`${this.emailTemplateUrl}/compute`, {
			TemplateID: template.ID,
			ContactID: contact.ID,
			Sales: reminders.map(r=>{
				return {
					SaleID: r.SaleID,
					Cost: r.Cost,
					Interest: r.Interest
				};
			})
		});
		return result.data;
	}

	async downloadReminder(reminders:Reminder[], address:ContactAddress, layoutId:number, reminderId:number, content:string, includeSales:boolean,
		socketEventId:string, createContext:PrintContext):Promise<Blob> {
		let result = await Axios.post(`${this.layoutUrl}/download-layout`, {
			AddressID: address.ID,
			LayoutID: layoutId,
			Sales: reminders.map(r => {
				return {
					SaleID: r.SaleID,
					Interest: r.Interest,
					Cost: r.Cost,
				};
			}),
			TemplateID: reminderId,
			Content: content,
			SocketEventID: socketEventId,
			CreateContext: createContext
		}, {responseType: "blob", params: {"include-sales": includeSales}});

		let blob = new Blob([result.data], { type: "application/pdf" });
		return blob;
	}

	async downloadAndSaveReminder(reminders:Reminder[], address:ContactAddress, layoutId:number, reminderId:number, content:string, includeSales:boolean,
		socketEventId:string):Promise<void> {
		let blob = await this.downloadReminder(reminders, address, layoutId, reminderId, content, includeSales, socketEventId, PrintContext.DOWNLOAD);
		let url = window.URL.createObjectURL(blob);
		let a = document.createElement("a");
		a.href = url;
		a.download = this.getPdfName();
		a.click();
		window.URL.revokeObjectURL(url);
	}

	getPdfName(date:Date = new Date()):string{
		let dateStr = printLocalDate(date).replace(/\//g, "-");
		return `${i18n.t("common.Reminder")}-${dateStr}.pdf`;
	}

	/*// eslint-disable-next-line max-lines-per-function
	async printReminder(reminders:Reminder[], address:ContactAddress, layoutId:number, reminderId:number, content:string, includeSales:boolean,
		socketEventId: string): Promise<any> {
		let data = await this.downloadReminder(reminders, address, layoutId, reminderId, content, includeSales, socketEventId);
		let fileName = this.getPdfName();
		if (BrowserInfo.isElectron()) {
			let defaultPrinterId = ElectronFunctions.getDefaultPrinterIdForReminderLayout(layoutId);
			let copyCount = ElectronFunctions.getCopyCountForReminderLayout(layoutId) || 1;
			if (defaultPrinterId){
				await ElectronFunctions.printPdf(data, defaultPrinterId, copyCount);
				return;
			}
			let fileTemp = data as any;
			fileTemp.lastModifiedDate = new Date();
			fileTemp.name = fileName;
			let file = fileTemp as File;
			var url = await ElectronFunctions.saveTempFile(file);
			url = ElectronFunctions.getPdfViewerUrl(fileName);
		} else {

			var url = URL.createObjectURL(data);
		}
		if (BrowserInfo.isFirefox()) {
			return window.open(url);
		}

		let iframe = window.document.createElement("iframe") as HTMLIFrameElement;
		iframe.width = "100%";
		iframe.height = "100%";
		if (!BrowserInfo.isChrome() && !BrowserInfo.isSafari()) {
			iframe.setAttribute("sandbox", "allow-popups allow-scripts allow-same-origin allow-modals" +
				"allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation");
		}
		let frameId = uuid();
		iframe.id = frameId;
		iframe.src = url;
		iframe.style.border = "none";
		iframe.style.position = "absolute";
		iframe.style.top = "100%";
		window.document.body.appendChild(iframe);
		setTimeout(() => {
			if (!iframe.contentDocument) return;
			iframe.focus();
			setTimeout(() => {
				if (!iframe.contentWindow) return;
				iframe.contentWindow.print();
				iframe.addEventListener("blur", async() => {
					iframe.remove();
					if (BrowserInfo.isElectron()) {
						await ElectronFunctions.deleteTempFile(fileName);
					} else {
						URL.revokeObjectURL(url);
					}
				});
			}, 1000);
		}, 1000);
	}*/

	async postReminders(reminders:Reminder[]):Promise<Reminder[]> {
		let result = await Axios.post(`${this.url}`, reminders.map(r => r.getJSON()));
		return result.data.map((r:any) => new Reminder(r));
	}

	async listAutoReminders(){
		let result = await Axios.get(`${this.url}/auto-reminder-list`);
		return (result.data || []).map((r:any) => new Sale(r));
		//auto-reminder-send
	}

	async sendAutoReminderList(reminders:Reminder[]):Promise<void>{
		await Axios.post(`${this.url}/auto-reminder-send/${JSON.stringify(reminders.map(r=>r.SaleID))}`, {});
	}

	async sendAllAutoReminders(){
		await Axios.post(`${this.url}/auto-reminder-send-all`);
		//auto-reminder-send
	}

	async sendReminders(reminders:Reminder[], mail:IMail, settings:MailSettings, addReadConfirmation:boolean):Promise<void>{
		let data = formdataFromIMail(mail);
		data.set("addReadConfirmation", addReadConfirmation ? "true" : "false");
		await Axios.post(`${this.url}/send/${settings.ID}/${JSON.stringify(reminders.map(r=>r.ID))}`, data);
	}

	async getReminderLayoutPreview(layout:ReminderLayout, lang:string = "nl-be"){
		let result = await Axios.post(`${this.layoutUrl}/download-preview`, {
			Lang: lang,
			Layout: layout.getJSON()
		}, {responseType: "blob"});

		return new Blob([result.data], {type: "application/pdf"});
	}

	async downloadReminderLayoutPreview(layout:ReminderLayout, lang:string = "nl-be"){
		let blob = await this.getReminderLayoutPreview(layout, lang);
		let url = window.URL.createObjectURL(blob);
		let a = document.createElement("a");
		a.href = url;
		a.download = "preview.pdf";
		a.click();
		window.URL.revokeObjectURL(url);
	}

	public async applyHeadingToLayouts(layouts:DocumentLayoutInfo[], heading:DocumentLayoutHeading){
		await Axios.post(`${this.layoutUrl}/apply-heading`, {
			LayoutIDs: layouts.map(l=>l.ID),
			Heading: heading.getJSON(),
		});
	}

	public async applyClientHeadingToLayouts(layouts:DocumentLayoutInfo[], heading:DocumentLayoutClientHeading){
		await Axios.post(`${this.layoutUrl}/apply-client-heading`, {
			LayoutIDs: layouts.map(l=>l.ID),
			Heading: heading.getJSON(),
		});
	}
}

export const ReminderService = new ReminderServiceClass();
import { SaleEditRowExtraInfoField } from "@/utils/sale-edit/sale-edit-row-extra-info-field";
import { SaleConfig } from "./sale-config";
import { SaleType } from "@/models/base";
import { boolOrNumberToNumber } from "@/utils/numbers";

export enum OpenClientInfoModalAfterSelectOptions {
	OFF = 0,
	INFO = 1,
	EXTRA_FIELDS = 2
}

export class SaleSettings {
	DefaultNewSaleVatID:number = 4;
	DefaultNewProductVatID:number = 4;
	DefaultQuotationValidDays:number = 30;
	NewSaleAutoFocusField:string = "contact";
	ShowUnits:boolean = false;
	ShowLeeggoed:boolean = false;
	DescriptionEditor:boolean = false;
	SaleDisplayOrder:number[] = [];
	PurchaseDisplayOrder:number[] = [];
	ProspectDisplayOrder:number[] = [];
	ShowProfitBar: boolean = false;
	ShowStockInfo: boolean = false;
	CompactSaleOverview: boolean = false;
	HideHiddenRows: boolean = false;
	VatRegimeDisplayOrder:number [] = [];
	PurchaseVatRegimeDisplayOrder:number[] = [];
	Configs:{[key:string]:SaleConfig} = {};
	SaleLayoutDisplayOrder:number[] = [];
	PurchaseLayoutDisplayOrder:number[] = [];
	ProspectLayoutDisplayOrder:number[] = [];
	SaleEditDisabledFieldsDisable:boolean = true;
	SaleEditDisabledFields:string[] = [];
	OpenClientInfoModalAfterSelect:OpenClientInfoModalAfterSelectOptions = OpenClientInfoModalAfterSelectOptions.OFF;
	SelectNextLineOnProductSubmit:boolean = false;
	SkipSubmitOnDescriptionChange:boolean = false;
	AllowEditOfSignedDocuments: boolean = false;
	ViesCheckExtraField:string = "";
	LastSalesRecoveryID:number = 0;
	DefaultQtyOnEmptyLine:number = 1;
	ShowSaleRecovery:boolean = true;
	WaitForEnterOnProductSearch:boolean = false;
	ShowSeperateStockInfo:boolean = true;
	AWSDocumentManagerAutomaticallyOpenPrintSettingsOnAdd:boolean = false;
	AWSDocumentManagerAutomaticallyShowWithPrint:boolean = false;
	AWSDocumentManagerAutomaticallyShowWithDownload:boolean = false;
	AWSDocumentManagerAutomaticallyShowWithSend:boolean = false;
	SaleEditRowExtraInfoFields:SaleEditRowExtraInfoField[] = [];
	RedirectToDashboardAfterSave:boolean = false;
	ClearSelectionAfterSave:boolean = false;
	FullSaleRowView:boolean = false;
	PurchaseAskForBankTransfer:boolean = false;
	TemplateSettingsDays:number = 365;
	TemplateSettingsUpdatePrices:boolean = true;
	TemplateSettingsSaleTypes:string[] = [];



	// eslint-disable-next-line max-lines-per-function
	constructor(data?: any) {
		if (!data) return;
		this.DefaultNewProductVatID = data.DefaultNewProductVatID || 0;
		this.DefaultNewSaleVatID = data.DefaultNewSaleVatID || 0;
		this.DefaultQuotationValidDays = data.DefaultQuotationValidDays || 8;
		this.NewSaleAutoFocusField = data.NewSaleAutoFocusField || "contact";
		this.ShowUnits = data.ShowUnits || false;
		this.ShowLeeggoed = data.ShowLeeggoed || false;
		this.DescriptionEditor = data.DescriptionEditor || false;
		this.SaleDisplayOrder = data.SaleDisplayOrder || [];
		this.PurchaseDisplayOrder = data.PurchaseDisplayOrder || [];
		this.ProspectDisplayOrder = data.ProspectDisplayOrder || [];
		this.ShowProfitBar = data.ShowProfitBar || false;
		this.ShowStockInfo = data.ShowStockInfo || false;
		this.CompactSaleOverview = data.CompactSaleOverview || false;
		this.HideHiddenRows = data.HideHiddenRows || false;
		this.VatRegimeDisplayOrder = [...(data.VatRegimeDisplayOrder || [])];
		this.PurchaseVatRegimeDisplayOrder = [...(data.PurchaseVatRegimeDisplayOrder || [])];
		this.SaleLayoutDisplayOrder = [...(data.SaleLayoutDisplayOrder || [])];
		this.PurchaseLayoutDisplayOrder = [...(data.PurchaseLayoutDisplayOrder || [])];
		this.ProspectLayoutDisplayOrder = [...(data.ProspectLayoutDisplayOrder || [])];
		this.SaleEditDisabledFieldsDisable = data.SaleEditDisabledFieldsDisable;
		this.SaleEditDisabledFields = [...(data.SaleEditDisabledFields || [])];
		this.OpenClientInfoModalAfterSelect = boolOrNumberToNumber(data.OpenClientInfoModalAfterSelect) || OpenClientInfoModalAfterSelectOptions.OFF;
		this.SelectNextLineOnProductSubmit = data.SelectNextLineOnProductSubmit;
		this.SkipSubmitOnDescriptionChange = data.SkipSubmitOnDescriptionChange;
		this.AllowEditOfSignedDocuments = data.AllowEditOfSignedDocuments;
		this.LastSalesRecoveryID = data.LastSalesRecoveryID;
		this.WaitForEnterOnProductSearch = data.WaitForEnterOnProductSearch;
		this.DefaultQtyOnEmptyLine = data.DefaultQtyOnEmptyLine || 1;
		this.ShowSeperateStockInfo = data.ShowSeperateStockInfo;
		this.ShowSaleRecovery = data.ShowSaleRecovery === undefined ? true : data.ShowSaleRecovery;
		this.RedirectToDashboardAfterSave = data.RedirectToDashboardAfterSave === undefined ? false : data.RedirectToDashboardAfterSave;
		this.ClearSelectionAfterSave = data.ClearSelectionAfterSave === undefined ? false : data.ClearSelectionAfterSave;
		this.FullSaleRowView = data.FullSaleRowView === undefined ? false : data.FullSaleRowView;
		this.PurchaseAskForBankTransfer = data.PurchaseAskForBankTransfer === undefined ? false : data.PurchaseAskForBankTransfer;
		for (let key of Object.keys(data.Configs || {})) {
			this.Configs[key] = new SaleConfig(data.Configs[key]);
		}
		this.SaleEditRowExtraInfoFields = ((data.SaleEditRowExtraInfoFields || []) as any[]).map(f=>new SaleEditRowExtraInfoField(f));
		this.AWSDocumentManagerAutomaticallyOpenPrintSettingsOnAdd = data.AWSDocumentManagerAutomaticallyOpenPrintSettingsOnAdd;
		this.AWSDocumentManagerAutomaticallyShowWithPrint=data.AWSDocumentManagerAutomaticallyShowWithPrint;
		this.AWSDocumentManagerAutomaticallyShowWithDownload=data.AWSDocumentManagerAutomaticallyShowWithDownload;
		this.AWSDocumentManagerAutomaticallyShowWithSend=data.AWSDocumentManagerAutomaticallyShowWithSend;
		this.TemplateSettingsDays=data.TemplateSettingsDays;
		this.TemplateSettingsUpdatePrices=data.TemplateSettingsUpdatePrices;
		this.TemplateSettingsSaleTypes=[...(data.TemplateSettingsSaleTypes || [])];
	}

	// eslint-disable-next-line max-lines-per-function
	getJSON(): any {
		let configs:{[key:string]:any} = {};
		for (let key of Object.keys(this.Configs)) {
			configs[key] = this.Configs[key].getJSON();
		}
		return {
			DefaultNewSaleVatID: this.DefaultNewSaleVatID,
			DefaultNewProductVatID: this.DefaultNewProductVatID,
			DefaultQuotationValidDays: this.DefaultQuotationValidDays,
			NewSaleAutoFocusField: this.NewSaleAutoFocusField,
			ShowUnits: this.ShowUnits,
			ShowLeeggoed: this.ShowLeeggoed,
			DescriptionEditor: this.DescriptionEditor,
			ShowProfitBar: this.ShowProfitBar,
			ShowStockInfo: this.ShowStockInfo,
			CompactSaleOverview: this.CompactSaleOverview,
			HideHiddenRows: this.HideHiddenRows,
			SaleDisplayOrder: [...this.SaleDisplayOrder],
			PurchaseDisplayOrder: [...this.PurchaseDisplayOrder],
			ProspectDisplayOrder: [...this.ProspectDisplayOrder],
			VatRegimeDisplayOrder: [...this.VatRegimeDisplayOrder],
			PurchaseVatRegimeDisplayOrder: [...this.PurchaseVatRegimeDisplayOrder],
			Configs: configs,
			SaleLayoutDisplayOrder: [...this.SaleLayoutDisplayOrder],
			PurchaseLayoutDisplayOrder: [...this.PurchaseLayoutDisplayOrder],
			ProspectLayoutDisplayOrder: [...this.ProspectLayoutDisplayOrder],
			SaleEditDisabledFields: [...this.SaleEditDisabledFields],
			SaleEditDisabledFieldsDisable: this.SaleEditDisabledFieldsDisable,
			OpenClientInfoModalAfterSelect: this.OpenClientInfoModalAfterSelect,
			SelectNextLineOnProductSubmit: this.SelectNextLineOnProductSubmit,
			SkipSubmitOnDescriptionChange: this.SkipSubmitOnDescriptionChange,
			AllowEditOfSignedDocuments: this.AllowEditOfSignedDocuments,
			ViesCheckExtraField: this.ViesCheckExtraField,
			LastSalesRecoveryID: this.LastSalesRecoveryID,
			WaitForEnterOnProductSearch: this.WaitForEnterOnProductSearch,
			DefaultQtyOnEmptyLine: this.DefaultQtyOnEmptyLine,
			ShowSeperateStockInfo: this.ShowSeperateStockInfo,
			ShowSaleRecovery: this.ShowSaleRecovery,
			RedirectToDashboardAfterSave: this.RedirectToDashboardAfterSave,
			ClearSelectionAfterSave: this.ClearSelectionAfterSave,
			FullSaleRowView: this.FullSaleRowView,
			PurchaseAskForBankTransfer: this.PurchaseAskForBankTransfer,
			SaleEditRowExtraInfoFields: this.SaleEditRowExtraInfoFields.map(f=>f.getJSON()),
			AWSDocumentManagerAutomaticallyOpenPrintSettingsOnAdd: this.AWSDocumentManagerAutomaticallyOpenPrintSettingsOnAdd,
			AWSDocumentManagerAutomaticallyShowWithPrint: this.AWSDocumentManagerAutomaticallyShowWithPrint,
			AWSDocumentManagerAutomaticallyShowWithDownload: this.AWSDocumentManagerAutomaticallyShowWithDownload,
			AWSDocumentManagerAutomaticallyShowWithSend: this.AWSDocumentManagerAutomaticallyShowWithSend,
			TemplateSettingsSaleTypes: [...this.TemplateSettingsSaleTypes],
			TemplateSettingsDays: this.TemplateSettingsDays,
			TemplateSettingsUpdatePrices: this.TemplateSettingsUpdatePrices
		};
	}

	getSaleConfig(saleType: number | SaleType): SaleConfig {
		if (typeof saleType == "object") {
			saleType = saleType.ID;
		}
		return this.Configs[saleType] || new SaleConfig();
	}

	setSaleConfig(saleType: number | SaleType, config: SaleConfig){
		if (typeof saleType == "object") {
			saleType = saleType.ID;
		}
		this.Configs[saleType] = config;
	}

	getSaleLayoutDisplayOrderByContactGroupId(contactGroupId:number):number[] {
		if (contactGroupId == 1) {
			return this.SaleLayoutDisplayOrder;
		}
		if(contactGroupId == 2){
			return this.PurchaseLayoutDisplayOrder;
		}
		return this.ProspectLayoutDisplayOrder;
	}
}
import { AuthService, UserConfigService } from "@/services";

export class DashboardSettings {
	SubtractPurchasesFromProfit:boolean = false;
	SubtractSaleBuyPriceFromProfit:boolean = false;
	ToComputeSaleTypes:number[] = [];
	TabOrder:number[] = [];
	LastOpenedTabIndex:number = 0;

	public constructor(data?:any){
		this.SubtractPurchasesFromProfit = data.SubtractPurchasesFromProfit;
		this.SubtractSaleBuyPriceFromProfit = data.SubtractSaleBuyPriceFromProfit;
		this.ToComputeSaleTypes = [...data.ToComputeSaleTypes || []];
		this.TabOrder = [...data.TabOrder || []];
		this.LastOpenedTabIndex = data.LastOpenedTabIndex || 0;
	}

	public getJSON(){
		return {
			SubtractPurchasesFromProfit: this.SubtractPurchasesFromProfit,
			SubtractSaleBuyPriceFromProfit: this.SubtractSaleBuyPriceFromProfit,
			ToComputeSaleTypes: [...this.ToComputeSaleTypes],
			TabOrder: [...this.TabOrder],
			LastOpenedTabIndex: this.LastOpenedTabIndex
		};
	}

	public static canShareSettings():boolean {
		if (AuthService.wfCompany && AuthService.wfUser){
			if (AuthService.wfUser.ID == AuthService.wfCompany.OwnerID){
				return true;
			}
		}
		if (UserConfigService.getPermissionSettings().IsAdmin){
			return true;
		}
		return false;
	}

}
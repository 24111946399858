import { View } from "@/models/view";

let converterList:{[key:string]:string} = {
	"Active": "active",
	"ComputeStock": "compute-stock",
	"Sku": "sku",
	"NameStripped": "name",
	"Vat.Value": "vat",
	"SellPrice1": "sell-price-1",
	"SellPrice2": "sell-price-2",
	"SellPrice3": "sell-price-3",
	"SellPrice4": "sell-price-4",
	"SellPrice5": "sell-price-5",
	"SellPrice6": "sell-price-6",
	"SellPrice7": "sell-price-7",
	"SellPrice8": "sell-price-8",
	"SellPrice9": "sell-price-9",
	"SellPrice10": "sell-price-10",
	"BuyNettoPrice": "buy-netto-price",
	"BuyCatalogPrice": "buy-catalog-price",
	"ManufacturerCode": "manufacturer-code",
	"SupplierCode": "supplier-code",
	"MainSupplier.Contact.ComputedFriendlyID": "supplier-nr",
	"MainSupplier.Brand": "brand",
	"RecupelTax": "recupel-tax",
	"AuvibelTax": "auvibel-tax",
	"BebatTax": "bebat-tax",
	"ReprobelTax": "reprobel-tax",
	"LeeggoedTax": "leeggoed-tax",
	"AccijnzenTax": "accijnzen-tax",
	"EcoboniTax": "ecoboni-tax",
	"BarCode": "bar-code",
	"LedgerAccountCode": "ledger-account-code",
	"ExtraFields.Num1": "ev-num-1",
	"ExtraFields.Num2": "ev-num-2",
	"ExtraFields.Num3": "ev-num-3",
	"ExtraFields.Num4": "ev-num-4",
	"ExtraFields.Num5": "ev-num-5",
	"ExtraFields.Num6": "ev-num-6",
	"ExtraFields.Num7": "ev-num-7",
	"ExtraFields.Num8": "ev-num-8",
	"ExtraFields.Num9": "ev-num-9",
	"ExtraFields.Num10": "ev-num-10",
	"ExtraFields.Num11": "ev-num-11",
	"ExtraFields.Num12": "ev-num-12",
	"ExtraFields.Num13": "ev-num-13",
	"ExtraFields.Num14": "ev-num-14",
	"ExtraFields.Num15": "ev-num-15",
	"ExtraFields.Num16": "ev-num-16",
	"ExtraFields.Num17": "ev-num-17",
	"ExtraFields.Num18": "ev-num-18",
	"ExtraFields.Num19": "ev-num-19",
	"ExtraFields.Num20": "ev-num-20",
	"ExtraFields.Text1": "ev-text-1",
	"ExtraFields.Text2": "ev-text-2",
	"ExtraFields.Text3": "ev-text-3",
	"ExtraFields.Text4": "ev-text-4",
	"ExtraFields.Text5": "ev-text-5",
	"ExtraFields.Text6": "ev-text-6",
	"ExtraFields.Text7": "ev-text-7",
	"ExtraFields.Text8": "ev-text-8",
	"ExtraFields.Text9": "ev-text-9",
	"ExtraFields.Text10": "ev-text-10",
	"ExtraFields.Text11": "ev-text-11",
	"ExtraFields.Text12": "ev-text-12",
	"ExtraFields.Text13": "ev-text-13",
	"ExtraFields.Text14": "ev-text-14",
	"ExtraFields.Text15": "ev-text-15",
	"ExtraFields.Text16": "ev-text-16",
	"ExtraFields.Text17": "ev-text-17",
	"ExtraFields.Text18": "ev-text-18",
	"ExtraFields.Text19": "ev-text-19",
	"ExtraFields.Text20": "ev-text-20",
	"ExtraFields.Bool1": "ev-bool-1",
	"ExtraFields.Bool2": "ev-bool-2",
	"ExtraFields.Bool3": "ev-bool-3",
	"ExtraFields.Bool4": "ev-bool-4",
	"ExtraFields.Bool5": "ev-bool-5",
	"ExtraFields.Bool6": "ev-bool-6",
	"ExtraFields.Bool7": "ev-bool-7",
	"ExtraFields.Bool8": "ev-bool-8",
	"ExtraFields.Bool9": "ev-bool-9",
	"ExtraFields.Bool10": "ev-bool-10",
	"ExtraFields.Bool11": "ev-bool-11",
	"ExtraFields.Bool12": "ev-bool-12",
	"ExtraFields.Bool13": "ev-bool-13",
	"ExtraFields.Bool14": "ev-bool-14",
	"ExtraFields.Bool15": "ev-bool-15",
	"ExtraFields.Bool16": "ev-bool-16",
	"ExtraFields.Bool17": "ev-bool-17",
	"ExtraFields.Bool18": "ev-bool-18",
	"ExtraFields.Bool19": "ev-bool-19",
	"ExtraFields.Bool20": "ev-bool-20",
	"Stock.Location1Qty": "stock-qty-location-1",
	"Stock.Location2Qty": "stock-qty-location-2",
	"Stock.Location3Qty": "stock-qty-location-3",
	"Stock.Location4Qty": "stock-qty-location-4",
	"Stock.Location5Qty": "stock-qty-location-5",
	"Stock.Location6Qty": "stock-qty-location-6",
	"Stock.Location7Qty": "stock-qty-location-7",
	"Stock.Location8Qty": "stock-qty-location-8",
	"Stock.Location9Qty": "stock-qty-location-9",
	"Unit.DisplayedName": "unit",
	"DiscountsDisabled": "discounts-disabled",
	"MarkedToPrintLabel": "marked-to-print-label",
	"DefaultValueQty2": "default-value-qty-2",
	"IntrastatData.Excnt": "intrastat-excnt",
	"IntrastatData.Extreg": "intrastat-extreg",
	"IntrastatData.Extgo": "intrastat-extgo",
	"IntrastatData.Exweight": "intrastat-exweight",
	"IntrastatData.Excntori": "intrastat-excntori",
	"PriceCategorySalesDates.PriceCategory1StartDate": "price-category-start-date-1",
	"PriceCategorySalesDates.PriceCategory1EndDate": "price-category-end-date-1",
	"PriceCategorySalesDates.PriceCategory2StartDate": "price-category-start-date-2",
	"PriceCategorySalesDates.PriceCategory2EndDate": "price-category-end-date-2",
	"PriceCategorySalesDates.PriceCategory3StartDate": "price-category-start-date-3",
	"PriceCategorySalesDates.PriceCategory3EndDate": "price-category-end-date-3",
	"PriceCategorySalesDates.PriceCategory4StartDate": "price-category-start-date-4",
	"PriceCategorySalesDates.PriceCategory4EndDate": "price-category-end-date-4",
	"PriceCategorySalesDates.PriceCategory5StartDate": "price-category-start-date-5",
	"PriceCategorySalesDates.PriceCategory5EndDate": "price-category-end-date-5",
	"PriceCategorySalesDates.PriceCategory6StartDate": "price-category-start-date-6",
	"PriceCategorySalesDates.PriceCategory6EndDate": "price-category-end-date-6",
	"PriceCategorySalesDates.PriceCategory7StartDate": "price-category-start-date-7",
	"PriceCategorySalesDates.PriceCategory7EndDate": "price-category-end-date-7",
	"PriceCategorySalesDates.PriceCategory8StartDate": "price-category-start-date-8",
	"PriceCategorySalesDates.PriceCategory8EndDate": "price-category-end-date-8",
	"PriceCategorySalesDates.PriceCategory9StartDate": "price-category-start-date-9",
	"PriceCategorySalesDates.PriceCategory9EndDate": "price-category-end-date-9",
	"PriceCategorySalesDates.PriceCategory10StartDate": "price-category-start-date-10",
	"PriceCategorySalesDates.PriceCategory10EndDate": "price-category-end-date-10",
	"MainGroup.Name": "main-group"
};

export function replaceProductColsWithCsvCols(view:View, colIndex:number):string {
	if (!view.Columns[colIndex]) return "";
	let col = view.Columns[colIndex];
	return converterList[col.Property] || "";
}


import { DossierConfigService } from "@/services/dossier-config-service";
import { SellCategory } from "@/utils/sell-category";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { ISelectItem } from "winfakt-vue-components";
export default Vue.extend({
	props: {
		value: {
			type: [Object as () => SellCategory, Number]
		},
		useNumber: Boolean,
		label: [String, Object as () => TranslateResult],
		fancy: Boolean,
		addEmptyValue: Boolean
	},
	computed: {
		sellCategories():SellCategory[] {
			let result:(SellCategory)[] = DossierConfigService.getSellCategories().getCategories();
			return result;
		},
		items():ISelectItem<SellCategory | null>[] {
			let items =  this.sellCategories.filter(c => c && c.Active).map((c, i) => {
				return {
					text: (c as SellCategory).Name || this.$ct("price-cat", {i: i+1}),
					value: c
				} as ISelectItem<SellCategory | null>;
			});

			if (this.addEmptyValue){
				items.unshift({
					text: this.$ct("common.none"),
					value: null
				});
			}

			return items;
		},
		computedValue():SellCategory | null {
			if (typeof(this.value) == "number") {
				if (this.value == 0) {
					if (this.addEmptyValue){
						return null;
					}
					return this.sellCategories[0];
				}
				return this.sellCategories[this.value - 1];
			}
			return this.value;
		}
	},
	methods: {
		onInput(value:SellCategory | null) {
			if (this.useNumber) {
				if (!value){
					return this.$emit("input", 0);
				}
				return this.$emit("input", this.sellCategories.indexOf(value) + 1);
			}
			this.$emit("input", value);
		}
	},
	i18nEx: {
		componentPrefix: "components.SellPriceCategorySelect"
	}
});

import { BrowseStateBase } from "./browse-state-base";

export class BrowseStateSearch extends BrowseStateBase {
	showReportsButton:boolean = false;
	multiple:boolean = false;
	allowViewButton:boolean = false;

	async fetchReports():Promise<void>{
		if (!this.showReportsButton) return;
		super.fetchReports();
	}

	useCheckboxes(): boolean {
		if (!this.multiple) return false;
		return super.useCheckboxes();
	}
}
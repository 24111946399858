import BankType from "./bank-type";
import Contact from "./contact";
import { field, nullable, rightAlign, print, filterValueInput, computed, decimals, onClick } from "@/utils/models/decorator-field";
import { ignoredFields, model } from "@/utils/models/decorator-model";
import { Purchase, Sale } from ".";
import { BankCtepSaleResult } from "./bank-ctep-sale-result";
import { isAboutEqualPrice } from "@/utils/numbers";
import { BankAwsFile } from "./bank-aws-file";
import { printBoolean } from "@/utils/pretty-print";
import { LedgerAccount } from "../ledger-account";
import { BankService } from "@/services";
import { AwsDocument } from "./aws-document";
import { getOpenAwsDocumentsFunc } from "@/utils/models/click-action-handlers";

export enum BankKind {
	Diverse = 1,
	Sale = 2,
	Purchase = 3
}


@model("Bank")
@ignoredFields([
	"FriendlyID",
	"Contact.MainAddress.Contact",
	"Ticket.LastBank",
	"Ticket.InvoiceAddress.Contact",
	"Ticket.DeliveryAddress.Contact",
	"Ticket.Contact",
	"Purchase.LastBank",
	"Purchase.Contact",
	"Invoice.LastBank",
	"Invoice.InvoiceAddress.Contact",
	"Invoice.DeliveryAddress.Contact",
	"Invoice.Contact",
	"Invoice.CreatedByFrequencyDocument.Sale"
])
export default class Bank {
	@field("number")
	public ID:number = 0;

	@field("number")
	public InvoiceID:number = 0;

	@field("number")
	public BankID:number = 1;

	@field("number")
	public TypeID:number = 1;
	@print((value:Number):string =>{
		if(value == 1){
			return "D";
		}
		if(value == 2){
			return "V";
		}
		return "A";
	})

	@field("number")
	@filterValueInput("BankKind")
	public Kind:BankKind = BankKind.Sale;

	@field("number")
	@filterValueInput("Contact")
	public ContactID:number = 0;

	@field("has-one", "Contact")
	@nullable()
	public Contact:Contact | null = null;

	@field("number")
	@rightAlign()
	@decimals(2)
	public Total:number = 0;

	@field("number")
	@rightAlign()
	@decimals(2)
	public TotalDC:number = 0;

	@field("date")
	@print((value:Date):string =>{
		return value.toLocaleDateString("nl-be", { year: "numeric", month: "2-digit", day: "2-digit" });
	})
	public TimeCreated:Date = new Date();

	@field("string")
	@computed()
	public get DC(): string {
		return this.isDC;
	}

	@field("has-one", "BankType")
	@nullable()
	public Type:BankType | null = null;

	@field("string")
	public FriendlySaleID:string = "";

	@field("number")
	@filterValueInput("BookYear")
	public BookYear:number = 0;

	@field("string")
	public StNr:string = "";

	@field("string")
	public Info:string = "";

	@field("has-one", "Sale")
	public Invoice:Sale | null = null;

	@field("number")
	public PurchaseID:number = 0;

	@field("string")
	public FriendlyPurchaseID:string = "";

	@field("has-one", "Purchase")
	public Purchase:Purchase | null = null;

	@field("string")
	@nullable()
	public LedgerAccountCode:string | null = null;

	@field("has-one", "LedgerAccount")
	public LedgerAccount:LedgerAccount | null = null;

	@field("number")
	public TicketID:number = 0;

	@field("has-one", "Sale")
	public Ticket:Sale | null = null;

	@field("boolean")
	public IsBeginBank:boolean = false;

	public CtepSaleResult:BankCtepSaleResult | null = null

	public Documents:BankAwsFile[] = [];

	@field("boolean")
	@print(printBoolean)
	@onClick(getOpenAwsDocumentsFunc(async function(value:any):Promise<AwsDocument[]>{
		let result = await BankService.getBooking(value.ID);
		return result.Documents;
	}))
	public HasAttachedDocument:boolean = false;
	public MetaData:any;

	// eslint-disable-next-line max-lines-per-function
	public constructor(data?: any) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		//Unused --> TypeID
		this.BankID = data.BankID;
		this.InvoiceID = data.InvoiceID;
		this.Kind = data.Kind || 2;
		this.TypeID = data.TypeID || 1;
		if (data.Type) {
			this.Type = new BankType(data.Type);
		}
		this.ContactID = data.ContactID;
		if (data.Contact) {
			this.Contact = new Contact(data.Contact);
		}
		this.Total = data.Total;
		this.TotalDC = data.TotalDC;
		this.TimeCreated = new Date(data.TimeCreated);
		this.FriendlySaleID = data.FriendlySaleID;
		this.BookYear = data.BookYear;
		this.StNr = data.StNr;
		this.LedgerAccountCode = data.LedgerAccountCode;
		this.Info = data.Info;
		this.PurchaseID = data.PurchaseID;
		this.FriendlyPurchaseID = data.FriendlyPurchaseID;
		this.TicketID = data.TicketID;
		this.IsBeginBank = data.IsBeginBank;
		this.MetaData = data.MetaData;

		if (data.Invoice){
			this.Invoice = new Sale(data.Invoice);
		}
		if (data.Purchase){
			this.Purchase = new Purchase(data.Purchase);
		}
		if (data.Ticket){
			this.Ticket = new Sale(data.Ticket);
		}
		if (data.CtepSaleResult){
			this.CtepSaleResult = new BankCtepSaleResult(data.CtepSaleResult);
		}
		if (data.Documents){
			this.Documents = (data.Documents as any[] || []).map(p=>new BankAwsFile(p));
		}
		if (data.LedgerAccount) {
			this.LedgerAccount = new LedgerAccount(data.LedgerAccount);
		}
		this.HasAttachedDocument = data.HasAttachedDocument;
	}

	public getJSON() {
		if (this.Kind == BankKind.Purchase){
			this.FriendlySaleID = "";
			this.InvoiceID = 0;
		}else if (this.Kind == BankKind.Sale) {
			this.FriendlyPurchaseID = "";
			this.PurchaseID = 0;
		}
		let ctepSaleResult:any = null;
		if (this.CtepSaleResult) {
			ctepSaleResult = this.CtepSaleResult.getJSON();
		}
		return {
			ID: this.ID,
			BankID: this.BankID,
			InvoiceID: this.InvoiceID,
			TypeID: this.TypeID,
			Kind: this.Kind,
			ContactID: this.ContactID,
			Contact: new Contact(this.Contact?.getJSON()),
			Type: new BankType(this.Type?.getJSON()),
			Total: this.Total,
			TotalDC: this.TotalDC,
			TimeCreated: this.TimeCreated.toJSON(),
			FriendlySaleID: this.FriendlySaleID,
			BookYear: this.BookYear,
			StNr: this.StNr,
			LedgerAccountCode: this.LedgerAccountCode,
			Info: this.Info,
			PurchaseID: this.PurchaseID,
			FriendlyPurchaseID: this.FriendlyPurchaseID,
			TicketID: this.TicketID,
			CtepSaleResult: ctepSaleResult,
			IsBeginBank: this.IsBeginBank,
			HasAttachedDocument: this.HasAttachedDocument
		};
	}

	public get isInvalidAsCoda():boolean{
		if(this.Invoice){
			if(isAboutEqualPrice(this.Invoice.TotalOpen, this.Total)){
				return false;
			}
		}
		if(this.Purchase){
			if(isAboutEqualPrice(this.Purchase.TotalOpen, this.Total)){
				return false;
			}
		}
		return false;
	}

	public get isAlreadyPayed():boolean{
		if(this.Invoice){
			if(this.Invoice.TotalOpen == 0){
				return true;
			}
		}
		if(this.Purchase){
			if(this.Purchase.TotalOpen == 0){
				return true;
			}
		}
		return false;
	}

	public get computedFriendlyID():string{
		if(this.isInvalidAsCoda){
			return "fas fa-exclamation-triangle";
		}
		if(this.isValidAsCodaRow){
			return "fas fa-check check";
		}
		return "";
	}

	public get saleOrPurchase():string{
		if(this.InvoiceID && this.Invoice){
			return this.Invoice.ComputedFriendlyID;
		}
		if(this.PurchaseID && this.Purchase){
			return this.Purchase.ComputedFriendlyID;
		}
		return "";
	}

	public get saleOrPurchaseTotal():number{
		if(this.InvoiceID && this.Invoice){
			return this.Invoice.TotalIncVAT;
		}
		if(this.PurchaseID && this.Purchase){
			return this.Purchase.Total;
		}
		return 0;
	}

	public get saleOrPurchaseTotalOpen():number{
		if(this.InvoiceID && this.Invoice){
			return this.Invoice.TotalOpen;
		}
		if(this.PurchaseID && this.Purchase){
			return this.Purchase.TotalOpen;
		}
		return 0;
	}

	public get codaRowIcon():string{
		if(this.isInvalidAsCoda){
			return "fas fa-exclamation-triangle";
		}
		if(this.isValidAsCodaRow){
			return "fas fa-check check";
		}
		return "";
	}

	public get isDC():string{
		if(this.TotalDC > 0 && this.Total > 0 ) {
			return "+";
		}
		return "-";
	}

	public get isValidAsCodaRow():boolean{
		if (this.ContactID != 0){
			if (this.InvoiceID != 0){
				return true;
			}
			if (this.PurchaseID != 0){
				return true;
			}
		}
		if(this.MetaData?.Processed == true){
			return true;
		}
		return false;
	}

	public getBrowseClass(): string[] {
		if (this.isAlreadyPayed) {
			return ["already-payed"];
		}
		if (this.isInvalidAsCoda) {
			return ["invalid-coda"];
		}
		if (this.isValidAsCodaRow) {
			return ["valid-coda"];
		}
		return [];
	}

}

import { AWSFile } from "../aws-file";

export interface IAwsDocumentDataSource {
	getDocuments:()=>Promise<AwsDocument[]>;
	addDocument:(file:File)=>Promise<void>;
	deleteDocument:(doc:AwsDocument)=>Promise<void>;
}


export abstract class AwsDocument{
	ID:number = 0;
	FileID:number = 0;
	File:AWSFile | null = null;

	public constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		if (data.File){
			this.File = new AWSFile(data.File);
		}
	}

	public getName():string{
		if (this.File) {
			return this.File.Name;
		}
		return "ERROR";
	}

	canPreview():boolean {
		if (!this.File) return false;
		return this.File.canPreview();
	}

	public getUrl():string{
		if (this.File){
			return this.File.getUrl();
		}
		return "ERROR";
	}
}
/* eslint-disable max-lines-per-function */
import { DossierConfigService } from "@/services/dossier-config-service";
import { field, translatedName, nullable, print, onClick } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";
import { parseDateFromData, convertDateToNullOrJSON, IExtraFields } from "@/utils/models/extra-fields";
import { printBoolean, printLocalDate } from "@/utils/pretty-print";
import { extraFieldEditClickAction } from "@/utils/models/click-action-handlers";

@model("CrmExtraFields")
export default class CrmExtraFields implements IExtraFields {
	@field("number")
	public ID: number = 0;
	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num1)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num1Config.printValue(v))
	public Num1:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num2)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num2Config.printValue(v))
	public Num2:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num3)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num3Config.printValue(v))
	public Num3:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num4)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num4Config.printValue(v))
	public Num4:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num5)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num5Config.printValue(v))
	public Num5:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num6)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num6Config.printValue(v))
	public Num6:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num7)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num7Config.printValue(v))
	public Num7:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num8)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num8Config.printValue(v))
	public Num8:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num9)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num9Config.printValue(v))
	public Num9:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num10)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num10Config.printValue(v))
	public Num10:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num11)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num11Config.printValue(v))
	public Num11:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num12)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num12Config.printValue(v))
	public Num12:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num13)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num13Config.printValue(v))
	public Num13:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num14)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num14Config.printValue(v))
	public Num14:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num15)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num15Config.printValue(v))
	public Num15:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num16)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num16Config.printValue(v))
	public Num16:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num17)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num17Config.printValue(v))
	public Num17:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num18)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num18Config.printValue(v))
	public Num18:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num19)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num19Config.printValue(v))
	public Num19:number = 0;

	@field("number")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num20)
	@print((v:number)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Num20Config.printValue(v))
	public Num20:number = 0;

	//-----------------------------------------------------------------------------------

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text1)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text1Config.printValue(v))
	public Text1:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text2)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text2Config.printValue(v))
	public Text2:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text3)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text3Config.printValue(v))
	public Text3:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text4)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text4Config.printValue(v))
	public Text4:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text5)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text5Config.printValue(v))
	public Text5:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text6)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text6Config.printValue(v))
	public Text6:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text7)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text7Config.printValue(v))
	public Text7:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text8)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text8Config.printValue(v))
	public Text8:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text9)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text9Config.printValue(v))
	public Text9:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text10)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text10Config.printValue(v))
	public Text10:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text11)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text11Config.printValue(v))
	public Text11:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text12)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text12Config.printValue(v))
	public Text12:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text13)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text13Config.printValue(v))
	public Text13:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text14)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text14Config.printValue(v))
	public Text14:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text15)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text15Config.printValue(v))
	public Text15:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text16)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text16Config.printValue(v))
	public Text16:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text17)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text17Config.printValue(v))
	public Text17:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text18)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text18Config.printValue(v))
	public Text18:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text19)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text19Config.printValue(v))
	public Text19:string = "";

	@field("string")
	@onClick(extraFieldEditClickAction)
	@translatedName(()=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text20)
	@print((v:string)=>DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Text20Config.printValue(v))
	public Text20:string = "";

	//------------------------------------------------------------------------------------

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool1)
	@print(printBoolean)
	public Bool1: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool2)
	@print(printBoolean)
	public Bool2: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool3)
	@print(printBoolean)
	public Bool3: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool4)
	@print(printBoolean)
	public Bool4: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool5)
	@print(printBoolean)
	public Bool5: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool6)
	@print(printBoolean)
	public Bool6: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool7)
	@print(printBoolean)
	public Bool7: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool8)
	@print(printBoolean)
	public Bool8: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool9)
	@print(printBoolean)
	public Bool9: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool10)
	@print(printBoolean)
	public Bool10: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool11)
	@print(printBoolean)
	public Bool11: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool12)
	@print(printBoolean)
	public Bool12: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool13)
	@print(printBoolean)
	public Bool13: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool14)
	@print(printBoolean)
	public Bool14: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool15)
	@print(printBoolean)
	public Bool15: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool16)
	@print(printBoolean)
	public Bool16: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool17)
	@print(printBoolean)
	public Bool17: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool18)
	@print(printBoolean)
	public Bool18: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool19)
	@print(printBoolean)
	public Bool19: boolean = false;

	@field("boolean")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Bool20)
	@print(printBoolean)
	public Bool20: boolean = false;

	//------------------------------------------------------------------------------------

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time1)
	@print(printLocalDate)
	@nullable()
	public Time1: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time2)
	@print(printLocalDate)
	@nullable()
	public Time2: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time3)
	@print(printLocalDate)
	@nullable()
	public Time3: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time4)
	@print(printLocalDate)
	@nullable()
	public Time4: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time5)
	@print(printLocalDate)
	@nullable()
	public Time5: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time6)
	@print(printLocalDate)
	@nullable()
	public Time6: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time7)
	@print(printLocalDate)
	@nullable()
	public Time7: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time8)
	@print(printLocalDate)
	@nullable()
	public Time8: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time9)
	@print(printLocalDate)
	@nullable()
	public Time9: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time10)
	@print(printLocalDate)
	@nullable()
	public Time10: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time11)
	@print(printLocalDate)
	@nullable()
	public Time11: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time12)
	@print(printLocalDate)
	@nullable()
	public Time12: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time13)
	@print(printLocalDate)
	@nullable()
	public Time13: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time14)
	@print(printLocalDate)
	@nullable()
	public Time14: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time15)
	@print(printLocalDate)
	@nullable()
	public Time15: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time16)
	@print(printLocalDate)
	@nullable()
	public Time16: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time17)
	@print(printLocalDate)
	@nullable()
	public Time17: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time18)
	@print(printLocalDate)
	@nullable()
	public Time18: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time19)
	@print(printLocalDate)
	@nullable()
	public Time19: Date | null = null;

	@field("date")
	@onClick(extraFieldEditClickAction)
	@translatedName(() => DossierConfigService.getReadonlyExtraFieldNamesConfigs().CrmNames.Time20)
	@print(printLocalDate)
	@nullable()
	public Time20: Date | null = null;

	//------------------------------------------------------------------------------------

	public constructor(data: any = null) {
		if (!data) {
			return;
		}
		this.ID = data.ID;

		this.Num1 = data.Num1;
		this.Num2 = data.Num2;
		this.Num3 = data.Num3;
		this.Num4 = data.Num4;
		this.Num5 = data.Num5;
		this.Num6 = data.Num6;
		this.Num7 = data.Num7;
		this.Num8 = data.Num8;
		this.Num9 = data.Num9;
		this.Num10 = data.Num10;
		this.Num11 = data.Num11;
		this.Num12 = data.Num12;
		this.Num13 = data.Num13;
		this.Num14 = data.Num14;
		this.Num15 = data.Num15;
		this.Num16 = data.Num16;
		this.Num17 = data.Num17;
		this.Num18 = data.Num18;
		this.Num19 = data.Num19;
		this.Num20 = data.Num20;

		this.Text1 = data.Text1;
		this.Text2 = data.Text2;
		this.Text3 = data.Text3;
		this.Text4 = data.Text4;
		this.Text5 = data.Text5;
		this.Text6 = data.Text6;
		this.Text7 = data.Text7;
		this.Text8 = data.Text8;
		this.Text9 = data.Text9;
		this.Text10 = data.Text10;
		this.Text11 = data.Text11;
		this.Text12 = data.Text12;
		this.Text13 = data.Text13;
		this.Text14 = data.Text14;
		this.Text15 = data.Text15;
		this.Text16 = data.Text16;
		this.Text17 = data.Text17;
		this.Text18 = data.Text18;
		this.Text19 = data.Text19;
		this.Text20 = data.Text20;

		this.Bool1 = data.Bool1;
		this.Bool2 = data.Bool2;
		this.Bool3 = data.Bool3;
		this.Bool4 = data.Bool4;
		this.Bool5 = data.Bool5;
		this.Bool6 = data.Bool6;
		this.Bool7 = data.Bool7;
		this.Bool8 = data.Bool8;
		this.Bool9 = data.Bool9;
		this.Bool10 = data.Bool10;
		this.Bool11 = data.Bool11;
		this.Bool12 = data.Bool12;
		this.Bool13 = data.Bool13;
		this.Bool14 = data.Bool14;
		this.Bool15 = data.Bool15;
		this.Bool16 = data.Bool16;
		this.Bool17 = data.Bool17;
		this.Bool18 = data.Bool18;
		this.Bool19 = data.Bool19;
		this.Bool20 = data.Bool20;

		this.Time1 = parseDateFromData(data.Time1);
		this.Time2 = parseDateFromData(data.Time2);
		this.Time3 = parseDateFromData(data.Time3);
		this.Time4 = parseDateFromData(data.Time4);
		this.Time5 = parseDateFromData(data.Time5);
		this.Time6 = parseDateFromData(data.Time6);
		this.Time7 = parseDateFromData(data.Time7);
		this.Time8 = parseDateFromData(data.Time8);
		this.Time9 = parseDateFromData(data.Time9);
		this.Time10 = parseDateFromData(data.Time10);
		this.Time11 = parseDateFromData(data.Time11);
		this.Time12 = parseDateFromData(data.Time12);
		this.Time13 = parseDateFromData(data.Time13);
		this.Time14 = parseDateFromData(data.Time14);
		this.Time15 = parseDateFromData(data.Time15);
		this.Time16 = parseDateFromData(data.Time16);
		this.Time17 = parseDateFromData(data.Time17);
		this.Time18 = parseDateFromData(data.Time18);
		this.Time19 = parseDateFromData(data.Time19);
		this.Time20 = parseDateFromData(data.Time20);
	}

	public getJSON() {
		return {
			ID: this.ID,
			Num1: this.Num1,
			Num2: this.Num2,
			Num3: this.Num3,
			Num4: this.Num4,
			Num5: this.Num5,
			Num6: this.Num6,
			Num7: this.Num7,
			Num8: this.Num8,
			Num9: this.Num9,
			Num10: this.Num10,
			Num11: this.Num11,
			Num12: this.Num12,
			Num13: this.Num13,
			Num14: this.Num14,
			Num15: this.Num15,
			Num16: this.Num16,
			Num17: this.Num17,
			Num18: this.Num18,
			Num19: this.Num19,
			Num20: this.Num20,

			Text1: this.Text1,
			Text2: this.Text2,
			Text3: this.Text3,
			Text4: this.Text4,
			Text5: this.Text5,
			Text6: this.Text6,
			Text7: this.Text7,
			Text8: this.Text8,
			Text9: this.Text9,
			Text10: this.Text10,
			Text11: this.Text11,
			Text12: this.Text12,
			Text13: this.Text13,
			Text14: this.Text14,
			Text15: this.Text15,
			Text16: this.Text16,
			Text17: this.Text17,
			Text18: this.Text18,
			Text19: this.Text19,
			Text20: this.Text20,

			Bool1: this.Bool1,
			Bool2: this.Bool2,
			Bool3: this.Bool3,
			Bool4: this.Bool4,
			Bool5: this.Bool5,
			Bool6: this.Bool6,
			Bool7: this.Bool7,
			Bool8: this.Bool8,
			Bool9: this.Bool9,
			Bool10: this.Bool10,
			Bool11: this.Bool11,
			Bool12: this.Bool12,
			Bool13: this.Bool13,
			Bool14: this.Bool14,
			Bool15: this.Bool15,
			Bool16: this.Bool16,
			Bool17: this.Bool17,
			Bool18: this.Bool18,
			Bool19: this.Bool19,
			Bool20: this.Bool20,

			Time1: convertDateToNullOrJSON(this.Time1),
			Time2: convertDateToNullOrJSON(this.Time2),
			Time3: convertDateToNullOrJSON(this.Time3),
			Time4: convertDateToNullOrJSON(this.Time4),
			Time5: convertDateToNullOrJSON(this.Time5),
			Time6: convertDateToNullOrJSON(this.Time6),
			Time7: convertDateToNullOrJSON(this.Time7),
			Time8: convertDateToNullOrJSON(this.Time8),
			Time9: convertDateToNullOrJSON(this.Time9),
			Time10: convertDateToNullOrJSON(this.Time10),
			Time11: convertDateToNullOrJSON(this.Time11),
			Time12: convertDateToNullOrJSON(this.Time12),
			Time13: convertDateToNullOrJSON(this.Time13),
			Time14: convertDateToNullOrJSON(this.Time14),
			Time15: convertDateToNullOrJSON(this.Time15),
			Time16: convertDateToNullOrJSON(this.Time16),
			Time17: convertDateToNullOrJSON(this.Time17),
			Time18: convertDateToNullOrJSON(this.Time18),
			Time19: convertDateToNullOrJSON(this.Time19),
			Time20: convertDateToNullOrJSON(this.Time20),
		};
	}

}

import { render, staticRenderFns } from "./ProductEditImages.vue?vue&type=template&id=acd9283a&scoped=true"
import script from "./ProductEditImages.vue?vue&type=script&lang=ts"
export * from "./ProductEditImages.vue?vue&type=script&lang=ts"
import style0 from "./ProductEditImages.vue?vue&type=style&index=0&id=acd9283a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acd9283a",
  null
  
)

export default component.exports
export interface IElectronPrinter {
	deviceId:string;
	name:string;
}



export interface IElectronDefaultPrinterSettings {
	deviceId:string;
	printInLandscape:boolean;
}

export function getNewElectronDefaultPrinterSettings():IElectronDefaultPrinterSettings{
	return {
		deviceId: "",
		printInLandscape: false
	};
}
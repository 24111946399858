
import { MODULE } from "@/config/modules";
import { Product} from "@/models/base";
import { ProductPriceRule } from "@/models/base/product-price-rule";
import { AuthService } from "@/services/auth-service";
import Vue from "vue";
import AppMissingModule from "@/components/common/MissingModule.vue";
import AppPriceRuleEdit from "@/components/price-rules/PriceRuleEdit.vue";

export default Vue.extend({
	data(){
		return {
			toEditRule: null as ProductPriceRule | null
		};
	},
	props: {
		product: {
			type: Object as ()=>Product,
			required: true
		}
	},
	computed: {
		isAllowed(): Boolean {
			return AuthService.hasModule(this.moduleId);
		},
		moduleId():number {
			return MODULE.PRIJSBEDING;
		}
	},
	methods: {
		onSorted(){
			this.product.PriceRules.forEach((r,i)=>{
				r.DisplayOrder = i;
			});
		},
		deleteRule(rule:ProductPriceRule){
			let i = this.product.PriceRules.indexOf(rule);
			if (i == -1) {
				return;
			}
			this.product.PriceRules.splice(i,1);
		},
		editRule(rule:ProductPriceRule){
			this.toEditRule = rule;
		},
		addRule(){
			this.toEditRule = new ProductPriceRule();
		},
		onRuleSaved(rule:ProductPriceRule){
			if (!this.toEditRule) return;
			let i = this.product.PriceRules.indexOf(this.toEditRule);
			if (i == -1) {
				this.product.PriceRules.push(rule);
			}else{
				this.product.PriceRules[i] = rule;
			}
		}
	},
	components: {
		AppMissingModule,
		AppPriceRuleEdit
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});

import { ServerConfig } from "@/config/config";
import { refreshTokensIfRequired } from "../axios";
import { downloadBlob } from "../blob";
import { DocumentPageSettings } from "../documents/page-settings";
import { EventEmitter } from "../event-emitter";
import { getModel } from "../models/model";
import { ModelNamedProperty } from "../models/named-property";
import { tokenStore } from "../token-store";
import { BrowseStateBase } from "./browse-state-base";
import { replaceContactColsWithCsvCols } from "./browse-to-file-csv-replace-contact-names";
import { replaceProductColsWithCsvCols } from "./browse-to-file-csv-replace-product-names";
import { printPdf } from "../pdf";

export type IPrintBrowseType = "pdf" | "xlsx" | "csv";

export interface IPrintBrowseSettings {
	Type:IPrintBrowseType;
	PageSettings:DocumentPageSettings;
	selectedOnly:boolean;
	fileName:string;
	title:string;
	replaceColumnNamesWithProductCsvNames:boolean;
	replaceColumnNamesWithContactCsvNames:boolean;
}

export interface IToAddColumn{
	Type:"int" | "float" | "time" | "string";
	Value:any;
}



export class PrintBrowseRunner extends EventEmitter{
	private settings:IPrintBrowseSettings;
	private thead:HTMLElement;
	private tbody:HTMLElement;
	public get rowsList():HTMLCollection{
		if (this.settings.selectedOnly){
			return this.tbody.getElementsByClassName("selected");
		}
		return this.tbody.children;
	}
	private socket?:WebSocket;
	private row:number=0;
	private stepSize:number = 100;
	private done:boolean = false;
	private readonly maxRows:number = 100000;
	private browseState:BrowseStateBase;
	public totalRowCount:number = 0;
	public canceled:boolean = false;
	private columnData:(ModelNamedProperty | undefined)[] = [];
	private skippedIndexes:number[]=[];
	private useCheckboxes:boolean = false;

	constructor(settings:IPrintBrowseSettings, browseState:BrowseStateBase, thead:HTMLElement, tbody:HTMLElement){
		super();
		this.settings = settings;
		this.browseState = browseState;
		this.thead = thead;
		this.tbody = tbody;
		let view = browseState.querySettings.view;
		let model = getModel(view.Table);
		this.useCheckboxes = browseState.useCheckboxes();
		this.columnData = view.Columns.map(c=>{
			return model.getBrowseField(c.Property);
		});
	}

	private fromCellIndexToViewIndex(cellIndex:number):number{
		if (this.useCheckboxes){
			return cellIndex-1;
		}
		return cellIndex;
	}

	private getTheadColumnsJson(thead:HTMLElement):any[]{
		let result:any[] = [];
		let row = thead.children.item(0);
		if (!row) return result;
		for (let i = 0; i < row.children.length; i++){
			if (this.useCheckboxes && i == 0) {
				this.skippedIndexes.push(i);
				continue;
			};
			let cell = row.children.item(i);
			if (!cell) continue;
			let colName = (cell as HTMLTableColElement).innerText;
			if (this.settings.replaceColumnNamesWithProductCsvNames){
				colName = replaceProductColsWithCsvCols(this.browseState.querySettings.view, this.fromCellIndexToViewIndex(i));
			}else if (this.settings.replaceColumnNamesWithContactCsvNames){
				colName = replaceContactColsWithCsvCols(this.browseState.querySettings.view, this.fromCellIndexToViewIndex(i));
			}
			if (!colName && (this.settings.replaceColumnNamesWithProductCsvNames || this.settings.replaceColumnNamesWithContactCsvNames)){
				this.skippedIndexes.push(i);
				continue;
			}
			let item = {
				Text: colName,
				rightAlign: false,
				Width: cell.getBoundingClientRect().width
			};
			let text = cell.getElementsByClassName("text").item(0);
			if (text &&
				text.previousSibling &&
				(text.previousSibling as HTMLElement).classList &&
				(text.previousSibling as HTMLElement).classList.contains("w-spacer")) {
				item.rightAlign = true;
			}
			result.push(item);
		}
		return result;
	}

	private getTbodyColumnsJson():IToAddColumn[][]{
		let result:IToAddColumn[][] = [];
		for (let i = 0; i < this.stepSize; i++){
			if (this.row+i >= this.rowsList.length) break;
			let item = this.rowsList.item(this.row+i);
			if (!item) continue;
			let thisItem:IToAddColumn[] = [];
			for (let c = 0; c < item.children.length; c++){
				let cell = item.children.item(c);
				if (this.skippedIndexes.indexOf(c) != -1){
					continue;
				}
				let stringValue = (cell as HTMLTableColElement).innerText;
				thisItem.push(this.parseColumn(stringValue, this.fromCellIndexToViewIndex(c)));
			}
			result.push(thisItem);
		}
		return result;
	}

	private parseColumn(stringValue:string, viewColumnIndex:number):IToAddColumn {
		let defaultValue:IToAddColumn = {Type: "string", Value: stringValue};
		if (this.settings.Type != "xlsx") {
			return defaultValue;
		}
		let field = this.columnData[viewColumnIndex];
		if (!field) {
			return defaultValue;
		}
		if (field.field.type == "number") {
			let isFloat = stringValue.indexOf(".") != -1 || stringValue.indexOf(",") != -1;
			if (isFloat){
				let value = parseFloat(stringValue.replaceAll(".", "").replaceAll(" ", "").replaceAll(",", "."));
				if (Number.isNaN(value)) {
					return defaultValue;
				}
				return {Type: "float", Value: value};
			}
			let value = parseInt(stringValue);
			if (Number.isNaN(value)) {
				return defaultValue;
			}
			return {Type: "int", Value: value};
		}
		return defaultValue;
	}

	async cancel(){
		if (!this.socket) return;
		this.canceled = true;
		this.socket.close();
	}



	private async onMessage(ev:MessageEvent, socket:WebSocket, resolve:Function, reject:Function){
		if (typeof(ev.data) == "string" && ev.data.startsWith("ERROR: ")){
			reject(ev.data);
		}else if (ev.data == "authorized"){
			let result = this.getTheadColumnsJson(this.thead);
			socket.send(JSON.stringify({...this.settings, Columns: result}));
		} else if (ev.data == "request-data"){
			console.log("Got request for data");
			if (!this.settings.selectedOnly){
				console.log("Waiting for done fetching");
				await this.browseState.doneFetching();
			}
			let result = this.getTbodyColumnsJson();
			this.row += this.stepSize;
			this.row = Math.min(this.rowsList.length, this.row);
			this.emit("progress", this.row);
			socket.send(JSON.stringify(result));
			if (this.settings.selectedOnly) return;
			if (this.row + this.stepSize < this.rowsList.length) return;
			if (this.browseState.queryResults.records <= this.rowsList.length) return;
			console.log("Fetching more");
			await this.browseState.infiniteScrollFetchMore();
		}else if (ev.data instanceof Blob){
			// Fixes printing on chrome (in firefox ev.data works but because the contentType isn't  set it breaks on chrome)
			let blob = ev.data.slice(0, ev.data.size, "application/pdf");
			let fileName = `${this.settings.fileName}.${this.settings.Type}`;
			if (this.settings.Type == "pdf"){
				printPdf(blob, fileName);
			}else{
				downloadBlob(ev.data, fileName);
			}
			this.done = true;
			resolve();
		}else if (ev.data == "done"){
			this.done = true;
			resolve();
		}
	}

	async run():Promise<void>{
		if (this.socket) return;
		this.totalRowCount = Math.min(this.browseState.queryResults.records, this.maxRows);
		if (this.settings.selectedOnly){
			this.totalRowCount = this.rowsList.length;
		}
		let socket = new WebSocket(`${ServerConfig.host}/browse-to-file/create/${this.totalRowCount}`);
		this.socket = socket;
		return new Promise((resolve, reject)=>{
			socket.addEventListener("open", async (ev)=>{
				await refreshTokensIfRequired();
				socket.send(tokenStore.getSelectedToken().token);
			});
			socket.addEventListener("message", (ev)=>this.onMessage(ev, socket, resolve, reject));
			socket.addEventListener("error", (err)=>{
				reject(err);
			});
			socket.addEventListener("close", (ev)=>{
				if (!this.done) reject("ERROR");
			});
		});
	}
}
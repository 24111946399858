
import { compareFormat, getDefaultPageFormatsSelectItems, IFormat } from "@/utils/layout-editor";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";
import { DocumentPageSettings } from "../../utils/documents/page-settings";



export default Vue.extend({
	data(){
		return {
			useCustomFormat: false,
			headingHeightBuffer: 0,
			footerHeightBuffer: 0
		};
	},
	props: {
		value: {
			type: Object as () => DocumentPageSettings,
			required: true,
		}
	},
	computed: {
		// eslint-disable-next-line max-lines-per-function
		pageFormatItems(): ISelectItem<IFormat>[] {
			return getDefaultPageFormatsSelectItems();
		},
		selectedFormat(): IFormat {
			let result = this.pageFormatItems.find(v => this.compareFormat(v.value));
			if (!result) {
				return {
					width: this.value.Width,
					height: this.value.Height
				};
			}
			return result.value;
		},
	},
	methods: {
		setFormat(format: IFormat) {
			if (!format)return;
			this.value.Width = format.width;
			this.value.Height = format.height;
		},
		compareFormat(format:IFormat): boolean {
			return compareFormat(format, {height: this.value.Height, width: this.value.Width});
		},
		doesFormatExist(format:IFormat):boolean {
			for (let item of this.pageFormatItems) {
				if (item.value.width != format.width){
					continue;
				}
				if (item.value.height != format.height) {
					continue;
				}
				return true;
			}
			return false;
		},
		useDefaultFormat(){
			this.useCustomFormat = false;
			if (this.doesFormatExist({height: this.value.Height, width: this.value.Width})){
				return;
			}
			this.value.Width = 210;
			this.value.Height = 297;
		}
	},
	mounted(){
		if (!this.doesFormatExist({height: this.value.Height, width: this.value.Width})) {
			this.useCustomFormat = true;
		}
		this.headingHeightBuffer = this.value.HeadingHeight;
		this.footerHeightBuffer = this.value.FooterHeight;
	},
	i18nEx: {
		componentPrefix: "components.document-settings"
	}
});

import Contact from "./contact";
import {i18n} from "@/setup/i18n-setup";
import {
	computed,
	decimals,
	dependencies,
	field,
	filterValueInput,
	hidden,
	nullable,
	onClick,
	print,
	rightAlign
} from "@/utils/models/decorator-field";
import {ignoredFields, model, namedFilters} from "@/utils/models/decorator-model";
import {printBoolean, printHumanFloat, printHumanInt, printLocalDate, printSaleStatus} from "@/utils/pretty-print";
import {ISaleStatus, SaleStatus} from "@/models/base/sale";
import Bank from "./bank";
import {PurchaseLedger} from "./purchase-ledger";
import {NamedFilter} from "@/utils/models/named-filter";
import {HumanFilter} from "@/utils/human-filter";
import {TranslateResult} from "vue-i18n";
import {PurchaseAwsFile} from "./purchase-aws-file";
import {AWSFileService, PurchaseService} from "@/services";
import {PurchaseVatRegime} from "@/services/vat-service";
import PurchaseExtraFields from "./purchase-extra-fields";
import Currency from "@/models/base/currency";
import {getOpenAwsDocumentsFunc} from "@/utils/models/click-action-handlers";
import {AwsDocument} from "./aws-document";

@model("Purchase")
@ignoredFields([
	"Contact.MainAddress.Contact",
	"LastBank.Invoice",
	"LastBank.Purchase",
	"LastBank.FriendlyPurchaseID",
	"LastBank.PurchaseID",
	"LastBank.Contact",
	"LastBank.Sale.Banks",
	"LastBank.Ticket",
])
@namedFilters([
	new NamedFilter({
		name: "client-in-list",
		filterToString(filter:HumanFilter):TranslateResult{
			if (filter.Options.indexOf("not-in") != -1){
				return i18n.t("models.friendlyNames.Sale.client-not-in-list-text");
			}
			return i18n.t("models.friendlyNames.Sale.client-in-list-text");
		}
	}),
	new NamedFilter({
		name: "document-contains",
		filterToString(filter:HumanFilter):TranslateResult {
			if (filter.Options.indexOf("not") != -1) {
				return i18n.t("models.friendlyNames.Purchase.document-does-not-contain-text", {value: filter.Values[0]});
			}
			return i18n.t("models.friendlyNames.Purchase.document-contains-text", {value: filter.Values[0]});
		},
		canBeUsedAsDefaultViewFilter: true
	})
])
export default class Purchase{
	@field("number")
	public ID:number = 0;

	@field("string")
	@filterValueInput("BookYear")
	public BookYear:number = 0;

	@field("number")
	@hidden()
	public FriendlyID:number = 0;

	@field("string")
	public ComputedFriendlyID:string = "";

	@field("string")
	public InvoiceNR:string = "";

	@field("date")
	@print(printLocalDate)
	public InvoiceDate:Date = new Date();

	@field("number")
	public VatPeriod:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public Total:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public DiscountPercantage:number = 0;

	@field("number")
	@filterValueInput("ContactSupplier")
	public ContactID:number = 0;

	@field("date")
	@print(printLocalDate)
	public ExpirationDate:Date = new Date();

	@field("date")
	@print(printLocalDate)
	public TimeCreated:Date = new Date();

	@field("boolean")
	@print((value:boolean):string=>{
		if (value) {
			return i18n.t("common.creditnote").toString();
		}
		return i18n.t("common.invoice").toString();
	})
	public IsCreditNote:boolean = false;

	@field("string")
	public Communication:string = "";
	@field("string")
	public Ogm:string = "";
	@field("number")
	public VATPurchaseTradingGoods:number = 0;
	@field("number")
	public VATServicesAndCosts:number = 0;
	@field("number")
	public VATBuisinessAssets:number = 0;
	@field("number")
	public VATAmountImportWithinTheEG:number = 0;
	@field("number")
	public VATAmountCoContracts:number = 0;
	@field("number")
	public VATAmountServicesWithingTheEG:number = 0;
	@field("number")
	public VATSubtractable:number = 0;
	@field("number")
	public VATNotSubtractable:number = 0;
	@field("number")
	public VATImportWithinTheEG:number = 0;
	@field("number")
	public VATCoContracts:number = 0;
	@field("number")
	public VATImportOutsideTheEG:number = 0;

	@field("number")
	public Leeggoed:number = 0;

	@field("number")
	public JournalID:number = 1;

	@field("has-one", "Contact")
	public Contact:Contact | null = new Contact();

	@field("number")
	@decimals(2)
	@rightAlign()
	public TotalOpen:number = 0;

	@field("boolean")
	@print(printBoolean)
	@onClick(getOpenAwsDocumentsFunc(async function(value:any):Promise<AwsDocument[]>{
		let result = await PurchaseService.getPurchase(value.ID);
		return result.Documents;
	}))
	public HasAttachedDocument: boolean = false;


	@field("number")
	@computed()
	@print(printSaleStatus)
	public get Status(): ISaleStatus {

		if (this.TotalOpen < 0 && this.TotalOpen != this.Total) {
			return {
				Status: SaleStatus.Difference
			};
		}

		if (this.TotalOpen == 0) {
			return {
				Status: SaleStatus.Payed
			};
		}

		let myDate = new Date();
		let dayDiff = (myDate.getTime() - this.ExpirationDate.getTime()) / (1000 * 60 * 60 * 24);
		if (dayDiff < 0) {
			return {
				Status: SaleStatus.Unpayed
			};
		}
		return {
			Status: SaleStatus.Expired,
			DaysToLate: dayDiff
		};

	}

	@field("number")
	public LastBankID:number | null = null;
	@field("has-one", "Bank")
	public LastBank:Bank | null = null;

	@field("has-many", "PurchaseLedger")
	public Ledgers:PurchaseLedger[] = [];

	@field("string")
	public Ledger82B:string = "";

	@field("string")
	@computed()
	@dependencies(["Ledgers"])
	public get LedgersAsString():string{
		return this.Ledgers.map(l=>l.LedgerCode +", &euro;"+printHumanFloat(l.Amount)).join(" | ");
	}

	@field("string")
	@computed()
	@dependencies(["Ledgers.LedgerAccount"])
	public get LedgersCodeAndDescriptionsAsString():string{
		return this.Ledgers.map(l=>{
			let description = "";
			if (l.LedgerAccount) {
				description = l.LedgerAccount.Description;
			}
			return l.LedgerCode +" "+ description + (l.Comment ? ", " + l.Comment : "");
		}).join(" | ");
	}

	@field("number")
	@decimals(2)
	public TotalNetto:number = 0.0;


	@field("number")
	@decimals(2)
	public TotalVAT:number = 0.0;

	@field("number")
	@print(printHumanInt)
	public AccountingPeriod:number = 1;

	@field("has-many", "Bank")
	public Banks:Bank[] = [];

	@field("string")
	@computed()
	@dependencies(["Banks.Type"])
	public get BankBookings(): string {
		return this.Banks.map((b:Bank)=> {
			return b.Type?.Name + ": &euro; " + b.Total.toFixed(2)+ " - " + printLocalDate(b.TimeCreated);
		}
		).join(" | ");
	};

	@field("number")
	public ExtraFieldsID: number = 0;

	@field("has-one", "PurchaseExtraFields")
	public ExtraFields: PurchaseExtraFields | null = new PurchaseExtraFields();

	@field("boolean")
	@print(printBoolean)
	public IsUblSentToAccountant:boolean = false;

	@field("number")
	@hidden()
	public CurrencyID: number = 1;

	@field("number")
	public CurrencyRate: number = 1;

	@field("has-one", "Currency")
	public Currency: Currency | null = new Currency();

	@field("date")
	@nullable()
	@print(printLocalDate)
	public ExportedToIsabelDate:Date | null = null;

	public InboxreaderMessageID:string = ""

	public InboxreaderPurchaseID:number = 0;

	public Documents:PurchaseAwsFile[] = [];

	public UnprocessedFiles: File[] = [];
	public ToDeleteFiles:PurchaseAwsFile[] = [];

	// eslint-disable-next-line max-lines-per-function
	public constructor(data?: any) {
		if (!data) return;
		this.ID = data.ID;
		this.BookYear = data.BookYear;
		this.InvoiceNR = data.InvoiceNR;
		this.InvoiceDate = new Date(data.InvoiceDate);
		this.FriendlyID = data.FriendlyID;
		this.ComputedFriendlyID = data.ComputedFriendlyID;
		this.VatPeriod = data.VatPeriod;
		this.Total = data.Total;
		this.DiscountPercantage = data.DiscountPercantage;
		this.ContactID = data.ContactID;
		this.ExpirationDate = new Date(data.ExpirationDate);
		this.TimeCreated = new Date(data.TimeCreated);
		this.IsCreditNote = data.IsCreditNote;
		this.Communication = data.Communication;
		this.Ogm = data.Ogm;
		this.VATPurchaseTradingGoods = data.VATPurchaseTradingGoods;
		this.VATServicesAndCosts = data.VATServicesAndCosts;
		this.VATBuisinessAssets = data.VATBuisinessAssets;
		this.VATAmountImportWithinTheEG = data.VATAmountImportWithinTheEG;
		this.VATAmountCoContracts = data.VATAmountCoContracts;
		this.VATAmountServicesWithingTheEG = data.VATAmountServicesWithingTheEG;
		this.VATSubtractable = data.VATSubtractable;
		this.VATNotSubtractable = data.VATNotSubtractable;
		this.VATImportWithinTheEG = data.VATImportWithinTheEG;
		this.VATCoContracts = data.VATCoContracts;
		this.VATImportOutsideTheEG = data.VATImportOutsideTheEG;
		this.JournalID = data.JournalID;
		this.LastBankID = data.LastBankID;
		this.TotalNetto = data.TotalNetto;
		this.TotalVAT = data.TotalVAT;
		this.Leeggoed = data.Leeggoed || 0;
		this.AccountingPeriod = data.AccountingPeriod;
		if (data.Contact) {
			this.Contact = new Contact(data.Contact);
		}
		this.TotalOpen = data.TotalOpen;
		if (data.LastBankID != null && data.LastBank) {
			this.LastBank = new Bank(data.LastBank);
			if (this.LastBank.ID == 0){
				this.LastBank = null;
			}
		}
		this.Ledger82B = data.Ledger82B;
		if (data.Ledgers) {
			this.Ledgers = (data.Ledgers || []).map((d:any)=>new PurchaseLedger(d));
		}
		this.HasAttachedDocument = data.HasAttachedDocument;
		if (data.Documents){
			this.Documents = (data.Documents as any[] || []).map(p=>new PurchaseAwsFile(p));
		}
		if (data.Banks) {
			this.Banks = (data.Banks as any[]).map(b=>new Bank(b));
		}
		this.ExtraFieldsID =data.ExtraFieldsID;
		if (data.ExtraFields){
			this.ExtraFields = new PurchaseExtraFields(data.ExtraFields);
		}
		this.IsUblSentToAccountant = data.IsUblSentToAccountant;
		this.CurrencyID = data.CurrencyID && data.CurrencyID != 0 ? data.CurrencyID : 1;
		this.CurrencyRate = data.CurrencyRate && data.CurrencyRate != 0 ? data.CurrencyRate : 1;
		this.InboxreaderMessageID = data.InboxreaderMessageID || "";
		if (data.ExportedToIsabelDate) {
			this.ExportedToIsabelDate = new Date(data.ExportedToIsabelDate);
		}
	}

	// eslint-disable-next-line max-lines-per-function
	public getJSON(): any {
		return {
			ID: this.ID,
			BookYear: this.BookYear,
			InvoiceNR: this.InvoiceNR,
			InvoiceDate: this.InvoiceDate.toJSON(),
			FriendlyID: this.FriendlyID,
			ComputedFriendlyID: this.ComputedFriendlyID,
			VatPeriod: this.VatPeriod,
			Total: this.Total,
			DiscountPercantage: this.DiscountPercantage,
			ContactID: this.ContactID,
			ExpirationDate: this.ExpirationDate.toJSON(),
			TimeCreated: this.TimeCreated.toJSON(),
			IsCreditNote: this.IsCreditNote,
			Communication: this.Communication,
			Ogm: this.Ogm,
			VATPurchaseTradingGoods: this.VATPurchaseTradingGoods,
			VATServicesAndCosts: this.VATServicesAndCosts,
			VATBuisinessAssets: this.VATBuisinessAssets,
			VATAmountImportWithinTheEG: this.VATAmountImportWithinTheEG,
			VATAmountCoContracts: this.VATAmountCoContracts,
			VATAmountServicesWithingTheEG: this.VATAmountServicesWithingTheEG,
			VATSubtractable: this.VATSubtractable,
			VATNotSubtractable: this.VATNotSubtractable,
			VATImportWithinTheEG: this.VATImportWithinTheEG,
			VATCoContracts: this.VATCoContracts,
			JournalID: this.JournalID,
			VATImportOutsideTheEG: this.VATImportOutsideTheEG,
			TotalOpen: this.TotalOpen,
			LastBankID: this.LastBankID,
			Leeggoed: this.Leeggoed,
			Ledger82B: this.Ledger82B,
			Ledgers: this.Ledgers.map(l=>l.getJSON()),
			HasAttachedDocument: this.HasAttachedDocument,
			AccountingPeriod: this.AccountingPeriod,
			ExtraFieldsID: this.ExtraFieldsID,
			ExtraFields: this.ExtraFields ? this.ExtraFields.getJSON() : null,
			CurrencyID: this.CurrencyID,
			CurrencyRate: this.CurrencyRate,
			InboxreaderPurchaseID: this.InboxreaderPurchaseID,
			InboxreaderMessageID: this.InboxreaderMessageID,
			ExportedToIsabelDate: this.ExportedToIsabelDate ? this.ExportedToIsabelDate.toJSON() : null
		};
	}

	public doLedgersMatchTotal():boolean {
		if (this.Ledgers.length == 0){
			return true;
		}
		let diff = this.getLedgersTotal() - this.getSupposedLedgersTotal();
		if (Math.abs(diff) < 0.01) {
			return true;
		}
		return false;
	}

	public getSupposedLedgersTotal():number {
		//return this.Total - (this.VATPurchaseTradingGoods + this.VATServicesAndCosts + this.VATBuisinessAssets);
		return (this.VATPurchaseTradingGoods + this.VATServicesAndCosts + this.VATBuisinessAssets);
	}

	public getLedgersTotal():number {
		let total = 0;
		for (let ledger of this.Ledgers) {
			total += ledger.Amount;
		}
		return total;
	}

	public getTotalVat():number{
		if (this.Contact) {
			if (this.Contact.VatRegime == PurchaseVatRegime.BTW_MEDECONTRACTANT){
				return this.VATCoContracts;
			}
			if (this.Contact.VatRegime == PurchaseVatRegime.AANKOOP_UIT_ANDERE_LIDSTATEN_BINNEN_DE_EG) {
				return this.VATImportWithinTheEG;
			}
			if (this.Contact.VatRegime == PurchaseVatRegime.AANKOOP_BUITEN_DE_EG) {
				return this.VATImportOutsideTheEG;
			}
		}
		return this.Total - this.Leeggoed - this.VATPurchaseTradingGoods-this.VATServicesAndCosts-this.VATBuisinessAssets;
	}

	public getSubtractablePercent():number {
		let totalVat = this.VATSubtractable + this.VATNotSubtractable;
		if (totalVat == 0) {
			return 0;
		}
		return this.VATSubtractable / totalVat * 100;
	}

	public getTotalBooked():number{
		// VAT 81 + 82 + 83
		let startingValue = this.VATPurchaseTradingGoods + this.VATServicesAndCosts + this.VATBuisinessAssets;
		let result = startingValue;
		result += this.VATSubtractable+this.VATNotSubtractable;
		result = result - this.VATImportWithinTheEG - this.VATCoContracts - this.VATImportOutsideTheEG;
		result += this.Leeggoed;
		if (this.VATAmountImportWithinTheEG+this.VATAmountCoContracts+this.VATAmountServicesWithingTheEG > 0){
			result -= startingValue - this.VATAmountImportWithinTheEG - this.VATAmountCoContracts - this.VATAmountServicesWithingTheEG;
		}
		return result;
	}

	public willBanksBeDeletedOnSave(originalPurchase:Purchase):boolean{
		if (this.TotalOpen != originalPurchase.TotalOpen) return true;
		if (this.Total !=  originalPurchase.Total) return true;
		if (this.ContactID != originalPurchase.ContactID) return true;
		if (this.IsCreditNote != originalPurchase.IsCreditNote) return true;
		return false;
	}

	public async downloadFiles(){
		if (!this.HasAttachedDocument) return;
		if (!this.Documents || this.Documents.length == 0) {
			let p = await PurchaseService.getPurchase(this.ID);
			p.downloadFiles();
			return;
		}
		for (let doc of this.Documents){
			if (!doc.File) continue;
			await AWSFileService.saveFile(doc.File);
		}
	}

	public getVatRegime():PurchaseVatRegime {
		if (!this.Contact) return PurchaseVatRegime.NORMALE_BTW;
		return this.Contact.VatRegime as PurchaseVatRegime;
	}

	public getVat():number {
		let vatRegime = this.getVatRegime();
		if (vatRegime == PurchaseVatRegime.AAN_0) {
			return 0.0;
		}else if (vatRegime == PurchaseVatRegime.AAN_6){
			return 0.06;
		}else if (vatRegime == PurchaseVatRegime.AAN_12){
			return 0.12;
		}else if (vatRegime == PurchaseVatRegime.AAN_21){
			return 0.21;
		}else if (vatRegime == PurchaseVatRegime.VRIJ_VAN_BTW_CLASSIC || vatRegime == PurchaseVatRegime.VRIJ_VAN_BTW){
			return 0.0;
		}
		return 0.21;
	}
}



/* eslint-disable max-classes-per-file */
import { i18n } from "@/setup/i18n-setup";
import { IEmailTemplate } from "@/utils/email-template";
import { GoTemplateDataArray } from "@/utils/go-template-data-structure/array";
import { GoTemplateDataLink } from "@/utils/go-template-data-structure/link";
import { GoTemplateDataObject } from "@/utils/go-template-data-structure/object";
import { GoTemplateDataProperty } from "@/utils/go-template-data-structure/property";
import { GoTemplateDataRoot } from "@/utils/go-template-data-structure/root";
import { GoTemplateDataString } from "@/utils/go-template-data-structure/string";
import { CustomTranslation } from "../utils/custom-translation";

export class S3Attachment{
	Key:string = "";
	FileName:string = "";

	public constructor(data?:any){
		if (!data) return;
		this.Key = data.Key;
		this.FileName = data.FileName;
	}

	public getJSON(){
		return {
			Key: this.Key,
			FileName: this.FileName,
		};
	}
}

export class SaleEmailTemplate implements IEmailTemplate{
	ID:number = 0;
	Name:string = "";
	Content:CustomTranslation = new CustomTranslation();
	Subject:CustomTranslation = new CustomTranslation();
	Attachments:S3Attachment[] = [];
	IsHTML:boolean = true;
	SendReadConfirmation:boolean = false;
	DisplayOrder:number = 0;

	constructor(data?:any) {
		if (!data) return;
		this.ID = data.ID;
		this.Name = data.Name;
		this.Content = new CustomTranslation(data.Content);
		this.Subject = new CustomTranslation(data.Subject);
		if (data.Attachments) {
			this.Attachments = data.Attachments.map((d:any)=>new S3Attachment(d));
		}
		this.IsHTML = data.IsHTML;
		this.SendReadConfirmation = data.SendReadConfirmation;
		this.DisplayOrder = data.DisplayOrder;
	}

	getJSON() {
		return {
			ID: this.ID,
			Name: this.Name,
			Content: this.Content.getJSON(),
			Subject: this.Subject.getJSON(),
			Attachments: this.Attachments.map(a=>a.getJSON()),
			IsHTML: this.IsHTML,
			SendReadConfirmation: this.SendReadConfirmation,
			DisplayOrder: this.DisplayOrder
		};
	}

	getContentData():GoTemplateDataRoot{
		let result = this.getBaseData();
		result.push(this.getRowData());
		return new GoTemplateDataRoot(result);
	}

	getSubjectData():GoTemplateDataRoot{
		return new GoTemplateDataRoot(this.getBaseData());
	}

	private getBaseData():GoTemplateDataProperty[]{
		let t = (val:string)=>i18n.t(val);
		return [
			new GoTemplateDataString("ClientPortalUrl", t("models.email-templates.sale.root.ClientPortalUrl")),
			new GoTemplateDataLink("ClientPortalUrl", t("models.email-templates.sale.root.ClientPortalUrlDifferentText"), t("models.email-templates.sale.root.ClientPortalUrl")),
			new GoTemplateDataString("PublicSaleUrl", t("models.email-templates.sale.root.PublicSaleUrl")),
			new GoTemplateDataLink("PublicSaleUrl", t("models.email-templates.sale.root.PublicSaleUrlDifferentText"), t("models.email-templates.sale.root.PublicSaleUrl")),
			new GoTemplateDataString("ID", t("models.email-templates.sale.root.ID")),
			new GoTemplateDataString("CompanyName", t("models.email-templates.sale.root.CompanyName")),
			new GoTemplateDataString("NR", t("models.email-templates.sale.root.NR")),
			new GoTemplateDataString("ToPrintMessage", t("models.email-templates.sale.root.ToPrintMessage")),
			new GoTemplateDataString("ToNotPrintMessage", t("models.email-templates.sale.root.ToNotPrintMessage")),
			new GoTemplateDataString("InvoiceDate", t("models.email-templates.sale.root.InvoiceDate")),
			new GoTemplateDataString("ExpirationDate", t("models.email-templates.sale.root.ExpirationDate")),
			new GoTemplateDataString("TotalExcVAT", t("models.email-templates.sale.root.TotalExcVAT")),
			new GoTemplateDataString("TotalVAT", t("models.email-templates.sale.root.TotalVAT")),
			new GoTemplateDataString("TotalIncVAT", t("models.email-templates.sale.root.TotalIncVAT")),
			new GoTemplateDataString("TotalOpen", t("models.email-templates.sale.root.TotalOpen")),
			new GoTemplateDataString("Type", t("models.email-templates.sale.root.Type")),
			new GoTemplateDataString("Ogm", t("models.email-templates.sale.root.Ogm")),
			new GoTemplateDataObject("Contact", [
				new GoTemplateDataString("ID", t("models.email-templates.sale.contact.ID")),
				new GoTemplateDataString("Info", t("models.email-templates.sale.contact.Info")),
				new GoTemplateDataString("NR", t("models.email-templates.sale.contact.NR")),
				this.getAddressData("MainAddress", t("models.email-templates.sale.contact.MainAddress").toString())
			]),
			this.getAddressData("InvoiceAddress", t("models.email-templates.sale.root.InvoiceAddress").toString()),
			this.getAddressData("DeliveryAddress", t("models.email-templates.sale.root.DeliveryAddress").toString()),
			this.getExtrafields("ExtraFields")
		];
	}

	private getExtrafields(name:string):GoTemplateDataObject{
		return new GoTemplateDataObject(name, [
			new GoTemplateDataString("Text1", i18n.t("models.email-templates.sale.extrafields.Text1")),
			new GoTemplateDataString("Text2", i18n.t("models.email-templates.sale.extrafields.Text2")),
			new GoTemplateDataString("Text3", i18n.t("models.email-templates.sale.extrafields.Text3")),
			new GoTemplateDataString("Text4", i18n.t("models.email-templates.sale.extrafields.Text4")),
			new GoTemplateDataString("Text5", i18n.t("models.email-templates.sale.extrafields.Text5"))
		]);
	}
	private getAddressData(name:string, prefix:string):GoTemplateDataObject{
		return new GoTemplateDataObject(name, [
			new GoTemplateDataString("ID", prefix + " " + i18n.t("models.email-templates.sale.address.ID").toString()),
			new GoTemplateDataString("CompanyName", prefix + " " + i18n.t("models.email-templates.sale.address.CompanyName").toString()),
			new GoTemplateDataString("LastName", prefix + " " + i18n.t("models.email-templates.sale.address.LastName").toString()),
			new GoTemplateDataString("FirstName", prefix + " " + i18n.t("models.email-templates.sale.address.FirstName").toString()),
			new GoTemplateDataString("AddressLine1", prefix + " " + i18n.t("models.email-templates.sale.address.AddressLine1").toString()),
			new GoTemplateDataString("AddressLine2", prefix + " " + i18n.t("models.email-templates.sale.address.AddressLine2").toString()),
			new GoTemplateDataString("Number", prefix + " " + i18n.t("models.email-templates.sale.address.Number").toString()),
			new GoTemplateDataString("Box", prefix + " " + i18n.t("models.email-templates.sale.address.Box").toString()),
			new GoTemplateDataString("Postal", prefix + " " + i18n.t("models.email-templates.sale.address.Postal").toString()),
			new GoTemplateDataString("Email", prefix + " " + i18n.t("models.email-templates.sale.address.Email").toString()),
			new GoTemplateDataString("Phone", prefix + " " + i18n.t("models.email-templates.sale.address.Phone").toString()),
			new GoTemplateDataString("Fax", prefix + " " + i18n.t("models.email-templates.sale.address.Fax").toString()),
			new GoTemplateDataString("City", prefix + " " + i18n.t("models.email-templates.sale.address.City").toString()),
			new GoTemplateDataString("CountryCode", prefix + " " + i18n.t("models.email-templates.sale.address.CountryCode").toString()),
		]);
	}

	private getRowData():GoTemplateDataArray{
		return new GoTemplateDataArray("Rows", "row", [
			new GoTemplateDataString("Amount", i18n.t("models.email-templates.sale.rows.Amount").toString()),
			new GoTemplateDataString("ProductSku", i18n.t("models.email-templates.sale.rows.ProductSku").toString()),
			new GoTemplateDataString("ProductDescription", i18n.t("models.email-templates.sale.rows.ProductDescription").toString()),
			new GoTemplateDataString("UnitPrice", i18n.t("models.email-templates.sale.rows.UnitPrice").toString()),
			new GoTemplateDataString("Netto", i18n.t("models.email-templates.sale.rows.Netto").toString()),
			new GoTemplateDataString("Bruto", i18n.t("models.email-templates.sale.rows.Bruto").toString()),
			new GoTemplateDataString("VAT", i18n.t("models.email-templates.sale.rows.VAT").toString()),
			new GoTemplateDataString("Discount", i18n.t("models.email-templates.sale.rows.Discount").toString()),
			new GoTemplateDataString("VatPercent", i18n.t("models.email-templates.sale.rows.VatPercent").toString()),
		], i18n.t("models.email-templates.sale.root.Rows").toString());
	}
}


import { BrowserInfo } from "@/utils/browser";
import { ElectronFunctions } from "@/utils/electron/electron";
import { printHtml } from "@/utils/pdf";
import { LabelLayoutData } from "./label-layout-data";

export class LabelLayoutModel {
	ID:number = 0;
	Name:string = "Nieuwe label";
	BrowseID:number = 0;
	Data:LabelLayoutData | null = null;

	public constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		this.Name = data.Name;
		this.BrowseID = data.BrowseID;
		if (data.Data) {
			this.Data = new LabelLayoutData(data.Data);
		}
	}

	public getJSON(){
		return {
			ID: this.ID,
			Name: this.Name.trim(),
			BrowseID: this.BrowseID,
			Data: this.Data ? this.Data.getJSON() : null
		};
	}

	getPrintScriptCode(pageWidth:number, pageHeight:number):string {
		if (BrowserInfo.isElectron() && ElectronFunctions.getDefaultPrinterIdForLabelLayout(this.ID).deviceId !+ ""){
			let settings = ElectronFunctions.getDefaultPrinterIdForLabelLayout(this.ID);
			return `
				const {ipcRenderer} = require("electron");
				ipcRenderer.send("print", {
					printer:"${settings.deviceId}", 
					closeOnFinish:true, 
					pageSize:{width:${pageWidth}, height:${pageHeight}},
					landscape:${settings.printInLandscape}
				})
			`;
		}
		// give chrome the time to generate qr codes
		return `setTimeout(()=>{
			window.print();
			parent.postMessage("close", "*");
		}, 100);`;
	}

	print(result:string){
		console.log(result);
		if (BrowserInfo.isElectron() && ElectronFunctions.getDefaultPrinterIdForLabelLayout(this.ID).deviceId != ""){
			let printerSettings = ElectronFunctions.getDefaultPrinterIdForLabelLayout(this.ID);
			console.log(printerSettings.deviceId);
			let w = window.open("", "Label");
			if (!w) return;
			w.document.write(result);
			w.document.close();
		}else{
			printHtml(result);
		}
	}
}

import Vue from "vue";
import AppContactSearch from "@/components/contacts/ContactSearch.vue";
import { Contact } from "@/models/base";
import { getContactServiceByGroupId } from "@/utils/services/contact-services";
import {ContactService} from "@/utils/services/contact-service";


export default Vue.extend({
	data(){
		return {
			cachedContacts: [] as Contact[]
		};
	},
	props: {
		value: String,
		label: String,
		groupId: {
			type: Number,
			required: true
		}
	},
	computed: {
		itemIds():number[]{
			let items = JSON.parse(this.value || "[]");
			if (!Array.isArray(items)){
				items = [];
			}
			return items;
		},
		items():Contact[]{
			let toFetchContacts = [] as number[];
			let result = [] as Contact[];
			for (let id of this.itemIds){
				let contact = this.cachedContacts.find(c=>c.ID == id);
				if (!contact){
					toFetchContacts.push(id);
				}else{
					result.push(contact);
				}
			}
			this.fetchContacts(toFetchContacts);
			return result;
		},
		contactService():ContactService{
			return getContactServiceByGroupId(1);
		}
	},
	methods: {
		onInput(contact:Contact){
			let foundContact = this.cachedContacts.find(c=>c.ID == contact.ID);
			if (!foundContact){
				this.cachedContacts.push(contact);
			}
			let foundId = this.itemIds.find(id=>id== contact.ID);
			if (!foundId){
				let itemIds = [...this.itemIds, contact.ID];
				this.$emit("input", JSON.stringify(itemIds));
			}
		},
		removeContact(contact:Contact){
			let itemIds = [...this.itemIds];
			let index = this.itemIds.findIndex(i=>i == contact.ID);
			if (index == -1) return;
			itemIds.splice(index, 1);
			this.$emit("input", JSON.stringify(itemIds));
		},
		async fetchContacts(contactIds:number[]){
			if (contactIds.length == 0) return;
			try{
				let service = getContactServiceByGroupId(this.groupId);
				let result = await service.getContactsByIDs(contactIds);
				let toAddContacts = [] as Contact[];
				for (let contact of result){
					let foundContact = this.cachedContacts.find(c=>c.ID == contact.ID);
					if (foundContact) continue;
					toAddContacts.push(contact);
				}
				this.cachedContacts.push(...toAddContacts);
				let itemIds = [...this.itemIds];
				for (let i = itemIds.length-1;i>=0;i--){
					let id = this.itemIds[i];
					let foundContact = this.cachedContacts.find(c=>c.ID == id);
					if (!foundContact){
						itemIds.splice(i, 1);
					}
				}
				this.$emit("input", JSON.stringify(itemIds));
			}catch(err){

			}
		}
	},
	created(){
		if (this.contactService.selectedContacts.length > 0) {
			this.$emit("input", JSON.stringify((this.contactService.selectedContacts.map(p => p.ID))));
			return;
		}
		this.$emit("input", "[]");
	},
	components: {
		AppContactSearch
	}
});


export class ElectronCtepSettings {
	defaultBankId:number = 0;
	mode: "tcp" | "serial" | "connect" | "off" = "off";
	port:number = 9000;
	comPort:string = "";
	connectUrl:string = ""
	selfCertificationModeEnabled:boolean = false;
	openSendTicketAfterPaid:boolean = false;

	public constructor(data?:any){
		if (!data) return;
		this.defaultBankId = data.defaultBankId || data.bankId;
		this.mode = data.mode || "off";
		if (!data.enabled) {
			this.mode = "off";
		}
		this.port = data.port | 9000;
		this.comPort = data.comPort;
		this.connectUrl = data.connectUrl || "";
		this.selfCertificationModeEnabled = data.selfCertificationModeEnabled;
		this.openSendTicketAfterPaid = data.openSendTicketAfterPaid || false;
	}

	public getJSON(){
		return {
			defaultBankId: this.defaultBankId,
			enabled: this.mode != "off",
			mode: this.mode,
			port: this.port,
			comPort: this.comPort,
			connectUrl: this.connectUrl,
			selfCertificationModeEnabled: this.selfCertificationModeEnabled,
			openSendTicketAfterPaid: this.openSendTicketAfterPaid,
		};
	}
}
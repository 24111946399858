
import { v4 } from "uuid";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
	data(){
		return {
			uid: v4(),
		};
	},
	props: {
		value: {
			type: [String, Array as ()=>string[]],
			required: true
		},
		multi: Boolean,
		label: {
			type: [Object as ()=>TranslateResult, String],
			default():TranslateResult{
				if (this.multi) return this.$ct("bank-kinds");
				return this.$ct("bank-kind");
			}
		}
	},
	computed: {
		quarters():number[]{
			return [1, 2, 3, 4];
		}
	},
	methods: {
		setQuarter(value:number){
			this.$emit("input", `${value}`);
		},
		isChecked(quarter:number):boolean {
			return `${this.value}` == `${quarter}`;
		}
	},
	components: {
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});

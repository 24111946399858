
import AppFileUpload from "@/components/FileUploadDropAndProgress.vue";
import { Product } from "@/models/base";
import { ProductImage } from "@/models/base";
import Vue from "vue";
import { ISelectItem } from "winfakt-vue-components";

interface IImageEntry {
	productImage?:ProductImage;
	toUploadImage?:{file:File, setAsCover:boolean, url:string};
	url:string;
	sourceList:any[];
	index:number;
	key:string;
}

export default Vue.extend({
	props: {
		product: {
			type: Object as () => Product,
			required: true
		}
	},
	computed: {
		images():IImageEntry[]{
			return this.product.Images.map((image,i)=>{
				return {
					productImage: image,
					url: image.getFileUrl(),
					sourceList: this.product.Images,
					index: i,
					key: `${image.ID}`
				} as IImageEntry;
			}).concat(this.product.toUploadImages.map((f,i)=>{
				if (!f.url) {
					f.url = URL.createObjectURL(f.file);
				}
				return {
					toUploadImage: f,
					url: f.url,
					sourceList: this.product.toUploadImages,
					index: i,
					key: f.url
				} as IImageEntry;
			}));
		}
	},
	methods: {
		onFile(file: File) {
			let coverImage = false;
			if(this.product.Images.length==0){
				coverImage = true;
			}
			this.product.toUploadImages.push({setAsCover: coverImage, file, url: ""});
		},
		getDropDownItems(img:IImageEntry):ISelectItem<Function>[]{
			let result:ISelectItem<Function>[] = [];
			let isCover = false;
			if (img.productImage) {
				isCover = img.productImage.setAsCover;
			}
			if (img.toUploadImage) {
				isCover = img.toUploadImage.setAsCover;
			}
			if (!isCover) {
				result.push({
					text: this.$ct("set-as-cover-image"),
					icon: "images",
					value: this.setCoverImage.bind(this)
				});
			}else{
				result.push({
					text: this.$ct("usnet-as-cover-image"),
					icon: "expand",
					value: this.unsetCoverImage.bind(this)
				});
			}
			result.push({
				text: this.$ct("common.delete"),
				icon: "times",
				value: this.removeImg.bind(this)
			});
			/*if(AuthService.hasModule(MODULE.WEBSHOP)){
				result.push({
					text: this.$ct("add-to-shop"),
					icon: "shopping-cart",
					value: this.toggleWebshop.bind(this)
				});
			}*/
			return result;
		},
		toggleWebshop(image:IImageEntry) {
			console.log("toggle");
		},
		removeImg(image:IImageEntry) {
			if (image.productImage){
				if (image.productImage.ID == this.product.CoverImageID) {
					this.product.CoverImageID = 0;
				}
				this.product.toRemoveImages.push(image.productImage);
			}
			image.sourceList.splice(image.index, 1);
			if (image.toUploadImage) {
				URL.revokeObjectURL(image.url);
			}
		},
		setCoverImage(image:IImageEntry){
			this.unsetCoverImage();
			if (image.productImage){
				this.product.CoverImageID = image.productImage.ID;
				image.productImage.setAsCover = true;
			}
			if (image.toUploadImage){
				image.toUploadImage.setAsCover = true;
			}
		},
		unsetCoverImage(){
			this.product.CoverImageID = 0;
			for (let image of this.product.toUploadImages) {
				image.setAsCover = false;
			}
			for (let image of this.product.Images) {
				image.setAsCover = false;
			}
		}
	},
	destroyed(){
		for (let img of this.product.toUploadImages) {
			URL.revokeObjectURL(img.url);
		}
	},
	components: {
		AppFileUpload
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});

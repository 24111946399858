import { TranslateResult } from "vue-i18n";
import { IModelPropertyClickAction } from "./model-field";
import { i18n } from "@/setup/i18n-setup";
import { ModalService } from "@/services";
import { AwsDocument } from "@/models/base/aws-document";

export function getParentOfProp(target:any, prop:PropertyKey):any{
	let parts = (prop as string).split(".");
	parts.pop();
	for (let part of parts) {
		if (!target) return;
		target = (target as any)[part] as any;
	}
	return target;
}

export const imageClickAction:IModelPropertyClickAction = {
	getName():TranslateResult{
		return i18n.t("common.open-image");
	},
	handler(target:any, prop:PropertyKey, options?:any):void{
		let parts = (prop as string).split(".");
		for (let part of parts) {
			if (!target) return;
			target = (target as any)[part] as any;
		}
		if (options && options.openInNewTab){
			window.open(target.getFileUrl(), "_blank");
			return;
		}
		ModalService.openImageUrl(target);
	},
	optionsComponent: "AppImageClickActionOptionsEdit"
};

export const extraFieldEditClickAction:IModelPropertyClickAction = {
	getName():TranslateResult{
		return i18n.t("common.edit-extra-field");
	},
	handler(target:any, prop:PropertyKey):string{
		return `edit-extra-field/${prop as string}`;
	},
	canBeUsed: (browse, path)=>{
		return path.startsWith("ExtraFields.");
	}
};


export function getOpenAwsDocumentsFunc(getDocumentsFunction:(value:any)=>Promise<AwsDocument[]>):IModelPropertyClickAction{
	return {
		getName():TranslateResult{
			return i18n.t("common.open-aws-documents-modal");
		},
		handler(target:any, prop:PropertyKey){
			target = getParentOfProp(target, prop);
			getDocumentsFunction(target).then((docs:AwsDocument[])=>{
				ModalService.openAwsDocuments(docs);
			});
		}
	};
}

export const editSellPriceClickAction:IModelPropertyClickAction = {
	getName():TranslateResult{
		return i18n.t("common.edit-sell-price");
	},
	handler(target:any, prop:PropertyKey):string{
		return `edit-sell-price/${(prop as string).replaceAll("SellPrice",  "")}`;
	},
	canBeUsed: (browse, path)=>{
		return browse.ID == 4;
	}
};


export const editSellPriceIncVatClickAction:IModelPropertyClickAction = {
	getName():TranslateResult{
		return i18n.t("common.edit-sell-price-inc-vat");
	},
	handler(target:any, prop:PropertyKey):string{
		return `edit-sell-price-inc-vat/${(prop as string).replaceAll("SellPrice",  "").replaceAll("WithVat", "")}`;
	},
	canBeUsed: (browse, path)=>{
		return browse.ID == 4;
	}
};


export const editBuyCatalogPriceClickAction:IModelPropertyClickAction = {
	getName():TranslateResult{
		return i18n.t("common.edit-buy-catalog-price");
	},
	handler(target:any, prop:PropertyKey):string{
		return `edit-buy-catalog-price`;
	},
	canBeUsed: (browse, path)=>{
		return browse.ID == 4;
	}
};


export const editBuyNettoPriceClickAction:IModelPropertyClickAction = {
	getName():TranslateResult{
		return i18n.t("common.edit-buy-netto-price");
	},
	handler(target:any, prop:PropertyKey):string{
		return `edit-buy-netto-price`;
	},
	canBeUsed: (browse, path)=>{
		return browse.ID == 4;
	}
};

export const editProductGroupClickAction:IModelPropertyClickAction = {
	getName():TranslateResult{
		return i18n.t("common.edit-product-group");
	},
	handler(target:any, prop:PropertyKey):string{
		return `edit-product-group`;
	},
	canBeUsed: (browse, path)=>{
		return browse.ID == 4;
	}
};

export const sendMailClickAction:IModelPropertyClickAction = {
	getName():TranslateResult{
		return i18n.t("common.send-mail");
	},
	handler(target:any, prop:PropertyKey):string{
		return `send-mail`;
	},
	canBeUsed(browse, path){
		return browse.ID < 4;
	}
};

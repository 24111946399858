import { ServerConfig } from "@/config/config";
import { Bank, Purchase } from "@/models/base";
import { BankType } from "@/models/base";
import { Sale } from "@/models/base";
import { Axios } from "@/utils/axios";
import { advancedSearch } from "@/utils/browse/browse";
import { HumanFilter } from "@/utils/human-filter";
import { IQueryParamsWithOptionalView } from "@/utils/query-params";
import BankBalance from "@/models/base/bank-balance";
import { View } from "@/models/view";
import { FetchedTotal } from "@/utils/views/fetched-total";
import { IGetResponse } from "@/utils/get-response";
import { IInitAppResponse } from "./init-service";
import { UserConfigService } from "./user-config-service";
import { getModel } from "@/utils/models/model";

export interface IBankTransferBody {
	Date:Date;
	Amount:number;
	FromBankID:number;
	ToBankID:number;
	Comment:string;
}

export class BankServiceClass {
	private bankTypes:BankType[] = [];

	public readonly url = ServerConfig.host + "/bank";
	public readonly typesURL = ServerConfig.host + "/bank-type";

	async getSettings() {
		let result = await Axios.get(ServerConfig.host + "/browse/8", {
			params: {}
		});
		return result.data;
	}

	async get(query: IQueryParamsWithOptionalView, extraFilters:HumanFilter[] = []): Promise<IGetResponse<Bank>> {
		let result = await advancedSearch(query, this.url, getModel("Bank"), extraFilters);
		result.data.data = result.data.data.map((r: any) => new Bank(r));
		return result.data;
	}

	async getTotals(view:View):Promise<FetchedTotal[]>{
		let result = await Axios.get(`${this.url}/view/${view.ID}/totals`);
		return (result.data || []).map((d:any)=>new FetchedTotal(d));
	}

	async getBookingsByPurchase(purchase:number | Purchase):Promise<Bank[]>{
		let purchaseId:number = typeof(purchase) == "object" ? purchase.ID : purchase;
		let filter = new HumanFilter({Field: "this.PurchaseID", Operator: "=", Values: [`${purchaseId}`]});
		let result = await Axios.get(`${this.url}`, {
			params: {
				filters: [filter]
			}
		});
		return (result.data.data || []).map((b:any)=> new Bank(b));
	}

	async getBookingsBySales(sales:Sale[]):Promise<Bank[]> {

		let filter = new HumanFilter({Field: "this.InvoiceID", Operator: "in", Values: sales.map(s => s.ID.toString())});
		let result = await Axios.get(`${this.url}`, {
			params: {
				filters: [filter]
			}
		});
		return (result.data.data || []).map((b:any) => new Bank(b));
	}

	async getBooking(id: number): Promise<Bank> {
		let result = await Axios.get(`${this.url}/${id}`);
		return new Bank(result.data);
	}

	async fetchTypes():Promise<BankType[]> {
		let result = await Axios.get(this.typesURL, {params: {limit: 100}});
		if (this.bankTypes.length > 0) {
			this.bankTypes.splice(0, this.bankTypes.length);
		}
		this.bankTypes.push(...result.data.data.map((i: any) => new BankType(i)));
		return this.bankTypes;
	}

	getTypes():BankType[] {
		let banksOrder = UserConfigService.getGeneralSettings().BanksOrder;
		return [...this.bankTypes].sort((a, b)=>{
			let ia = banksOrder.indexOf(a.ID);
			let ib = banksOrder.indexOf(b.ID);
			if (ia != -1 && ib == -1) {
				return -1;
			}else if (ia == -1 && ib != -1) {
				return 1;
			}else if (ia != -1 && ib != -1) {
				return ia-ib;
			}
			return a.ID - b.ID;
		});
	}

	getType(id:number):BankType | undefined {
		return this.bankTypes.find(b=>b.ID == id);
	}

	async postType(type:BankType):Promise<BankType> {
		let result = await Axios.post(this.typesURL, type.getJSON());
		this.fetchTypes();
		return new BankType(result.data[0]);
	}

	async putType(type:BankType):Promise<BankType> {
		let result = await Axios.put(this.typesURL, [type.getJSON()]);
		this.fetchTypes();
		return new BankType(result.data[0]);
	}

	async deleteType(t:BankType):Promise<void> {
		await Axios.delete(`${this.typesURL}/${t.ID}`);
		this.fetchTypes();
	}

	async postBooking(bank: Bank): Promise<Bank> {
		let result = await Axios.post(`${this.url}`, bank.getJSON());
		return new Bank(result.data);
	}

	async batchPostBooking(banks:Bank[]):Promise<Bank[]>{
		let result = await Axios.post(`${this.url}/batch`, banks.map(b=>b.getJSON()));
		return result.data.map((d:any)=>new Bank(d));
	}

	async putBooking(bank: Bank): Promise<Bank> {
		let result = await Axios.put(`${this.url}`, bank.getJSON());
		return new Bank(result.data);
	}

	async deleteBookings(banks: Bank[]):Promise<void> {
		await Axios.delete(`${this.url}`, {
			data: banks.map(b => b.ID)
		});
	}

	async downloadCtepTicketAsPdf(bank:Bank):Promise<Blob>{
		let result = await Axios.get(`${this.url}/${bank.ID}/download-ctep-result-ticket`, {responseType: "blob"});
		return result.data;
	}

	async getBalance(bankId:number, bookyear?:number):Promise<number> {
		let headers = undefined;
		if (bookyear){
			headers = {
				Bookyear: bookyear
			};
		}
		let result = await Axios.get(`${this.url}/balance/${bankId}`, {headers});
		return result.data.Balance;
	}

	async getAllBalances( bookyear?:number):Promise<BankBalance[]> {
		let headers = undefined;
		if (bookyear){
			headers = {
				Bookyear: bookyear
			};
		}
		let result = await Axios.get(`${this.url}/all-balance`, {headers});
		return result.data.map((b:BankBalance) => new BankBalance(b));
	}

	async isStNrUsed(stNumber:string):Promise<boolean>{
		let response = await Axios.get(`${this.url}`, {params: {
			filters: [new HumanFilter({Field: "this.StNr", Operator: "=", Values: [stNumber]})],
			limit: 1,
		}});
		return response.data.data.length > 0;
	}

	async copyBankSaldosToNewerBookYear():Promise<void>{
		await Axios.post(`${this.url}/copy-bank-saldo-to-newer-bookyear`);
	}

	init(data:IInitAppResponse){
		this.bankTypes = data.BankTypes;
	}

	async addDocumentToBank(bank:Bank, file:File):Promise<Bank> {
		let data = new FormData();
		data.append("file", file, file.name);
		let result = await Axios.post(`${this.url}/${bank.ID}/add-document`, data);
		return new Bank(result.data);
	}

	async removeDocumentFromBank(bank:Bank, fileId:number):Promise<Bank> {
		let result = await Axios.delete(`${this.url}/${bank.ID}/remove-document/${fileId}`);
		return new Bank(result.data);
	}

	async createTransfer(bankTransferBody:IBankTransferBody):Promise<void>{
		await Axios.post(`${this.url}/create-transfer`, bankTransferBody);
	}
};

export const BankService = new BankServiceClass();
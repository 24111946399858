import { ExtraFieldBaseConfig } from "@/models/dossier-configs/extra-field-base-config";

export enum ExtraFieldsType {
	CONTACT = "contact",
	PRODUCT = "product",
	SALE = "sale",
	PURCHASE = "purchase",
	CAR = "car",
	CRM = "crm"
}

export interface IExtraFields {
	Num1:number;
	Num2:number;
	Num3:number;
	Num4:number;
	Num5:number;
	Num6:number;
	Num7:number;
	Num8:number;
	Num9:number;
	Num10:number;
	Num11:number;
	Num12:number;
	Num13:number;
	Num14:number;
	Num15:number;
	Num16:number;
	Num17:number;
	Num18:number;
	Num19:number;
	Num20:number;
	Text1:string;
	Text2:string;
	Text3:string;
	Text4:string;
	Text5:string;
	Text6:string;
	Text7:string;
	Text8:string;
	Text9:string;
	Text10:string;
	Text11:string;
	Text12:string;
	Text13:string;
	Text14:string;
	Text15:string;
	Text16:string;
	Text17:string;
	Text18:string;
	Text19:string;
	Text20:string;
	Bool1:boolean;
	Bool2:boolean;
	Bool3:boolean;
	Bool4:boolean;
	Bool5:boolean;
	Bool6:boolean;
	Bool7:boolean;
	Bool8:boolean;
	Bool9:boolean;
	Bool10:boolean;
	Bool11:boolean;
	Bool12:boolean;
	Bool13:boolean;
	Bool14:boolean;
	Bool15:boolean;
	Bool16:boolean;
	Bool17:boolean;
	Bool18:boolean;
	Bool19:boolean;
	Bool20:boolean;
	Time1:Date | null;
	Time2:Date | null;
	Time3:Date | null;
	Time4:Date | null;
	Time5:Date | null;
	Time6:Date | null;
	Time7:Date | null;
	Time8:Date | null;
	Time9:Date | null;
	Time10:Date | null;
	Time11:Date | null;
	Time12:Date | null;
	Time13:Date | null;
	Time14:Date | null;
	Time15:Date | null;
	Time16:Date | null;
	Time17:Date | null;
	Time18:Date | null;
	Time19:Date | null;
	Time20:Date | null;
}

export interface IExtraFieldQuickEditViaMenuData {
	field:string;
	name:string;
	config:ExtraFieldBaseConfig;
}

export interface IExtraFieldQuickEditViaMenuDataResult{
	numValue:number;
	textValue:string;
	boolValue:boolean;
	timeValue:Date | null;
}


export function parseDateFromData(data:any):Date | null {
	if (!data) return null;
	return new Date(data);
}

export function convertDateToNullOrJSON(date:Date|null):string | null {
	if (!date) return null;
	return date.toJSON();
}

export function getExtraFieldCount():number {
	return 20;
}

export function getExtraFieldIndexes():number[] {
	let result:number[] = [];
	for (let i = 1; i <= getExtraFieldCount(); i++) {
		result.push(i);
	}
	return result;
}

export function getExtraFieldKeyNames(t:"Bool" | "Text" | "Num" | "Time" ):(keyof IExtraFields)[] {
	return getExtraFieldIndexes().map(i=>`${t}${i}` as keyof IExtraFields);
}

export function getAllExtraFieldKeyNames():(keyof IExtraFields)[]{
	return [ ...getExtraFieldKeyNames("Num"), ...getExtraFieldKeyNames("Text"), ...getExtraFieldKeyNames("Bool"), ...getExtraFieldKeyNames("Time")];
}
import { decimals, field } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";

@model("ContactStats")
export class ContactStats{
	public ContactID:number = 0;

	@field("number")
	@decimals(2)
	public TotalRevenue:number = 0;

	@field("number")
	@decimals(2)
	public TotalOpen:number = 0;

	public constructor(data?:any){
		if (!data) return;
		this.ContactID = data.ContactID;
		this.TotalRevenue = data.TotalRevenue;
		this.TotalOpen = data.TotalOpen;
	}
}
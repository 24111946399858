import { IDataTableColumn } from "winfakt-vue-components";
import { Model } from "../models/model";
import { ModelNamedProperty } from "../models/named-property";
import { printBooleanWithIcon, printHumanFloat } from "../pretty-print";

export enum ViewColumnAlignment {
	LEFT = 0,
	RIGHT = 1,
	CENTER = 2
}

export interface IViewColumnBooleanIconSettings {
	IconTrue:string;
	IconTrueColor:string;
	IconFalse:string;
	IconFalseColor:string;
}

export class ViewColumn implements IViewColumnBooleanIconSettings {
	Name: string = "";
	Property: string = "";
	Width: string = "200px";
	IsComputed: boolean = false;
	ComputedPreloads: string[] = [];
	Decimals:number | null = null;
	RightAlign:ViewColumnAlignment | null = null;
	EnableClickAction:boolean = false;
	ClickActionOnDoubleClick:boolean = false;
	ClickActionOptions:any = null

	IconTrue:string = "";
	IconTrueColor:string = "";
	IconFalse:string = "";
	IconFalseColor:string = "";
	TextTrue:string = "";
	TextFalse:string = "";

	constructor(data: any = null) {
		if (!data) {
			return;
		}
		this.Name = data.Name;
		this.Property = data.Property;
		this.Width = data.Width || "100px";
		this.IsComputed = data.IsComputed;
		this.ComputedPreloads = data.ComputedPreloads || [];
		this.Decimals = data.Decimals;
		if (data.RightAlign !== null){
			if (data.RightAlign > 1) {
				this.RightAlign = data.RightAlign;
			}else {
				this.RightAlign = data.RightAlign ? 1 : 0;
			}
		}
		this.IconTrue = data.IconTrue || "";
		this.IconTrueColor = data.IconTrueColor || "";
		this.IconFalse = data.IconFalse || "";
		this.IconFalseColor = data.IconFalseColor || "";
		this.TextTrue = data.TextTrue || "";
		this.TextFalse = data.TextFalse || "";
		this.EnableClickAction = data.EnableClickAction;
		this.ClickActionOnDoubleClick = data.ClickActionOnDoubleClick || false;
		this.ClickActionOptions = data.ClickActionOptions;
	}

	public static makeQuickViewColumn(prop:string, model:Model, width:string = "200px", name:string  | {toString():string} = ""):ViewColumn {
		if (!name) {
			let field = model.getField(prop);
			if (field) {
				name = field.getPropertyTranslation();
			}
		}
		let result = new ViewColumn();
		result.Property = prop;
		result.Width = width;
		result.Name = name.toString();
		return result;
	}

	private getPrintFunction(field:ModelNamedProperty):((value:any, highestParent:any, lowestParent:any)=>string) | undefined {
		let print = field.field.print;
		let oldPrint = print;
		if (field.field.type == "boolean") {
			if (this.IconTrue || this.IconFalse) {
				return (v:any)=> printBooleanWithIcon(v, this.IconTrue, this.IconTrueColor, this.IconFalse, this.IconFalseColor);
			}
			if (this.TextTrue || this.TextFalse) {
				return (v:any)=> v ? this.TextTrue : this.TextFalse;
			}
		}
		if (this.Decimals !== null){
			return (val:number)=>printHumanFloat(val, this.Decimals || 0);
		}else if (print != null){
			return print;
		}else if (field.field.decimals !== undefined){
			let decimals = field.field.decimals;
			return (val:number)=> {
				if (typeof(val) == "number"){
					return printHumanFloat(val, decimals || 0);
				}
				if (oldPrint){
					return oldPrint(val, val, val);
				}
				return val;
			};
		}
		return print;
	}

	private getDatatableAlignment(field:ModelNamedProperty):{rightAlign:boolean, centerAlign:boolean}{
		let rightAlign:boolean = false;
		let centerAlign:boolean  = false;
		if (field.field.type == "number") {
			rightAlign = true;
		}
		if (field.field.type == "boolean") {
			centerAlign = true;
			rightAlign = false;
		}
		if (field.field.rightAlign){
			rightAlign = true;
			centerAlign = false;
		}

		if (this.RightAlign !== null) {
			rightAlign = false;
			centerAlign = false;
			if (this.RightAlign == ViewColumnAlignment.RIGHT) {
				rightAlign = true;
			}
			if (this.RightAlign == ViewColumnAlignment.CENTER) {
				centerAlign = true;
			}
		}

		return {rightAlign, centerAlign};
	}

	private getFieldNotFoundDataTableColumn():IDataTableColumn {
		return {
			name: this.Name,
			property: this.Property,
			width: this.Width,
			classes: ["browse-table-error"],
			print: ()=>"ERROR"
		};
	}

	getDataTableColumn(model:Model, headerIcon?:string, onHeaderIconClick?:(column:IDataTableColumn)=>void):IDataTableColumn{
		let field = model.getField(this.Property);
		if (!field){
			return  this.getFieldNotFoundDataTableColumn();
		}
		let {centerAlign, rightAlign} = this.getDatatableAlignment(field);
		if (field.field.type == "array" || field.field.type == "json" || field.field.isComputed) {
			headerIcon = undefined;
		}
		let clickAction:undefined | ((value:any)=>void) = undefined;
		let doubleClickAction:undefined | ((value:any)=>void) = undefined;
		if (field.field.clickAction && (this.EnableClickAction || field.field.clickAction.cannotBeDisabled)){
			let handler = field.field.clickAction.handler;
			clickAction = (value:any)=>handler(value, this.Property, this.ClickActionOptions);
			if (this.ClickActionOnDoubleClick){
				doubleClickAction = clickAction;
				clickAction = undefined;
			}
		}
		return {
			name: this.Name,
			property: this.Property,
			width: this.Width,
			print: this.getPrintFunction(field),
			right: rightAlign,
			center: centerAlign,
			classes: field.field.displayClasses,
			disableSorting:	this.IsComputed,
			getValueFunc: field.field.getValueFunc,
			headerIcon,
			headerIconClick: onHeaderIconClick,
			click: clickAction,
			dblClick: doubleClickAction
		};
	}
}

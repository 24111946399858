
import AppSearchBrowse from "@/components/browse/SearchBrowse.vue";
import { Contact } from "@/models/base";
import { BrowseService } from "@/services/browse-service";
import { IBrowseBaseDataSource } from "@/utils/browse/browse-data-source";
import Vue from "vue";
import { CustomerService } from "@/services/customer-service";
import { View } from "@/models/view";
import { FetchedTotal } from "@/utils/views/fetched-total";

export default Vue.extend({
	computed: {
		dataSource():IBrowseBaseDataSource<Contact> {
			return {
				getSettings: () => BrowseService.getBrowse(1),
				getElements: CustomerService.getContacts.bind(CustomerService),
				getTotals(view:View):Promise<FetchedTotal[]>{
					return CustomerService.getTotals(view);
				},
				browseName: "Clients",
				modelName: "Contact"
			};
		}
	},
	methods: {
		onClick(contact:Contact) {
			this.$emit("click", contact);
		}
	},
	components: {
		AppSearchBrowse
	}
});

import axios, { AxiosRequestConfig, AxiosError, AxiosResponse, AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { AuthService } from "@/services/auth-service";
import { ServerConfig } from "@/config/config";
import { tokenStore } from "./token-store";
import { EVENT_SWITCH_SESSION, MainSocketService } from "@/services/main-socket-service";
import { EventEmitter } from "./event-emitter";
import { HttpError } from "./services/http-error";
import { AuthSocketService, BookYearService } from "@/services";
import * as services from "@/services/index";

type OnRequestInterceptor = (req:InternalAxiosRequestConfig, axios:AxiosInstance, services:any)=>Promise<HttpError | null>
type OnResponseInterceptor = (res:AxiosResponse<any>, axios:AxiosInstance, services:any)=>Promise<AxiosResponse | null>;

export const ExtraInterceptors = {
	onRequest: [] as (OnRequestInterceptor)[],
	onResponse: [] as (OnResponseInterceptor)[]
};

export function getAuthenticatedHeaders(): any {
	return {
		Authorization: tokenStore.getSelectedToken().token,
		SocketID: tokenStore.socketId
	};
}

export function createOnRequestInterceptor(code:string):OnRequestInterceptor{
	let asyncFuncConstructor = new Function("return async function(){}")().constructor;
	return asyncFuncConstructor("req", "Axios", "services", code);
}

export function createOnResponseInterceptor(code:string):OnResponseInterceptor{
	let asyncFuncConstructor = new Function("return async function(){}")().constructor;
	return asyncFuncConstructor("res", "Axios", "services", code);
}

export const AxiosErrorEmitter = new EventEmitter();

export interface IAxiosCreateOptions {
	skipAuthentication?:boolean;
}

let isInitingDossier = false;
let isInitingUser = false;
let eventEmitter = new EventEmitter();

async function initDossier(){
	if (!isInitingDossier){
		isInitingDossier = true;
		try{
			await axios.post(`${ServerConfig.host}/dossier/init`, {Lang: "nl-be"}, {headers: getAuthenticatedHeaders()});
			eventEmitter.emit("dossier-init", null);
		}catch(err){
			eventEmitter.emit("dossier-init", err);
		}
		isInitingDossier = false;
	}else{
		await new Promise<void>((resolve, reject) => {
			eventEmitter.once("dossier-init", (err:any)=>{
				if (err){
					reject(err);
				}else{
					resolve();
				}
			});
		});
	}
}

async function initUser(){
	if (!isInitingUser){
		isInitingUser = true;
		try{
			await axios.post(`${ServerConfig.host}/user/init`, {Lang: "nl-be"}, {headers: getAuthenticatedHeaders()});
			eventEmitter.emit("user-init", null);
		}catch(err){
			eventEmitter.emit("user-init", err);
		}
		isInitingUser = false;
	}else{
		await new Promise<void>((resolve, reject)=>{
			eventEmitter.once("user-init", (err:any)=>{
				if (err){
					reject(err);
				}else{
					resolve();
				}
			});
		});
	}
}


async function checkFor600StatusCodes(value:AxiosError):Promise<boolean> {
	if (!value.response) throw value;
	if (value.response.status == 600) {
		await initDossier();
		await initUser();
		return true;
	}
	if (value.response.status == 601) {
		await initUser();
		AuthService.emit("user-init");
		return true;
	}
	if (value.response.status == 603) {
		let isSwitched = await new Promise<Boolean>((resolve) => {
			AuthService.emit("other-session-active", resolve);
		});
		if (!isSwitched) {
			let err = new HttpError({}, 603);
			err.key = "NOT_ACTIVE_SESSION";
			err.message = "Not active session";
			throw err;
		}
		return true;
	}
	if (value.response.status == 604) {
		console.log("Got 604. Disconnecting sockets");
		await MainSocketService.disconnect();
		await AuthSocketService.disconnect();
		console.log("Reconnecting");
		await AuthSocketService.connect(AuthService.wfDossier.id);
		await MainSocketService.connect(AuthService.wfDossier.id);
		console.log("Reconnected sockets");
		MainSocketService.emit(EVENT_SWITCH_SESSION);
		return true;
	}
	return false;
}

export async function refreshTokensIfRequired(){
	if (!tokenStore.tokenExpires) return;
	if (tokenStore.willTokenExpireInSeconds(60)) {
		await AuthService.refreshTokens();
	}
}

async function authenticate(value:AxiosRequestConfig) {
	await refreshTokensIfRequired();
	let authHeaders = getAuthenticatedHeaders();
	if (!value.headers) {
		value.headers = {};
	}
	value.headers.Authorization = authHeaders.Authorization;
	if (value.url && value.url.startsWith(ServerConfig.host)) {
		value.headers.SocketID = authHeaders.SocketID;
	}
}

function addBookYearToHeaders(value:AxiosRequestConfig){
	if (!value.url || !value.url.startsWith(ServerConfig.host)){
		return;
	}
	if (!value.headers) {
		value.headers = {};
	}
	if (value.headers.Bookyear) return;
	if (value.headers.Bookyear === 0) return;
	value.headers.Bookyear = BookYearService.selectedBookYear;
}

// Fixes hashtags and other special symbols being removed from query
function replaceSpecialSymbolsInQuery(value:InternalAxiosRequestConfig){
	if (value.params) {
		let keys = Object.keys(value.params);
		for (let key of keys) {
			value.params[key] = `${value.params[key]}`.replaceAll("#", "%23").replaceAll("+", "%2B");
		}
	}
	if (value.url){
		value.url = value.url.replaceAll("#", "%23").replaceAll("+", "%2B");
	}
}

function addRequestInterceptors(client:AxiosInstance, options:IAxiosCreateOptions){
	client.interceptors.request.use(async (value: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
		if (!options.skipAuthentication){
			await authenticate(value);
		}
		addBookYearToHeaders(value);
		if (value.params){
			for (let key of Object.keys(value.params)) {
				let t = typeof (value.params[key]);
				if (t == "object") {
					value.params[key] = JSON.stringify(value.params[key]);
				}
			}
		}
		replaceSpecialSymbolsInQuery(value);
		for (let interceptor of ExtraInterceptors.onRequest){
			let err = await interceptor(value, client, services);
			if (err) {
				throw err;
			}
		}
		return value;
	});
}


// eslint-disable-next-line max-lines-per-function
function addResponseInterceptors(client:AxiosInstance){
	client.interceptors.response.use(async (value:AxiosResponse)=>{
		for (let interceptor of ExtraInterceptors.onResponse){
			let res = await interceptor(value, client, services);
			if (res){
				value = res;
			}
		}
		return value;
	}, async (value: AxiosError): Promise<AxiosError | AxiosResponse> => {
		if (!value.response) throw value;
		if (!value.config) throw value;
		if (value.config.responseType == "stream") {
			return value;
		}
		let has600 = await checkFor600StatusCodes(value);
		if (has600){
			return client.request(value.config);
		}

		if (typeof(value.response.data) == "object") {
			let err = new HttpError(value.response.data, value.response.status);
			err.isNetworkError = false;
			AxiosErrorEmitter.emit("error", err, value, client);
			throw err;
		}else if (typeof(value.response.data) == "string") {
			let err = new HttpError({message: value.response.data}, value.response.status);
			err.isNetworkError = false;
			AxiosErrorEmitter.emit("error", err, value, client);
			throw err;
		}

		let err = new HttpError({}, value.response.status);
		err.isNetworkError = true;
		err.message = value.message;
		err.key = "HTTP_ERROR";
		if (!value.response.status) {
			err.message = "Request failed";
			err.data = [value.message];
		} else if (!(value.response.status + "").match(/^2[0-9][0-9]$/)) {
			err.data = [`${value.response.data}`];
		}
		AxiosErrorEmitter.emit("error", err, value, client);
		throw err;
	});
}


export function createAxiosClient(options:IAxiosCreateOptions = {}):AxiosInstance {
	let client = axios.create();
	addRequestInterceptors(client, options);
	addResponseInterceptors(client);
	return client;
}


export const Axios = createAxiosClient();

export const UnauthenticatedClient = createAxiosClient({skipAuthentication: true});
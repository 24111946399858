import { ProductGroupService } from "@/services/product-group-service";
import { CustomTranslation } from "@/utils/custom-translation";
import { field, hidden } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";
import { v4 } from "uuid";
import { AWSFile } from "../aws-file";
import { UserConfigService } from "@/services";

@model("ProductGroup")
export class ProductGroup {
	@field("number")
	public ID:number = 0;
	@field("string")
	public Name:string = "";
	@field("number")
	public Level:number = 0;
	@field("has-one", "ProductGroup")
	public ParentGroup:ProductGroup | null = null;
	@field("has-many", "ProductGroup")
	public Children:ProductGroup[] = [];
	@field("number")
	public OrderPriority:number = 0;
	private ChildrenFetched:boolean = false;
	@field("json")
	public Translations:CustomTranslation = new CustomTranslation();
	@field("boolean")
	public ShowOnDisplay:boolean = false;

	@field("number")
	@hidden()
	public ImageAWSFileID:number | null = null;
	public ImageAWSFile:AWSFile | null = null

	private uid:string = v4();

	public constructor(data?:any, depth:number = 0){
		if (!data) return;
		this.ID = data.ID;
		this.Name = data.Name;
		this.Level = data.Level;
		if  (data.ParentGroup && data.ParentGroup.ID != 0) {
			this.ParentGroup = new ProductGroup(data.ParentGroup);
		}
		this.ChildrenFetched = data.ChildrenFetched;
		if (this.Level < 10 && depth < 10) {
			if (data.Children){
				this.Children = data.Children.map((c:any)=>{
					let child = new ProductGroup(c, depth+1);
					child.ParentGroup = this;
					return child;
				});
			}
		}
		this.OrderPriority = data.OrderPriority;
		this.Translations = new CustomTranslation(data.Translations);

		this.ShowOnDisplay = data.ShowOnDisplay;

		this.ImageAWSFileID = data.ImageAWSFileID;
		if (data.ImageAWSFile && this.ImageAWSFileID != null) {
			this.ImageAWSFile = new AWSFile(data.ImageAWSFile);
		}
	}

	public async fetchChildren(level:number = 0):Promise<void>{
		if (this.ID == 0) return;
		if (this.ChildrenFetched) return;
		if (level == 0){
			level = this.Level + 1;
		}
		this.Children = await ProductGroupService.getChildrenOfGroup(this.ID, level);
		for (let child of this.Children){
			child.ParentGroup = this;
		}
		this.ChildrenFetched = true;
	}

	public async fetchAllChildren():Promise<void>{
		if (this.ID == 0) return;
		this.Children = await ProductGroupService.getAllChildrenOfGroup(this.ID);
		for (let child of this.Children){
			child.ParentGroup = this;
		}
		this.ChildrenFetched = true;
	}

	public getChildrenFetched():boolean{
		return this.ChildrenFetched;
	}


	// Merges two groups together when refetched.
	public merge(newGroup:ProductGroup){
		if (newGroup.ID != this.ID) throw new Error("Cant merge these groups");
		for (let newChild of newGroup.Children) {
			let oldChild = this.Children.find(c=>c.ID == newChild.ID);
			if (!oldChild){
				this.addChild(newChild);
				continue;
			}

			oldChild.merge(newChild);
			this.Children = this.Children.sort((a,b)=>a.OrderPriority - b.OrderPriority);
		}
		if (newGroup.ChildrenFetched){
			this.ChildrenFetched = true;
		}
	}

	public copy():ProductGroup {
		let result = new ProductGroup();
		result.ID = this.ID;
		result.Name = this.Name;
		result.Level = this.Level;
		result.ParentGroup = this.ParentGroup;
		result.OrderPriority = this.OrderPriority;
		result.ChildrenFetched = this.ChildrenFetched;
		result.Translations = this.Translations;
		result.Children = this.Children.map(c=>{
			let r = c.copy();
			r.ParentGroup = result;
			return r;
		});
		result.ShowOnDisplay = this.ShowOnDisplay;
		result.ImageAWSFileID = this.ImageAWSFileID;
		if (this.ImageAWSFile) {
			result.ImageAWSFile = new AWSFile(this.ImageAWSFile);
		}
		return result;
	}

	public getPath():string{
		if (this.ParentGroup){
			return `${this.ParentGroup.getPath()} / ${this.Name}`;
		}
		return this.Name;
	}

	public getJSON():any{
		return {
			ID: this.ID,
			Name: this.Name,
			Level: this.Level,
			Children: this.Children.map(c=>c.getJSON()),
			Translations: this.Translations.getJSON(),
			ShowOnDisplay: this.ShowOnDisplay,
			ImageAWSFileID: this.ImageAWSFileID
		};
	}

	public removeChild(child:ProductGroup){
		let i = this.Children.indexOf(child);
		child.ParentGroup = null;
		if (i != -1){
			this.Children.splice(i, 1);
		}
	}

	public addChild(group:ProductGroup, index:number = -1){
		if (group.ParentGroup) {
			group.ParentGroup.removeChild(group);
		}
		if (index != -1){
			this.Children.splice(index, 0, group);
		}else{
			this.Children.push(group);
		}
		group.ParentGroup = this;
	}

	public getUid():string{
		return this.uid;
	}

	public static canEditGroups():boolean {
		return UserConfigService.hasCurrentUserPermission((p)=>false);
	}
}
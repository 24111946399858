import { HumanFilter } from "../human-filter";
import { DashboardChartData } from "./dashboard-chart-data";
import { DashboardItemType } from "./dashboard-item";

export class DashboardChartDataWithBanks extends DashboardChartData{
	useToAddBanks:boolean = false;
	toAddBanksFilters:HumanFilter[] = [];
	useToSubtractBanks:boolean = false;
	toSubtractBanksFilters:HumanFilter[] = [];

	public constructor(data?:any, type?:DashboardItemType){
		super(data, type);
		if (!data) return;
		this.useToAddBanks = `${data.useToAddBanks}` == "true";
		this.useToSubtractBanks = `${data.useToSubtractBanks}` == "true";
		if (data.toAddBanksFilters) {
			let filters = data.toAddBanksFilters;
			if (typeof(filters) == "string"){
				filters = JSON.parse(data.toAddBanksFilters);
			}
			this.toAddBanksFilters = (filters || []).map((d:any)=>new HumanFilter(d));
		}
		if (data.toSubtractBanksFilters){
			let filters = data.toSubtractBanksFilters;
			if (typeof(filters) == "string"){
				filters = JSON.parse(data.toSubtractBanksFilters);
			}
			this.toSubtractBanksFilters = (filters || []).map((d:any)=>new HumanFilter(d));
		}
	}

	public getJSON(){
		return {
			...super.getJSON(),
			useToAddBanks: `${this.useToAddBanks}`,
			toAddBanksFilters: JSON.stringify(this.toAddBanksFilters.map(f=>f.getJSON())),
			useToSubtractBanks: `${this.useToSubtractBanks}`,
			toSubtractBanksFilters: JSON.stringify(this.toSubtractBanksFilters.map(f=>f.getJSON())),
		};
	}
}

import Vue from "vue";
import AppHumanFilterEditValueDefault from "./HumanFilterEditValueDefault.vue";
import AppHumanFilterEditValueCustom from "./HumanFilterEditValueCustom.vue";
import { HumanFilter } from "@/utils/human-filter";
import { ModelPropertyType } from "@/utils/models/model-field";
import { Model } from "@/utils/models/model";

export default Vue.extend({
	props: {
		filter: {
			type: Object as ()=>HumanFilter,
			required: true
		},
		fieldType: {
			type: String,
			required: true
		},
		labels: {
			type: Array as ()=>String[]
		},
		model: {
			type: Object as ()=>Model,
		}
	},
	computed: {
		isCustom():boolean {
			return (["string", "boolean", "number", "date", "duration"] as ModelPropertyType[]).findIndex(f=>f == this.fieldType) == -1;
		}
	},
	watch: {
		isCustom: {
			immediate: true,
			handler(){
				if (!this.isCustom) return;
				for (let i = 0; i < this.filter.UseFieldValues.length; i++){
					this.filter.UseFieldValues[i] = false;
				}
			}
		}
	},
	components: {
		AppHumanFilterEditValueDefault,
		AppHumanFilterEditValueCustom
	}
});

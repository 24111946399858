/* eslint-disable max-classes-per-file */

export class ContactAddressMailSetting {
	public Label:string = "";
	public UseForSaleTypeIDs:number[] = [];

	public constructor(data?:any){
		if (!data) return;
		this.Label = data.Label;
		this.UseForSaleTypeIDs = [...(data.UseForSaleTypeIDs || [])];
	}

	public getJSON(){
		return {
			Label: this.Label,
			UseForSaleTypeIDs: [...this.UseForSaleTypeIDs]
		};
	}
}

export class ContactAddressMailSettings {
	public DefaultMailIndex:number = 0;
	public Settings:ContactAddressMailSetting[] = [];


	public constructor(data?:any){
		if (!data) return;
		this.DefaultMailIndex = data.DefaultMailIndex;
		this.Settings = ((data.Settings || []) as any[]).map(s=>new ContactAddressMailSetting(s));
	}


	public getJSON(){
		return {
			DefaultMailIndex: this.DefaultMailIndex,
			Settings: this.Settings.map(s=>s.getJSON())
		};
	}
}